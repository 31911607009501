import { types } from 'mobx-state-tree';
import { LocationState } from './audit-location-state.model';
import { ControlState } from './audit-control-state.model';
import { NavigationState } from './audit-navigation-state.model';
import { NetworkState } from './audit-network-state.model';
import { JoystickState } from './audit-joystick-state.model';
import { DeploymentState } from './audit-deployment-state.model';

export const RobotState = types.model({
  location_state: types.maybeNull(LocationState),
  control_state: types.maybeNull(ControlState),
  navigation_state: types.maybeNull(NavigationState),
  network_state: types.maybeNull(NetworkState),
  joystick_state: types.maybeNull(JoystickState),
  deployment_state: types.maybeNull(DeploymentState)
});
