import axios from 'axios';
import { Auth } from 'aws-amplify';

const baseURL = process.env.REACT_APP_CHAPERONE_BASE_URL;

export const client = axios.create({
  baseURL,
  timeout: 90000,
  headers: { 'Content-Type': 'application/json' }
});

const getAccessToken = async () => {
  const res = await Auth.currentSession();
  return res.getAccessToken().getJwtToken();
};

export const guardedClient = axios.create({
  baseURL,
  timeout: 90000,
  headers: { 'Content-Type': 'application/json' }
});

guardedClient.interceptors.request.use(async (config) => {
  const token = await getAccessToken();
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});
