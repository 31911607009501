import React from 'react';
import {
  makeStyles,
  withStyles,
  Typography,
  Grid,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Fade,
  Tooltip,
  Icon
} from '@material-ui/core';
import PropTypes from 'prop-types';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import BatteryIcon from './battery-icon.component';
import { useStores } from '../../store/root/root.store';
import { celsiusToFahrenheit } from '../../utils/ui.utils';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  }
});

const useStyles = makeStyles(() => ({
  containerScrollable: {
    height: '100%',
    maxHeight: 262
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.grey[600],
    color: theme.palette.primary.contrastText
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(4n+1)': {
      backgroundColor: '#e0e0e0'
    },
    '&:nth-of-type(4n+2)': {
      backgroundColor: '#e0e0e0'
    }
  }
}))(TableRow);

function Row(props) {
  const { row } = props;
  const classes = useRowStyles();
  const { autonomyRobotStore } = useStores();
  const name = autonomyRobotStore.getSelectedRobot()?.name;

  return (
    <StyledTableRow className={classes.root}>
      <StyledTableCell component="th" scope="row">
        <b>{props.index}</b>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Box>
            <BatteryIcon percentage={row.percentage} />
          </Box>
          {`${row.percentage}%`}
          {row.percentage <= 10 && row.type === 'VANGUARD' && (
            <Tooltip
              title={`A VANGUARD battery should never be fully discharged. Power off ${name} until the battery is able to be swapped.`}
            >
              <Icon
                aria-label="expand row"
                size="small"
                style={{
                  position: 'relative',
                  top: '-4px',
                  right: '-5px',
                  color: `${row.percentage > 5 ? '#eed202' : 'red'}`
                }}
              >
                <WarningOutlinedIcon />
              </Icon>
            </Tooltip>
          )}
        </Grid>
      </StyledTableCell>
      <StyledTableCell align="right">{`${row.temperature.toFixed(2)}°C | ${celsiusToFahrenheit(row.temperature).toFixed(
        2
      )}°F`}</StyledTableCell>
      <StyledTableCell align="right">{`${row.voltage.toFixed(2)}V`}</StyledTableCell>
      <StyledTableCell align="right">{`${row.current.toFixed(2)}A`}</StyledTableCell>
      <StyledTableCell align="right">{row.type}</StyledTableCell>
    </StyledTableRow>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    percentage: PropTypes.number.isRequired,
    temperature: PropTypes.number.isRequired,
    voltage: PropTypes.number.isRequired,
    current: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired
};

export default function BatteryDetails() {
  const classes = useStyles();
  const { autonomyRobotStore } = useStores();
  return (
    <Fade in>
      <TableContainer component={Paper} className={classes.containerScrollable}>
        <Table stickyHeader aria-label="collapsible table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>
                <Typography variant="subtitle1">#</Typography>
              </StyledTableCell>
              <StyledTableCell align="right">
                <Typography variant="subtitle1">Percentage</Typography>
              </StyledTableCell>
              <StyledTableCell align="right">
                <Typography variant="subtitle1">Temperature</Typography>
              </StyledTableCell>
              <StyledTableCell align="right">
                <Typography variant="subtitle1">Voltage</Typography>
              </StyledTableCell>
              <StyledTableCell align="right">
                <Typography variant="subtitle1">Current</Typography>
              </StyledTableCell>
              <StyledTableCell align="right">
                <Typography variant="subtitle1">Type</Typography>
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.battery_state?.battery_info.map((battery, i) => (
              <Row key={i} row={battery} index={i + 1} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Fade>
  );
}
