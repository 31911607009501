import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  TableCell,
  Button,
  Checkbox,
  FormControl,
  Select,
  MenuItem
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import CommentTwoToneIcon from '@material-ui/icons/CommentTwoTone';
import EmailTwoToneIcon from '@material-ui/icons/EmailTwoTone';
import { StripedTableRow } from '../core/striped-table-row.component';
import { StripedTable } from '../core/striped-table.component';
import { formatDateTime, alphaNumericOrder } from '../../utils/ui.utils';
import { ZONES } from '../../utils/constants';
import { guardedClient } from '../../utils/axios-instance';
import { AuditRobotStatePopup } from '../audit/audit-robot-state-popup';
import { sortTable } from '../../utils/table-sort';
import { CommentModal } from '../dialogs/infraction-comment.dialog';
import { useStores } from '../../store/root/root.store';
import { infractionEmailNotification } from '../../services/api/dashboard.service';
import EmailConfirmationDialog from '../dialogs/email-confirmation.dialog';

const sortInfractionsOnFakeDataFlag = (infractionsData) => {
  const fakeDataInfractions = [];
  const nonFakeDataInfractions = [];
  infractionsData.forEach((infraction) => {
    if (infraction.fake_data) {
      fakeDataInfractions.push(infraction);
    } else {
      nonFakeDataInfractions.push(infraction);
    }
  });
  return [...nonFakeDataInfractions, ...fakeDataInfractions];
};
const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    backgroundColor: theme.palette.primary.light,
    '& > *': {
      color: theme.palette.inverted.main
    }
  },
  content: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    '& > *': {
      color: theme.palette.inverted.main
    }
  },
  closeButton: {
    color: theme.palette.inverted.main,
    minwidth: 100
  },
  TableCell: {
    width: 50
  },
  Button: {
    backgroundColor: 'gray',
    margin: theme.spacing(0.2)
  }
}));
const getInfractionTableCellStyle = (fakeDataFlag) => ({ color: fakeDataFlag ? 'gray' : null });

const InfractionVideoDialog = ({ open, videoSrc, onClose = () => {} }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>
      Infraction Video
      <IconButton aria-label="close" onClick={onClose} style={{ position: 'absolute', right: '8px', top: '8px' }}>
        <Close />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      {!videoSrc ? (
        'Error loading Video'
      ) : (
        <video width="400" controls>
          <track kind="captions" />
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support HTML video.
        </video>
      )}
    </DialogContent>
  </Dialog>
);

export const InfractionsTable = ({
  infractions,
  users,
  viewAllInfractions,
  show = false,
  onClose = () => {},
  updateInfraction = () => {}
}) => {
  const classes = useStyles();
  const { applicationStore } = useStores();
  const [openStateDialog, setOpenStateDialog] = useState(false);
  const [openVideoDialog, setOpenVideoDialog] = useState(false);
  const [selectedInfraction, setSelectedInfraction] = useState({});
  const [infractionVideoSrc, setInfractionVideoSrc] = useState('');
  const [selectedSortParam, setSelectedSortParam] = useState('serial_number');
  const [selectedSortType, setSelectedSortType] = useState('asc');
  const zone = infractions[0] && infractions?.[0].zone ? infractions[0].zone : '';
  const zoneColor = zone === ZONES.ONE ? 'red' : 'yellow';
  const direction = infractions[0] ? infractions?.[0].direction : '';
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [openInfractionId, setOpenInfractionId] = useState(null);
  const [infractionEmail, setInfractionEmail] = useState('');
  const [showEmailConfirmationDialog, setShowEmailConfirmationDialog] = useState(false);
  const [emailConfirmationMessage, setEmailConfirmationMessage] = useState('');

  const handleGetInfractionVideoSrc = async (path) => {
    try {
      const response = await guardedClient.get('/dashboard/infractions/video-src', { params: { path } });
      setInfractionVideoSrc(response?.data?.url);
      setOpenVideoDialog(true);
      return response?.data?.url || ''; // Return the video link or an empty string if not available
    } catch (error) {
      setInfractionVideoSrc('');
      return '';
    }
  };

  // Handles closing the comment modal when the save button is clicked
  const handleCloseCommentOnSave = async () => {
    try {
      setOpenCommentModal(false);
    } catch (e) {
      console.error(e);
    }
  };

  // Handles sending infraction email with video link
  const handleSendInfractionEmail = async (infraction) => {
    try {
      // Wait for the video link to be retrieved
      const videoLink = await handleGetInfractionVideoSrc(infraction.video_ref_link);

      // Send the infraction email with the video link
      const emailSentResult = await sendInfractionEmailMessage(
        infractionEmail,
        infraction.serial_number,
        formatDateTime(infraction.created_at),
        infraction.comments,
        videoLink
      );

      // Update the UI based on the email send result
      if (emailSentResult) {
        setEmailConfirmationMessage('Email Sent!');
        setShowEmailConfirmationDialog(true);
        setTimeout(() => {
          setShowEmailConfirmationDialog(false);
        }, 1500);

        // Update infraction status
        updateInfraction(infraction.id, { email_sent: true, created_at: infraction.created_at }, false);
      } else {
        setEmailConfirmationMessage('Error sending email, contact Autonomy');
        setShowEmailConfirmationDialog(true);
        setTimeout(() => {
          setShowEmailConfirmationDialog(false);
        }, 2000);
      }
    } catch (error) {
      console.error('Error handling infraction video:', error);
    }
  };

  // Makes a call to the email.controller API to create/send an infraction email
  const sendInfractionEmailMessage = async (infractionsEmail, robotSerial, infractionDate, infractionComment, videoLink) => {
    try {
      await infractionEmailNotification(infractionsEmail, robotSerial, infractionDate, infractionComment, videoLink);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  };
  const headers = [
    { name: 'Assigned User', id: 'assigned_user_id', isSortable: false },
    { name: 'Serial Number', id: 'serial_number', isSortable: true },
    { name: 'Created At', id: 'date:created_at', isSortable: true },
    { name: 'Duration (Sec)', id: 'numeric:duration', isSortable: true },
    { name: 'Type', id: 'type', isSortable: true },
    { name: 'Video Links', id: 'video_links', isSortable: false },
    { name: 'Debrief Sent', id: 'debrief_sent', isSortable: false },
    { name: 'Safety Test', id: 'fake_data', isSortable: false },
    { name: 'State', id: 'state', isSortable: false },
    { name: 'Comments', id: 'comments', isSortable: false },
    { name: 'Email', id: 'send_email', isSortable: false }
  ];

  /** Header for All infractions table. Contains two additional column, Zone Color and Direction */
  const AllInfractionHeaders = [
    { name: 'Assigned User', id: 'assigned_user_id', isSortable: false },
    { name: 'Serial Number', id: 'serial_number', isSortable: true },
    { name: 'Zone Color', id: 'zone', isSortable: true },
    { name: 'Infraction Direction', id: 'direction', isSortable: true },
    { name: 'Created At', id: 'date:created_at', isSortable: true },
    { name: 'Duration (Sec)', id: 'numeric:duration', isSortable: true },
    { name: 'Type', id: 'type', isSortable: true },
    { name: 'Video Links', id: 'video_links', isSortable: false },
    { name: 'Debrief Sent', id: 'debrief_sent', isSortable: true },
    { name: 'Safety Test', id: 'fake_data', isSortable: true },
    { name: 'State', id: 'state', isSortable: false },
    { name: 'Comment', id: 'state', isSortable: false },
    { name: 'Email', id: 'send_email', isSortable: false }
  ];
  const sortedInfractions = sortInfractionsOnFakeDataFlag(sortTable(infractions, selectedSortParam, selectedSortType));
  const title = viewAllInfractions ? 'Infractions list' : `Infractions list of ${zoneColor} zone, ${direction.toLowerCase()} direction`;

  return (
    <Dialog open={show} onClose={onClose} className={classes.root} maxWidth="xl">
      <EmailConfirmationDialog open={showEmailConfirmationDialog} message={emailConfirmationMessage} />
      <DialogTitle className={classes.title}>
        <Grid item container direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" component="p">
            {title}
          </Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <StripedTable
          headers={viewAllInfractions ? AllInfractionHeaders : headers}
          scrollable
          selectedSortParam={selectedSortParam}
          selectedSortType={selectedSortType}
          setSelectedSortParam={setSelectedSortParam}
          setSelectedSortType={setSelectedSortType}
        >
          {sortedInfractions.map((infraction) => (
            <StripedTableRow key={infraction.id} backgroundColor={infraction.fake_data ? '#CFCFCF' : '#F7F7F7'}>
              <TableCell align="left">
                <FormControl className={classes.formControl}>
                  <Select
                    value={infraction.responsible_user_email || 'NONE'}
                    className={classes.select}
                    style={getInfractionTableCellStyle(infraction.fake_data)}
                    onChange={(event) => {
                      setInfractionEmail(event.target.value);
                      updateInfraction(
                        infraction.id,
                        { responsible_user_email: event.target.value, created_at: infraction.created_at },
                        false
                      );
                    }}
                  >
                    <MenuItem key="NONE" value="NONE">
                      None
                    </MenuItem>
                    {users.toSorted(alphaNumericOrder((u) => u['custom:firstName'])).map((user) => (
                      <MenuItem key={user.email} value={user.email}>
                        {`${user['custom:firstName']} ${user['custom:lastName']}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell style={getInfractionTableCellStyle(infraction.fake_data)} align="left">
                {infraction.serial_number}
              </TableCell>
              {viewAllInfractions && (
                <>
                  <TableCell style={getInfractionTableCellStyle(infraction.fake_data)} align="left">
                    {infraction.zone === 1 ? 'red' : 'yellow'}
                  </TableCell>
                  <TableCell style={getInfractionTableCellStyle(infraction.fake_data)} align="left">
                    {infraction.direction}
                  </TableCell>
                </>
              )}
              <TableCell style={getInfractionTableCellStyle(infraction.fake_data)} align="left">
                {formatDateTime(infraction.created_at)}
              </TableCell>
              <TableCell style={getInfractionTableCellStyle(infraction.fake_data)} align="left">
                {infraction.duration.toFixed(2)}
              </TableCell>
              <TableCell style={getInfractionTableCellStyle(infraction.fake_data)} align="left">
                {infraction.type}
              </TableCell>
              <TableCell style={getInfractionTableCellStyle(infraction.fake_data)} align="left">
                {infraction.state && (
                  <Button
                    className={classes.Button}
                    onClick={() => {
                      handleGetInfractionVideoSrc(infraction.video_ref_link);
                    }}
                  >
                    View
                  </Button>
                )}
                {infraction.video_url_after}
              </TableCell>
              <TableCell style={getInfractionTableCellStyle(infraction.fake_data)} align="left">
                <Checkbox
                  checked={infraction.debrief_sent}
                  onChange={(event) =>
                    updateInfraction(infraction.id, { debrief_sent: event.target.checked, created_at: infraction.created_at }, false)
                  }
                />
              </TableCell>
              <TableCell style={getInfractionTableCellStyle(infraction.fake_data)} align="left">
                <Checkbox
                  checked={infraction.fake_data}
                  onChange={(event) =>
                    updateInfraction(infraction.id, { fake_data: event.target.checked, created_at: infraction.created_at }, true)
                  }
                />
              </TableCell>
              <TableCell style={getInfractionTableCellStyle(infraction.fake_data)} align="left">
                {infraction.state && (
                  <Button
                    className={classes.Button}
                    onClick={() => {
                      setSelectedInfraction(infraction);
                      setOpenStateDialog(true);
                    }}
                  >
                    Show
                  </Button>
                )}
              </TableCell>
              <TableCell style={getInfractionTableCellStyle(infraction.fake_data)} align="left">
                <IconButton
                  onClick={() => {
                    setOpenInfractionId(infraction.id);
                    setOpenCommentModal(true);
                  }}
                >
                  <CommentTwoToneIcon style={{ color: `${infraction.comments === '' ? 'red' : 'green'}` }} />
                </IconButton>
                {openInfractionId === infraction.id && (
                  <CommentModal
                    key={infraction.id}
                    open={openCommentModal}
                    onClose={setOpenCommentModal}
                    onSave={handleCloseCommentOnSave}
                    infraction={infraction}
                    handleComment={(infractionId, comment, createdAt) => {
                      updateInfraction(infractionId, { comments: comment, created_at: createdAt }, false);
                    }}
                    infractionComment={infraction.comments}
                  />
                )}
              </TableCell>
              <TableCell style={getInfractionTableCellStyle(infraction.fake_data)} align="left">
                <IconButton
                  onClick={() => {
                    if (infractionEmail === 'NONE' || infraction.responsible_user_email === '') {
                      applicationStore.pushInfo('Error', 'Please Select a User First!');
                    } else {
                      handleSendInfractionEmail(infraction);
                    }
                  }}
                >
                  {infraction.email_sent ? (
                    <EmailTwoToneIcon key={infraction.id} style={{ color: 'green' }} />
                  ) : (
                    <EmailTwoToneIcon key={infraction.id} />
                  )}
                </IconButton>
              </TableCell>
            </StripedTableRow>
          ))}
        </StripedTable>
        <AuditRobotStatePopup
          audit_record={[selectedInfraction.serial_number, selectedInfraction.created_at, { robot_state: selectedInfraction.state }]}
          isOpen={openStateDialog}
          onClose={() => setOpenStateDialog(false)}
        />
        <InfractionVideoDialog open={openVideoDialog} videoSrc={infractionVideoSrc} onClose={() => setOpenVideoDialog(false)} />
      </DialogContent>
    </Dialog>
  );
};
