import React from 'react';
import { Button, Dialog, DialogContent, DialogActions, Typography } from '@material-ui/core';
import { DialogTitle } from '../core/dialog.component';

const ConfirmActionDialog = ({ handleAction, handleClose, title, body, open, action = 'Delete', containerRef, backdropProps }) => (
  <Dialog
    open={open}
    container={containerRef === undefined || !containerRef ? null : containerRef.current}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    fullWidth
    maxWidth={'xs'}
    style={{ position: 'absolute' }}
    BackdropProps={backdropProps}
  >
    <DialogTitle onClose={handleClose} id="alert-dialog-title">
      {title}
    </DialogTitle>
    <DialogContent>
      <Typography gutterBottom>{body}</Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} variant="outlined" color="primary">
        Cancel
      </Button>
      <Button onClick={handleAction} variant="contained" color="secondary">
        {action}
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmActionDialog;
