import { types } from 'mobx-state-tree';

export const ToolType = types.model({
  tool_type: types.string
});

export const BluetoothAdapter = types.model({
  id: types.maybeNull(types.string),
  mac_address: types.maybeNull(types.string),
  manufacturer: types.maybeNull(types.string)
});

export const rosTime = types.model({
  secs: types.maybeNull(types.number),
  nsecs: types.maybeNull(types.number)
});

export const BatteryInfo = types.model({
  percentage: types.maybeNull(types.number),
  temperature: types.maybeNull(types.number),
  voltage: types.maybeNull(types.number),
  current: types.maybeNull(types.number),
  type: types.maybeNull(types.string)
});

const BatteryState = types.model({
  number_of: types.maybeNull(types.number),
  percentage: types.maybeNull(types.number),
  battery_info: types.maybeNull(types.array(BatteryInfo))
});

export const Motor = types.model({
  id: types.maybeNull(types.string),
  motor_type: types.maybeNull(types.string),
  rpm: types.maybeNull(types.number),
  current: types.maybeNull(types.number),
  motor_temp: types.maybeNull(types.number),
  mc_temp: types.maybeNull(types.number),
  status: types.maybeNull(types.number),
  fault: types.maybeNull(types.string)
});

const SolarState = types.model({
  blades_running: types.maybeNull(types.boolean),
  deck_offset: types.maybeNull(types.number),
  frame_offset: types.maybeNull(types.number)
});

const LocationState = types.model({
  gps_fix_status: types.maybeNull(types.string),
  latitude: types.maybeNull(types.number),
  longitude: types.maybeNull(types.number),
  is_localization_stable: types.maybeNull(types.boolean)
});

const SafetyState = types.model({
  safety_enabled: types.maybeNull(types.boolean),
  safety_error_message: types.maybeNull(types.string),
  estop_is_on: types.maybeNull(types.boolean),
  sensing_edge_is_on: types.maybeNull(types.boolean),
  safety_stop: types.maybeNull(types.number),
  sw_estop_is_on: types.maybeNull(types.boolean),
  baseboard_is_active: types.maybeNull(types.boolean),
  attachment_active: types.maybeNull(types.boolean)
});

const ControlState = types.model({
  control_mode: types.maybeNull(types.string),
  tool_type: types.maybeNull(types.string),
  motors: types.maybeNull(types.array(Motor)),
  battery_state: types.maybeNull(BatteryState),
  solar_state: types.maybeNull(SolarState),
  oil_temperature: types.maybeNull(types.number),
  base_liquid_sensor: types.maybeNull(types.boolean),
  tank_liquid_sensor: types.maybeNull(types.boolean)
});

const JoystickState = types.model({
  connected: types.maybeNull(types.boolean),
  valid_command: types.maybeNull(types.boolean),
  connection_strength: types.maybeNull(types.string),
  controller_type: types.maybeNull(types.string),
  info: types.array(types.string),
  bluetooth_adapter: types.maybeNull(types.array(BluetoothAdapter))
});

export const ConnectedClient = types.model({
  ip_address: types.maybeNull(types.string),
  uuid: types.maybeNull(types.string),
  user: types.maybeNull(types.string),
  webpage: types.maybeNull(types.string),
  is_active: types.maybeNull(types.boolean),
  connection_time: types.maybeNull(rosTime)
});

const DeploymentState = types.model({
  git_update_state: types.maybeNull(types.integer),
  deployment_status: types.maybeNull(types.string),
  deployment_target: types.maybeNull(types.string),
  version: types.maybeNull(types.string),
  commit: types.maybeNull(types.string),
  free_storage: types.maybeNull(types.integer),
  branch_deployed: types.maybeNull(types.string)
});

const NetworkState = types.model({
  portal_client_count: types.maybeNull(types.number),
  iot_robot_latency: types.maybeNull(types.number),
  download_speed: types.maybeNull(types.number),
  upload_speed: types.maybeNull(types.number),
  interface_type: types.maybeNull(types.string),
  active_client: types.maybeNull(ConnectedClient),
  is_online: types.maybeNull(types.boolean),
  upload_throughput: types.maybeNull(types.number),
  download_throughput: types.maybeNull(types.number),
  last_speed_test: types.maybeNull(types.string)
});

const NavigationState = types.model({
  wps_state: types.maybeNull(types.number),
  autonomy_navigation_mode: types.maybeNull(types.string),
  autonomy_navigation_controller: types.maybeNull(types.string),
  autonomy_navigation_deviation_check: types.maybeNull(types.boolean),
  autonomy_navigation_deviation_slow_threshold: types.maybeNull(types.number),
  autonomy_navigation_deviation_stop_threshold: types.maybeNull(types.number),
  autonomy_navigation_deviation_slow_speed: types.maybeNull(types.number)
});

const RobotState = types.model({
  location_state: types.maybeNull(LocationState),
  control_state: types.maybeNull(ControlState),
  safety_state: types.maybeNull(SafetyState),
  joystick_state: types.maybeNull(JoystickState),
  deployment_state: types.maybeNull(DeploymentState),
  network_state: types.maybeNull(NetworkState),
  navigation_state: types.maybeNull(NavigationState)
});

export const AutonomyRobot = types.model({
  id: types.integer,
  name: types.string,
  serial_number: types.string,
  status: types.string,
  use_case: types.string,
  last_operation_date: types.maybeNull(types.string),
  last_status_update_date: types.maybeNull(types.string),
  created_at: types.string,
  updated_at: types.string,
  home_property_id: types.integer,
  current_property_id: types.integer,
  operating_subrow_id: types.maybeNull(types.integer),
  operating_subsection_id: types.maybeNull(types.integer),
  current_user: types.maybeNull(types.string),
  home_region_id: types.integer,
  home_region_name: types.string,
  current_region_id: types.integer,
  current_region_name: types.string,
  current_country_id: types.maybeNull(types.integer),
  home_property_name: types.maybeNull(types.string),
  current_property_name: types.maybeNull(types.string),
  battery_level: types.maybeNull(types.number),
  last_battery_update_date: types.maybeNull(types.string),
  last_ping: types.maybeNull(types.Date),
  robot_state: types.maybeNull(RobotState),
  assignee: types.maybeNull(types.string),
  latitude: types.maybeNull(types.number),
  longitude: types.maybeNull(types.number),
  tool_type: types.maybeNull(types.string)
});
