import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useStores } from '../../store/root/root.store';
import { capitalize } from '../../utils/ui.utils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxHeight: '200px',
    objectFit: 'contain',
    border: `1px solid ${theme.palette.grey[500]}`,
    cursor: 'pointer'
  },
  dialogContainer: {
    width: '100%',
    height: '100%'
  },
  fullImage: {
    objectFit: 'contain',
    width: '100%',
    maxHeight: '800px',
    border: `1px solid ${theme.palette.grey[500]}`
  }
}));

export const AuditImage = ({ filename, camera }) => {
  const classes = useStyles();
  const [base64, setBase64] = useState();
  const [viewFull, setViewFull] = useState(false);
  const { auditReportsStore } = useStores();

  const handleImageClick = () => {
    setViewFull(true);
  };

  useEffect(() => {
    let isMounted = true;
    async function f() {
      const { data } = await auditReportsStore.getReportMedia(filename);
      if (isMounted) {
        setBase64(data);
      }
    }
    f();
    return () => {
      isMounted = false;
    };
  }, [filename, auditReportsStore]);

  return (
    <>
      {base64 ? (
        <img onClick={handleImageClick} className={classes.root} src={`data:image/jpeg;base64,${base64}`} alt=""></img>
      ) : (
        <CircularProgress color="secondary" />
      )}
      {viewFull ? (
        <Dialog open={viewFull} fullWidth maxWidth="xl">
          <DialogTitle>{`Camera: ${capitalize(camera?.replace(/_/g, ' '))}`}</DialogTitle>
          <DialogContent>
            <div className={classes.dialogContainer}>
              <img className={classes.fullImage} src={`data:image/jpeg;base64,${base64}`} alt=""></img>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setViewFull(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};
