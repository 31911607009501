import { types } from 'mobx-state-tree';
import { MotorState } from './audit-motor-state.model';
import { Battery } from './audit-battery.model';
import { SnowControlState } from './audit-snow-control-state.model';
import { SolarControlState } from './audit-solar-control-state.model';

export const ControlState = types.model({
  control_mode: types.maybeNull(types.string),
  tool_type: types.maybeNull(types.string),
  motors: types.maybeNull(types.array(MotorState)),
  battery_state: types.maybeNull(Battery),
  snow_state: types.maybeNull(SnowControlState),
  solar_state: types.maybeNull(SolarControlState),
  oil_temperature: types.maybeNull(types.number),
  base_liquid_sensor: types.maybeNull(types.boolean),
  tank_liquid_sensor: types.maybeNull(types.boolean),
  light_is_on: types.maybeNull(types.boolean),
  beeper_is_on: types.maybeNull(types.boolean),
  fan_is_on: types.maybeNull(types.boolean),
  wiper_level: types.maybeNull(types.number)
});
