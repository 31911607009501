import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles, Typography } from '@material-ui/core';
import { ChaperoneRobotConnectionService } from '../../services/chaperone/robot-connection.service';
import { useStores } from '../../store/root/root.store';
import { isDevMode } from '../../utils/ui.utils';
import ConnectionErrorDialog from '../dialogs/connection-error.dialog';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleFilled';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';

const useStyles = makeStyles((theme) => ({
  robotCard: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    border: '1px solid #000'
  },
  name: {
    display: 'flex',
    flexDirection: 'column'
  },
  PlayCircleOutlineIcon: {
    color: '#000',
    height: 35,
    width: 35,
    zIndex: 100,
    cursor: 'pointer'
  }
}));

export const EStopControl = observer(({ serialNumber, allEstop }) => {
  const { MultiRobotsStore } = useStores();
  const isMountedRef = useRef(null);
  const robotConnectionService = useRef(null);
  const robotConnection = useRef(null);
  const userName = localStorage.getItem('username');
  const classes = useStyles();
  const robotSwEstopState = MultiRobotsStore.robots.get(serialNumber)?.swEstopState;
  const robotSensingEdgeState = false; // Just hard-coding it to false until further notice //MultiRobotsStore.robots.get(serialNumber)?.sensingEdgeState;
  const robotEstopState = MultiRobotsStore.robots.get(serialNumber)?.estopState;
  const isRobotPhysicallyEstopped = robotSensingEdgeState || robotEstopState;

  const [connectionError, setConnectionError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleConnectionError = () => {
    if (isDevMode) {
      console.log('Developing mode...');
      return;
    }
    setConnectionError(true);
    setErrorMessage(
      `An error occurred with robot ${serialNumber}, check the robot's internet connection and if the issue occurs again, try to reboot the robot.`
    );
  };

  const getRobotCardBackground = () => {
    let background = '';
    robotSwEstopState || isRobotPhysicallyEstopped ? (background = '#e6160f') : (background = '#77DD77');
    return background;
  };

  useEffect(() => {
    if (allEstop) {
      !isRobotPhysicallyEstopped && robotConnection?.current?.ros?.cmdSwEstop(true);
    } else {
      !isRobotPhysicallyEstopped && robotConnection?.current?.ros?.cmdSwEstop(false);
    }
  }, [allEstop]);

  useEffect(() => {
    // Confirm the robot has a WebSocket connection to the gateway
    (async () => {
      if (serialNumber) {
        // Running async code in the effect, we need to track component is mounted
        isMountedRef.current = true;
        try {
          // On first load, initiate the robot connection
          if (isMountedRef.current && robotConnection.current === null) {
            robotConnectionService.current = new ChaperoneRobotConnectionService(
              () => {
                // onConnected
                robotConnectionService?.current?.ros.subscribeToRobotStateStamped((robotState) =>
                  MultiRobotsStore.updateState(serialNumber, robotState)
                );
                robotConnection.current = robotConnectionService.current;
              },
              () => {
                // onDisconnect
                console.log('Lost connection to robot');
                if (robotConnectionService.current !== null) {
                  robotConnectionService?.current?.retryConnection();
                }
                handleConnectionError();
                robotConnection.current = null;
              },
              serialNumber,
              userName,
              'estop_control'
            );
          }
        } catch (error) {
          console.error('Failed to connect', error);
        }
      }
    })();

    robotConnectionService?.current?.connectToRobot(handleConnectionError);
    // Cleanup function
    return () => {
      isMountedRef.current = false;
      if (robotConnectionService.current !== null) {
        robotConnectionService?.current?.destroy();
        robotConnectionService.current = null;
        robotConnection.current = null;
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <ConnectionErrorDialog open={connectionError} handleClose={() => setConnectionError(false)} errorMessage={errorMessage} />
      <div className={classes.robotCard} style={{ backgroundColor: getRobotCardBackground() }}>
        <div className={classes.name}>
          <Typography variant="body2">{serialNumber}</Typography>
          {robotSwEstopState || isRobotPhysicallyEstopped ? (
            <Typography variant="body1">{isRobotPhysicallyEstopped ? 'Physically E-Stopped' : 'E-Stopped'}</Typography>
          ) : (
            <Typography variant="body1">Operating</Typography>
          )}
        </div>
        <div>
          {robotSwEstopState || isRobotPhysicallyEstopped ? (
            <PlayCircleOutlineIcon
              className={classes.PlayCircleOutlineIcon}
              style={{ opacity: isRobotPhysicallyEstopped && '0.4' }}
              color="action"
              onClick={() => {
                !isRobotPhysicallyEstopped && robotConnection?.current?.ros?.cmdSwEstop(!robotSwEstopState);
              }}
            />
          ) : (
            <PauseCircleOutlineIcon
              className={classes.PlayCircleOutlineIcon}
              color="action"
              onClick={() => !isRobotPhysicallyEstopped && robotConnection?.current?.ros?.cmdSwEstop(!robotSwEstopState)}
            />
          )}
        </div>
      </div>
    </div>
  );
});
