import { types } from 'mobx-state-tree';

export const SolarSubrow = types.model({
  id: types.integer,
  created_at: types.string,
  updated_at: types.string,
  status: types.maybeNull(types.string),
  direction: types.string,
  order_index: types.integer,
  path_type: types.string,
  name: types.maybeNull(types.string)
});
