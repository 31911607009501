import { flow, getEnv, types } from 'mobx-state-tree';
import { ResourceStore } from '../resources/resource.store';
import { AuditFilter, DataViewOptions } from './audit-filter.model';
import { AuditReport } from './audit-report.model';
import { fetchOpsAudit } from '../../services/api/audit-reports.service';

const AuditReportsStoreInternal = types
  .model('AuditReportsStore', {
    filter: types.optional(AuditFilter, {}),
    records: types.optional(types.array(AuditReport), []),
    selectedRecord: types.maybeNull(types.reference(AuditReport)),
    loading: false
  })
  .views((self) => ({
    getDataViewOptions() {
      return DataViewOptions._types.map((t) => t.value);
    },
    getDefaultDataView() {
      return this.getDataViewOptions()[0];
    },
    getCurrentDataView() {
      return this.filter.dataView;
    },
    get recordsWithGpsCoords() {
      return self.records.filter((r) => {
        const loc = r.location;
        if (!loc.latitude && !loc.longitude) {
          return false;
        } else if (loc.latitude === 0 && loc.longitude === 0) {
          return false;
        } else {
          return true;
        }
      });
    }
  }))
  .actions((self) => ({
    setFilter(filterOptions) {
      self.filter = filterOptions;
      self.lastResult = null;
      self.hasMoreResults = false;
    },
    getApiFetch: flow(function* f() {
      try {
        self.loading = true;
        const queryParameters = self.constructQueryParams();
        const result = yield fetchOpsAudit(queryParameters);
        self.onFetchSuccess(result.data.results);
      } catch (error) {
        console.error(`Error retrieving Ops Audit Data. Error: ${error}`);
        self.onFetchError(error);
      }
    }),
    getReports: flow(function* getReports() {
      try {
        self.selectedRecord = null;
        yield self.getApiFetch();
      } catch (error) {
        console.error('Error while retrieving Ops Audit Data', error);
      }
    }),
    setDataView(view) {
      self.filter.dataView = view;
    },
    onFetchSuccess(results) {
      self.loading = false;
      self.records = results;
    },
    onFetchError(error) {
      self.loading = false;
      self.throwError(error?.message || 'Unable to load audit records');
    },
    selectRecord: (record) => (self.selectedRecord = record),

    getReportMedia(filename, isVideoFeed) {
      const { AuditReportsService } = getEnv(self);
      return AuditReportsService.fetchReportMedia(filename, isVideoFeed);
    },
    clearReports() {
      self.records = [];
    },
    constructQueryParams: () => {
      const formattedStarDate = new Date(self.filter.startTime).toISOString();
      const formattedEndDate = new Date(self.filter.endTime).toISOString();
      const { country, region, property, robotList } = self.filter;
      const params = { formattedStarDate, formattedEndDate, country, property, region, robotList };
      return params;
    },
    resetLoading() {
      self.loading = false;
    }
  }));

export const AuditReportsStore = types.compose(AuditReportsStoreInternal, ResourceStore).named('AuditReportsStore');
