import { Grid, Typography, makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../store/root/root.store';
import { AddRegion } from './dialogs/add-region.dialog';
import { AddChaperoneProperty } from './dialogs/add-chaperone-property.dialog';
import { ControlledSelectField } from './inputs/ControlledSelectField';
import { AddCountry } from './dialogs/add-country.dialog';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
  SNOW_RG_PREFIX,
  SOLAR_RG_PREFIX
} from '../utils/localstorage.controller';
import { AddSection } from './dialogs/add-section.dialog';
import { AVAILABLE, WPS_SUSPENDED, EXEC_SUSPENDED, DEFAULT_PATH_TYPE } from '../utils/constants';
import { getSubBlockPathTypes } from '../services/api/subrows.service';
import { formatStringForReadbility } from '../utils/ui.utils';
import { AddBlock } from './dialogs/add-block.dialog';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240
  },
  select: {
    backgroundColor: theme.palette.inverted.main,
    padding: theme.spacing(1)
  },
  label: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 150
  },
  titleLable: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 250
  },
  selectButton: {
    height: '100%',
    width: '100%',
    backgroundColor: '#0000FF'
  },
  helperText: {
    color: 'red'
  }
}));

const ManageLocations = observer(({ pagePrefix, solarMode }) => {
  const {
    authenticationStore,
    countriesStore,
    regionsStore,
    sectionStore,
    chaperonePropertyStore,
    blocksStore,
    subBlocksStore,
    subrowStore,
    autonomyRobotStore
  } = useStores();
  const classes = useStyles();

  const [fieldUsed, setFieldUsed] = useState({});
  const [openAddCountryDialog, setOpenAddCountryDialog] = useState(false);
  const [openAddRegionDialog, setOpenAddRegionDialog] = useState(false);
  const [openAddPropertyDialog, setOpenAddPropertyDialog] = useState(false);
  const [openAddSectionDialog, setOpenAddSectionDialog] = useState(false);
  const [openAddBlockDialog, setOpenAddBlockDialog] = useState(false);
  const [pathTypeOptions, setpathTypeOptions] = useState([]);

  const country = countriesStore.getSelectedCountry()?.id;
  const region = regionsStore.getSelectedRegion()?.id;
  const property = chaperonePropertyStore.getSelectedProperty()?.id;
  const section = sectionStore.getSelectedSection()?.id;
  const block = blocksStore.getSelectedBlock()?.id;
  const subBlock = subBlocksStore.getSelectedSubBlock()?.id;
  const robot = autonomyRobotStore.getSelectedRobot()?.id;
  const pathType = subrowStore.getSelectedPathType();
  const isManager = !!(authenticationStore.role === 'manager' || authenticationStore.role === 'admin');
  const countryFromLocalStorage = () => parseInt(getLocalStorageItem('country', pagePrefix)) || undefined;
  const regionFromLocalStorage = () => parseInt(getLocalStorageItem('region', pagePrefix)) || undefined;
  const propertyFromLocalStorage = () => parseInt(getLocalStorageItem('property', pagePrefix)) || undefined;
  const sectionFromLocalStorage = () => parseInt(getLocalStorageItem('section', pagePrefix)) || undefined;
  const blockFromLocalStorage = () => parseInt(getLocalStorageItem('block', pagePrefix)) || undefined;
  const subBlockFromLocalStorage = () => parseInt(getLocalStorageItem('subBlock', pagePrefix)) || undefined;
  const pathTypeFromLocalStorage = () => getLocalStorageItem('pathType', pagePrefix);
  const useCase = localStorage.getItem('useCase');

  useEffect(() => {
    async function fetchData() {
      countriesStore.clearSelectedCountry();
      await countriesStore.getCountries();
      if (countryFromLocalStorage()) {
        countriesStore.setSelectedCountry(countryFromLocalStorage());
      }
    }
    if (pathTypeFromLocalStorage()) {
      subrowStore.setSelectedPathType(pathTypeFromLocalStorage());
    }
    fetchData();
  }, []);

  const updateUsedField = (fieldName) => {
    setFieldUsed({ ...fieldUsed, [fieldName]: true });
  };

  const getRobots = async () => {
    const selectedCountry = country || undefined;
    const selectedRegion = region || undefined;
    const selectedProperty = property || undefined;
    const selectedUseCase = useCase || undefined;
    if (![SNOW_RG_PREFIX, SOLAR_RG_PREFIX].includes(pagePrefix)) {
      await autonomyRobotStore.getRobots(selectedCountry, selectedRegion, selectedProperty, selectedUseCase);
    }
  };

  const getPathTypes = async () => {
    if (subBlock) {
      const paths = await getSubBlockPathTypes(subBlock);
      const pathsOptions = paths.map((path) => ({ id: path, name: formatStringForReadbility(path.replace('_', ' ')), value: path }));
      setpathTypeOptions(pathsOptions);
    }
  };

  useEffect(() => {
    async function getRegionsByCountry() {
      if (country) {
        updateUsedField('country');
        await regionsStore.getRegions(country);
        regionsStore.clearSelectedRegion();
        if (regionFromLocalStorage()) {
          regionsStore.setSelectedRegion(regionFromLocalStorage());
        }
      }
    }
    getRegionsByCountry();
  }, [country]);

  useEffect(() => {
    async function getProperties() {
      if (region) {
        updateUsedField('region');
        await chaperonePropertyStore.getProperties(region);
        chaperonePropertyStore.clearSelectedProperty();
        if (propertyFromLocalStorage()) {
          chaperonePropertyStore.setSelectedProperty(propertyFromLocalStorage());
        }
      }
    }
    getProperties();
  }, [region]);

  useEffect(() => {
    async function getSections() {
      if (!solarMode) {
        if (region && property) {
          updateUsedField('property');
          sectionStore.getSections(region, property, !!solarMode);
          sectionStore.clearSelectedSection();
          if (sectionFromLocalStorage()) {
            sectionStore.setSelectedSection(sectionFromLocalStorage());
          }
        }
      }
    }
    getSections();
  }, [region, property]);

  useEffect(() => {
    async function getBlocks() {
      if (solarMode) {
        if (region && property) {
          updateUsedField('property');
          blocksStore.getBlocks(property);
          blocksStore.clearSelectedBlock();
          if (blockFromLocalStorage()) {
            blocksStore.setSelectedBlock(blockFromLocalStorage());
          }
        }
      }
    }
    getBlocks();
  }, [region, property]);

  useEffect(() => {
    async function getSubBlocks() {
      if (solarMode) {
        if (block && property) {
          updateUsedField('subBlock');
          subBlocksStore.getSubBlocks(block);
          subBlocksStore.clearSelectedSubBlock();
          if (subBlockFromLocalStorage()) {
            subBlocksStore.setSelectedSubBlock(subBlockFromLocalStorage());
            if (pathTypeFromLocalStorage) {
              subrowStore.setSelectedPathType(pathTypeFromLocalStorage());
            }
          }
        }
      }
    }
    getSubBlocks();
  }, [property, block]);

  useEffect(() => {
    getRobots();
  }, [country, region, property, block, useCase]);

  useEffect(() => {
    if (subBlock) {
      updateUsedField('subBlock');
      getPathTypes();
    }
  }, [subBlock]);

  const clearSelectedBlocksOrSections = (removedKeys) => {
    let keys = [];
    if (solarMode) {
      blocksStore.clearSelectedBlock();
      blocksStore.clearBlocks();
      subBlocksStore.clearSubBlocks();
      subBlocksStore.clearSelectedSubBlock();
      removedKeys ? (keys = ['block', 'subBlock', ...removedKeys]) : (keys = ['block', 'subBlock']);
    } else {
      sectionStore.clearSections();
      sectionStore.clearSelectedSection();
      removedKeys ? (keys = ['section', ...removedKeys]) : (keys = ['section']);
    }
    keys.forEach((key) => removeLocalStorageItem(key, pagePrefix));
  };

  const clearRelatedSelections = (currentStep) => {
    if (currentStep === 'block') {
      subBlocksStore.clearSubBlocks();
      subBlocksStore.clearSelectedSubBlock();
      removeLocalStorageItem('subBlock', pagePrefix);
    } else if (currentStep === 'property') {
      clearSelectedBlocksOrSections();
    } else if (currentStep === 'region') {
      clearSelectedBlocksOrSections(['property']);
      chaperonePropertyStore.clearProperties();
      chaperonePropertyStore.clearSelectedProperty();
    } else if (currentStep === 'country') {
      clearSelectedBlocksOrSections(['region', 'property']);
      regionsStore.clearRegions();
      regionsStore.clearSelectedRegion();
      chaperonePropertyStore.clearProperties();
      chaperonePropertyStore.clearSelectedProperty();
    }
    subrowStore.clearPathType();
  };

  const handleCloseCountryDialog = (countryId) => {
    if (countryId) {
      clearRelatedSelections('country');
      countriesStore.setSelectedCountry(countryId);
      setLocalStorageItem('country', countryId, pagePrefix);
    }
    setOpenAddCountryDialog(false);
  };

  const handleCloseRegionDialog = (regionId) => {
    if (regionId) {
      clearRelatedSelections('region');
      regionsStore.setSelectedRegion(regionId);
      setLocalStorageItem('region', regionId, pagePrefix);
    }
    setOpenAddRegionDialog(false);
  };

  const handleClosePropertyDialog = (propertyId) => {
    if (propertyId) {
      clearRelatedSelections('property');
      chaperonePropertyStore.setSelectedProperty(propertyId);
      setLocalStorageItem('property', propertyId, pagePrefix);
    }
    setOpenAddPropertyDialog(false);
  };

  const handleCloseSectionDialog = (sectionId) => {
    if (sectionId) {
      sectionStore.setSelectedSection(sectionId);
      setLocalStorageItem('section', sectionId, pagePrefix);
    }
    setOpenAddSectionDialog(false);
  };

  const handleCloseBlockDialog = (blockId) => {
    if (blockId) {
      blocksStore.setSelectedBlock(blockId);
      setLocalStorageItem('block', blockId, pagePrefix);
      subBlocksStore.clearSubBlocks();
      subBlocksStore.clearSelectedSubBlock();
    }
    setOpenAddBlockDialog(false);
  };

  const formattedRobotOptions = autonomyRobotStore.robots?.map(({ id, serial_number: serialNumber, status, name }) => ({
    id,
    name: `${name} - ${serialNumber}`,
    isDisabled: ![AVAILABLE, EXEC_SUSPENDED, WPS_SUSPENDED].includes(status)
  }));

  return (
    <>
      <Grid item xs={4}>
        <Typography variant="h4" className={classes.titleLable}>
          {isManager ? 'Manage Locations' : 'Select Area'}
        </Typography>
      </Grid>
      <ControlledSelectField
        label="Country"
        id="country"
        value={country || 0}
        handleSelectOption={(e) => {
          subrowStore.clearPathType();
          if (typeof e.target.value === 'number') {
            clearRelatedSelections('country');
            setLocalStorageItem('country', e.target.value, pagePrefix);
            countriesStore.setSelectedCountry(e.target.value);
          }
        }}
        options={countriesStore.countries}
        classes={classes}
        formError={fieldUsed.country}
        required
        includeNone
        isDialog
        setOpenDialog={() => setOpenAddCountryDialog(true)}
        openDialogText="Add Country"
      />
      {openAddCountryDialog && <AddCountry show={openAddCountryDialog} onClose={handleCloseCountryDialog} />}

      <ControlledSelectField
        label="Region"
        id="region"
        value={region || 0}
        handleSelectOption={(e) => {
          subrowStore.clearPathType();
          if (typeof e.target.value === 'number') {
            clearRelatedSelections('region');
            setLocalStorageItem('region', e.target.value, pagePrefix);
            regionsStore.setSelectedRegion(e.target.value);
            updateUsedField('region');
          }
        }}
        options={regionsStore.regions}
        classes={classes}
        formError={fieldUsed.region}
        required
        includeNone
        isDialog={country}
        setOpenDialog={() => setOpenAddRegionDialog(true)}
        openDialogText="Add Region"
      />
      {openAddRegionDialog ? <AddRegion show={openAddRegionDialog} onClose={handleCloseRegionDialog} /> : null}

      <ControlledSelectField
        label="Property"
        id="property"
        value={property || 0}
        handleSelectOption={(e) => {
          subrowStore.clearPathType();
          if (typeof e.target.value === 'number') {
            clearRelatedSelections('property');
            chaperonePropertyStore.setSelectedProperty(e.target.value);
            setLocalStorageItem('property', e.target.value, pagePrefix);
            updateUsedField('property');
          }
        }}
        options={chaperonePropertyStore.properties}
        classes={classes}
        formError={fieldUsed.property}
        required
        includeNone
        isDialog={region}
        setOpenDialog={() => setOpenAddPropertyDialog(true)}
        openDialogText="Add Property"
      />
      {openAddPropertyDialog ? <AddChaperoneProperty show={openAddPropertyDialog} onClose={handleClosePropertyDialog} /> : null}

      {solarMode ? (
        <>
          <ControlledSelectField
            label="Block"
            id="block"
            value={block || 0}
            handleSelectOption={(e) => {
              subrowStore.clearPathType();
              if (typeof e.target.value === 'number') {
                clearRelatedSelections('block');
                blocksStore.setSelectedBlock(e.target.value);
                setLocalStorageItem('block', e.target.value, pagePrefix);
                updateUsedField('block');
              }
            }}
            options={blocksStore.blocks}
            classes={classes}
            formError={fieldUsed.block}
            required
            includeNone
            isDialog={property}
            setOpenDialog={() => setOpenAddBlockDialog(true)}
            openDialogText="Add Block"
          />
          {openAddBlockDialog ? <AddBlock show={openAddBlockDialog} onClose={handleCloseBlockDialog} /> : null}
        </>
      ) : (
        <>
          <ControlledSelectField
            label="Section"
            id="section"
            value={section || 0}
            handleSelectOption={(e) => {
              subrowStore.clearPathType();
              if (typeof e.target.value === 'number') {
                sectionStore.setSelectedSection(e.target.value);
                setLocalStorageItem('section', e.target.value, pagePrefix);
                updateUsedField('section');
              }
            }}
            options={sectionStore.sections}
            classes={classes}
            formError={fieldUsed.section}
            required
            includeNone
            isDialog={property}
            setOpenDialog={() => setOpenAddSectionDialog(true)}
            openDialogText="Add Section"
          />
          {openAddSectionDialog ? <AddSection show={openAddSectionDialog} onClose={handleCloseSectionDialog} /> : null}
        </>
      )}

      {solarMode && (
        <ControlledSelectField
          label="Sub Block"
          id="subBlock"
          value={subBlock || 0}
          handleSelectOption={(e) => {
            subrowStore.clearPathType();
            if (typeof e.target.value === 'number') {
              const selectedSubBlock = e.target.value;
              subBlocksStore.setSelectedSubBlock(selectedSubBlock);
              setLocalStorageItem('subBlock', selectedSubBlock, pagePrefix);
              updateUsedField('subBlock');
              if (selectedSubBlock !== 0) {
                subrowStore.setSelectedPathType(DEFAULT_PATH_TYPE);
              } else {
                subrowStore.setSelectedPathType(undefined);
              }
            }
          }}
          options={subBlocksStore.subBlocks}
          classes={classes}
          formError={fieldUsed.subBlock}
          required
          includeNone
        />
      )}

      <ControlledSelectField
        label="PathType"
        id="pathType"
        value={pathType || 0}
        handleSelectOption={(e) => {
          if (['string', 'number'].includes(typeof e.target.value)) {
            const selectedPathType = e.target.value === 0 ? null : e.target.value;
            setLocalStorageItem('pathType', e.target.value, pagePrefix);
            subrowStore.setSelectedPathType(selectedPathType);
          }
          updateUsedField('pathType');
        }}
        options={pathTypeOptions}
        classes={classes}
        formError={fieldUsed.pathType}
        includeNone
        required
      />

      {![SNOW_RG_PREFIX, SOLAR_RG_PREFIX].includes(pagePrefix) && (
        <ControlledSelectField
          label="Robot"
          id="robot"
          value={robot || 0}
          handleSelectOption={(e) => {
            if (typeof e.target.value === 'number') {
              autonomyRobotStore.setSelectedRobot(e.target.value);
              updateUsedField('robot');
            }
          }}
          options={formattedRobotOptions}
          classes={classes}
          formError={fieldUsed.robot}
          required
          includeNone
        />
      )}
    </>
  );
});

export default ManageLocations;
