import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { ensureWebComponentIsLoaded } from '../../utils/external-scripts.utls';
import { useStores } from '../../store/root/root.store';
import { HIGH_LATENCY_THRESHOLD_SECONDS, IN_REVERSE_VELOCITY_THRESHOLD } from '../../utils/constants';

const useStyles = makeStyles(() => ({
  root: {
    background: 'rgba(32, 32, 32, 1.0)',
    height: '100%',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    maxHeight: '1090px'
  },
  webComponentRemoteGuardian: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    // video3 -> left, video1 -> rear, video0 -> front, video2 -> right
    '& .webrtc-video3': {
      position: 'absolute',
      left: '0%',
      width: '25.0%'
    },
    '& .webrtc-video1': {
      position: 'absolute',
      top: '520px',
      left: '0%',
      width: '25.0%',
      zIndex: '1'
    },
    '& .webrtc-video0': {
      position: 'absolute',
      left: '25.0%',
      width: '50.0%'
    },
    '& .webrtc-video2': {
      position: 'absolute',
      left: '75.0%',
      width: '25.0%',
      zindex: '1'
    }
  },
  webComponentRemoteGuardianRearFOV: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    // video3 -> left, video1 -> rear, video0 -> front, video2 -> right
    '& .webrtc-video3': {
      position: 'absolute',
      left: '0%',
      width: '25.0%'
    },
    '& .webrtc-video1': {
      position: 'absolute',
      left: '25.0%',
      width: '50.0%'
    },
    '& .webrtc-video0': {
      position: 'absolute',
      top: '520px',
      left: '0%',
      width: '25.0%',
      zIndex: '1'
    },
    '& .webrtc-video2': {
      position: 'absolute',
      left: '75.0%',
      width: '25.0%',
      zindex: '1'
    }
  },
  webComponentVideocontainer: {
    '& .webrtc-video-wrapper': {
      display: 'grid',
      maxHeight: '100%',
      maxWidth: '100%',
      height: '100%',
      width: '100%',
      backgroundColor: 'black',
      gridTemplateColumns: '0.7fr 0.9fr 1.4fr;',
      gridTemplateRows: '1.5fr 0.5fr 1fr',
      gap: '0px 0px',
      gridAutoFlow: 'row',
      gridTemplateAreas: `"rearCamera leftCamera frontCamera"
      "rearCamera rightCamera frontCamera"
      ". rightCamera frontCamera"`
    },
    '& .webrtc-video0': {
      gridArea: 'frontCamera',
      height: '502px',
      width: '550px',
      maxHeight: '100%',
      maxWidth: '100%',
      margin: '0px',
      padding: '0px'
    },
    '& .webrtc-video1': {
      gridArea: 'rearCamera',
      height: '240px',
      width: '100%',
      maxWidth: '100%'
    },
    '& .webrtc-video2': {
      gridArea: 'leftCamera',
      height: '240px',
      width: '100%',
      maxHeight: '95%',
      maxWidth: '100%'
    },
    '& .webrtc-video3': {
      gridArea: 'rightCamera',
      height: '240px',
      width: '100%',
      marginTop: '0px',
      maxHeight: '95%',
      maxWidth: '100%'
    }
  },
  webComponentVideocontainerRearCameraFOV: {
    '& .webrtc-video-wrapper': {
      display: 'grid',
      maxHeight: '100%',
      maxWidth: '100%',
      height: '100%',
      width: '100%',
      backgroundColor: 'black',
      gridTemplateColumns: '0.7fr 0.9fr 1.4fr;',
      gridTemplateRows: '1.5fr 0.5fr 1fr',
      gap: '0px 0px',
      gridAutoFlow: 'row',
      gridTemplateAreas: `"frontCamera leftCamera rearCamera"
      "frontCamera rightCamera rearCamera"
      ". rightCamera rearCamera"`
    },
    '& .webrtc-video0': {
      gridArea: 'frontCamera',
      height: '240px',
      width: '100%',
      maxWidth: '100%',
      margin: '0px',
      padding: '0px'
    },
    '& .webrtc-video1': {
      gridArea: 'rearCamera',
      height: '502px',
      width: '550px',
      maxHeight: '100%',
      maxWidth: '100%',
      margin: '0px',
      padding: '0px'
    },
    '& .webrtc-video2': {
      gridArea: 'leftCamera',
      height: '240px',
      width: '100%',
      maxHeight: '95%',
      maxWidth: '100%'
    },
    '& .webrtc-video3': {
      gridArea: 'rightCamera',
      height: '240px',
      width: '100%',
      marginTop: '0px',
      maxHeight: '95%',
      maxWidth: '100%'
    }
  }
}));

const VideoStreamMultiPageView = observer(({ serialNumber, hide = false, remoteGuardianView = false }) => {
  const { MultiRobotsStore } = useStores();
  const streamToken = MultiRobotsStore.robots.get(serialNumber)?.streamToken;
  const isReversing = MultiRobotsStore.robots.get(serialNumber)?.linearVelocity < IN_REVERSE_VELOCITY_THRESHOLD;
  const videoDeviceRef = useRef();
  const classes = useStyles();

  useEffect(() => {
    if (!remoteGuardianView) {
      ensureWebComponentIsLoaded(`d_${serialNumber}`, () => MultiRobotsStore.getRobotStreamToken(serialNumber), true);
    }
    // return () => {
    //   const script = document.getElementById('webrtc-web-component-video');
    //   if (script) script.remove();
    // };
  }, [serialNumber]);

  /* useEffect(() => {
    let timeoutId;
  
    const checkLatency = async () => {
      try {
        const lag = await new Promise((resolve) => {
          timeoutId = setTimeout(() => {
            videoDeviceRef.current?.call('onLag', (lag) => {
              resolve(lag);
            });
          }, 2000);
        });
  
        if (lag.lag > HIGH_LATENCY_THRESHOLD_SECONDS) {
          MultiRobotsStore.updateLatency(serialNumber, true, lag.lag);
        } else {
          MultiRobotsStore.updateLatency(serialNumber, false, lag.lag);
        }
      } catch (e) {
        console.warn('onLag event handler', e);
      }
    };
  
    checkLatency();
  
    return () => {
      clearTimeout(timeoutId);
    };
  }, [videoDeviceRef.current]); */

  if (streamToken && remoteGuardianView) {
    return (
      <div className={classes.root}>
        <webrtc-video-device
          class={isReversing ? classes.webComponentRemoteGuardianRearFOV : classes.webComponentRemoteGuardian}
          id="swap"
          host="transitiverobotics.com"
          ssl="true"
          jwt={streamToken}
          use-default="true"
          timeout="86400"
          exclusive="true"
          ref={videoDeviceRef}
        />
      </div>
    );
  }
  if (streamToken && !hide) {
    return (
      <div>
        <webrtc-video-device
          class={isReversing ? classes.webComponentVideocontainerRearCameraFOV : classes.webComponentVideocontainer}
          id="swap"
          host="transitiverobotics.com"
          ssl="true"
          jwt={streamToken}
          use-default="true"
          timeout="86400"
          exclusive="true"
          ref={videoDeviceRef}
        />
      </div>
    );
  }
  return null;
});

export default VideoStreamMultiPageView;
