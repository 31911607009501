/* eslint-disable no-unused-expressions */
import { Button, FormControl, Grid, makeStyles, Typography, TextField, Fade, Select, MenuItem } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStores } from '../store/root/root.store';
import LoadingDialog from '../components/dialogs/loading-dialog.dialog';
import { ROUTE_SOLAR_CHAPERONE } from './routes';
import { generateSubRowsS3, uploadSubBlockSubrowsFile } from '../services/api/subrows.service';
import ConnectionErrorDialog from '../components/dialogs/connection-error.dialog';
import { INTER_ROW_MODES, GENERATE_METHODS, GENERATE_METHODS_OPTIONS, VEGETATION_CONDITION, WEATHER_CONDITION } from '../utils/constants';
import { AlertDialog } from '../components/dialogs/alert-dialog.dialog';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  formControlTextField: {
    paddingLeft: theme.spacing(10),
    margin: theme.spacing(1)
  },
  input: {
    backgroundColor: theme.palette.inverted.main
  },
  label: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 300
  },
  Button: {
    margin: theme.spacing(2),
    minWidth: 100
  },
  fileName: {
    color: theme.palette.inverted.main
  },
  helperText: {
    color: 'red'
  },
  selectComponent: {
    backgroundColor: theme.palette.inverted.main,
    minWidth: 195,
    minHeight: 40,
    paddingLeft: theme.spacing(1)
  }
}));

export const GenerateSolarPathsPage = observer(() => {
  const classes = useStyles();
  const { push } = useHistory();
  const { regionsStore, blocksStore, chaperonePropertyStore, subBlocksStore } = useStores();
  const [driveLine, setDriveLine] = useState(5.0);
  const [subBlockName, setSubBlockName] = useState('');
  const [subBlockPanelWidth, setSubBlockPanelWidth] = useState(2);
  const [driveWayWidth, setDriveWayWidth] = useState(4);
  const [pilePointSeparation, setPilePointSeparation] = useState(60);
  const [robotToPanelDistance, setRobotToPanelDistance] = useState(40);
  const [targetOffset, setTargetOffset] = useState(0);
  const [vegetationThickness, setVegetationThickness] = useState('moderate');
  const [weatherCondition, setWeatherCondition] = useState('dry');
  const [generationMethod, setGenerationMethod] = useState('coverage');
  const [generationMethodType, setGenerationMethodType] = useState('left_under_panel');
  const [interRowMode, setInterRowMode] = useState('floss');
  const [showGenerateMethodType, setShowGenerateMethodType] = useState(false);
  const [showInvalidPilePointAlert, setShowInvalidPilePointAlert] = useState(false);
  const [generationMethodTypeOptions, setGenerationMethodTypeOptions] = useState([]);
  const [fileInput, setFileInput] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const invalidPilePointMessage = {
    error: 'Invalid Pile Point Value',
    message: `Pile-Point cannot be smaller than 0 or larger than ${
      (subBlockPanelWidth * 60) / 2
    }cm (Panel-Width/2). Please provide a valid value`
  };
  const containerReference = useRef(null);

  const handleCloseErrorDialog = () => {
    push(ROUTE_SOLAR_CHAPERONE);
  };

  const handleShowDialog = (message) => {
    setLoading(true);
    setLoadingMessage(message);
  };

  const handleGenerateSolarRows = async () => {
    try {
      handleShowDialog('Generating Solar Paths...');
      const blockId = blocksStore.selectedBlockId;
      await blocksStore.updateBlock({ blockId, panelWidth: subBlockPanelWidth });
      const subBlock = await subBlocksStore.createSubBlock({ blockId: blocksStore.selectedBlockId, name: subBlockName });
      const result = await uploadSubBlockSubrowsFile(
        fileInput[0],
        regionsStore.selectedRegionId,
        chaperonePropertyStore.selectedPropertyId,
        blocksStore.selectedBlockId,
        subBlock.results?.id
      );
      const fileRemoteLocation = result.data?.Location;
      const pathGenerationResult = await generateSubRowsS3(
        fileRemoteLocation,
        driveLine,
        subBlockPanelWidth,
        driveWayWidth,
        pilePointSeparation,
        robotToPanelDistance,
        generationMethod,
        generationMethodType,
        targetOffset,
        vegetationThickness,
        weatherCondition,
        interRowMode
      );
      if (pathGenerationResult.data.statusCode === 200) {
        setLoading(false);
      } else if (pathGenerationResult.data.statusCode === 400) {
        setErrorMessage(pathGenerationResult.data.body);
        setTimeout(() => {
          setLoading(false);
        }, 3000);
      } else {
        throw new Error('This is an error to force an error');
      }
    } catch (error) {
      setErrorMessage('Error Occured While Generating Paths. Please Contact Autonomy Team');
      setTimeout(() => {
        setLoading(false);
      }, 3000);
      console.error(error);
    }
  };
  /** Controls rendering path generation method options &
   * adjust target offset based on generation method
   */
  useEffect(() => {
    if (generationMethod !== '') {
      if (generationMethod === 'coverage') {
        setGenerationMethodTypeOptions(GENERATE_METHODS_OPTIONS.coverage);
        setTargetOffset(70);
      } else if (generationMethod === 'lane_based') {
        setGenerationMethodTypeOptions(GENERATE_METHODS_OPTIONS.lane_based);
        setTargetOffset(132);
      }
      setShowGenerateMethodType(true);
    }
  }, [generationMethod]);

  return (
    <Fade in>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        className={classes.root}
        ref={containerReference}
      >
        <AlertDialog
          containerRef={containerReference}
          state={invalidPilePointMessage}
          show={showInvalidPilePointAlert}
          onClose={() => setShowInvalidPilePointAlert(false)}
        />
        <LoadingDialog show={loading} message={loadingMessage} maxWidth="md" />
        <Grid item xs={4}>
          <Typography variant="h3" className={classes.label}>
            Add Solar Rows
          </Typography>
        </Grid>

        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          <Typography variant="h6" component="span" className={classes.label}>
            Sub block name:
          </Typography>
          <FormControl className={classes.formControlTextField}>
            <TextField
              id="sub-block-name"
              placeholder="Sub block name"
              className={classes.input}
              variant="outlined"
              value={subBlockName}
              onChange={(event) => {
                setSubBlockName(event.target.value);
              }}
            />
          </FormControl>
        </Grid>

        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          <Typography variant="h6" component="span" className={classes.label}>
            Drive Line (m):
          </Typography>
          <FormControl className={classes.formControlTextField}>
            <TextField
              id="piles-spearation"
              placeholder="I-Beam Width"
              className={classes.input}
              variant="outlined"
              value={driveLine}
              onChange={(event) => {
                setDriveLine(event.target.value);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          <Typography variant="h6" component="span" className={classes.label}>
            Sub block panel width (m):
          </Typography>
          <FormControl className={classes.formControlTextField}>
            <TextField
              id="panel-width"
              placeholder="Panel width"
              className={classes.input}
              variant="outlined"
              value={subBlockPanelWidth}
              onChange={(event) => {
                setSubBlockPanelWidth(event.target.value);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          <Typography variant="h6" component="span" className={classes.label}>
            Driveway Width (m):
          </Typography>
          <FormControl className={classes.formControlTextField}>
            <TextField
              id="driveway-width"
              placeholder="Driveway width"
              className={classes.input}
              variant="outlined"
              value={driveWayWidth}
              onChange={(event) => {
                setDriveWayWidth(event.target.value);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          <Typography variant="h6" component="span" className={classes.label}>
            Deck To Pile-Points Distance (cm):
          </Typography>
          <FormControl className={classes.formControlTextField}>
            <TextField
              id="distance-from-pile-point"
              placeholder="Distance From Pile points"
              className={classes.input}
              variant="outlined"
              value={pilePointSeparation}
              onChange={(event) => {
                const val = event.target.value;
                if (val > (subBlockPanelWidth * 60) / 2 || val < 0) {
                  setShowInvalidPilePointAlert(true);
                  setPilePointSeparation(30);
                } else {
                  setPilePointSeparation(event.target.value);
                }
              }}
            />
          </FormControl>
        </Grid>
        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          <Typography variant="h6" component="span" className={classes.label}>
            Robot to Panel Distane (cm):
          </Typography>
          <FormControl className={classes.formControlTextField}>
            <TextField
              id="robot-to-panel-distance"
              placeholder="Distance Between Robot and Panel"
              className={classes.input}
              variant="outlined"
              value={robotToPanelDistance}
              onChange={(event) => {
                const val = event.target.value;

                setRobotToPanelDistance(val);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          <Typography variant="h6" component="span" className={classes.label}>
            Path Generation Method:
          </Typography>
          <FormControl className={classes.formControlTextField}>
            <Select
              labelId="generate-method"
              id="generate-method"
              className={classes.selectComponent}
              value={generationMethod}
              onChange={(e) => {
                setGenerationMethod(e.target.value);
              }}
            >
              {GENERATE_METHODS.map((method) => (
                <MenuItem value={method.value} key={method.value}>
                  {method.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {showGenerateMethodType && (
          <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
            <Typography variant="h6" component="span" className={classes.label}>
              Path Generation Method Type:
            </Typography>
            <FormControl className={classes.formControlTextField}>
              <Select
                className={classes.selectComponent}
                value={generationMethodType}
                onChange={(e) => {
                  console.log(e.target.value);
                  setGenerationMethodType(e.target.value);
                }}
              >
                {generationMethodTypeOptions.map((method) => (
                  <MenuItem value={method.value} key={method.value}>
                    {method.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          <Typography variant="h6" component="span" className={classes.label}>
            Target Offset (cm):
          </Typography>
          <FormControl className={classes.formControlTextField}>
            <TextField
              id="target-offset"
              placeholder="Target Offset"
              className={classes.input}
              variant="outlined"
              value={targetOffset}
              onChange={(event) => {
                setTargetOffset(event.target.value);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          <Typography variant="h6" component="span" className={classes.label}>
            Vegetation Thickness:
          </Typography>
          <FormControl className={classes.formControlTextField}>
            <Select
              className={classes.selectComponent}
              value={vegetationThickness}
              onChange={(event) => setVegetationThickness(event.target.value)}
            >
              {VEGETATION_CONDITION.map((condition) => (
                <MenuItem key={condition.value} value={condition.value}>
                  {condition.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          <Typography variant="h6" component="span" className={classes.label}>
            Weather Condition:
          </Typography>
          <FormControl className={classes.formControlTextField}>
            <Select
              className={classes.selectComponent}
              value={weatherCondition}
              onChange={(event) => setWeatherCondition(event.target.value)}
            >
              {WEATHER_CONDITION.map((condition) => (
                <MenuItem key={condition.value} value={condition.value}>
                  {condition.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          <Typography variant="h6" component="span" className={classes.label}>
            Inter-Row Mode
          </Typography>
          <FormControl className={classes.formControlTextField}>
            <Select className={classes.selectComponent} value={interRowMode} onChange={(event) => setInterRowMode(event.target.value)}>
              {INTER_ROW_MODES.map((interRowModeType) => (
                <MenuItem key={interRowModeType.value} value={interRowModeType.value}>
                  {interRowModeType.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          <Typography variant="h6" component="span" className={classes.label}>
            Solar sub Block Coordinates CSV:
          </Typography>
          <FormControl className={classes.formControlTextField}>
            <label htmlFor="raised-button-file">
              <input
                className={classes.input}
                style={{ display: 'none' }}
                onChange={(e) => setFileInput(e.target.files)}
                id="raised-button-file"
                type="file"
              />
              <Button variant="contained" color="secondary" component="span">
                Upload CSV
              </Button>
            </label>
          </FormControl>
          <div className={classes.fileName}>{fileInput[0]?.name}</div>
        </Grid>

        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          <Button variant="contained" disableElevation onClick={handleGenerateSolarRows} color="secondary">
            Generate Paths
          </Button>
        </Grid>
        {errorMessage && <ConnectionErrorDialog open handleClose={handleCloseErrorDialog} errorMessage={errorMessage} />}
      </Grid>
    </Fade>
  );
});
