import { types } from 'mobx-state-tree';
export const LocationState = types.model({
  gps_fix_status: types.maybeNull(types.string),
  latitude: types.maybeNull(types.number),
  longitude: types.maybeNull(types.number),
  utm_east: types.maybeNull(types.number),
  utm_north: types.maybeNull(types.number),
  heading_radian: types.maybeNull(types.number),
  linear_speed: types.maybeNull(types.number),
  angular_speed: types.maybeNull(types.number)
})  .views((self) => ({
  formatted() {
    if (self.latitude === 0 && self.longitude === 0) {
      return 'Not available';
    } else {
      return `${self.latitude}, ${self.longitude}`;
    }
  },
}));
