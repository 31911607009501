import React from 'react';
import { Grid, makeStyles, Typography, Box, Paper, Fade, Icon, Tooltip } from '@material-ui/core';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import { useStores } from '../../store/root/root.store';
import { DEFAULT_NO_DATA } from '../../utils/ui.utils';
import { SOLAR_LAWN_MOWING, ICON_TYPES } from '../../utils/constants';

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: 'white'
  }
}));

function getValueOrDefault(value, onValue, offValue) {
  if (value !== null) {
    return value ? onValue : offValue;
  }
  return DEFAULT_NO_DATA;
}

export default function SafetyDetails() {
  const classes = useStyles();
  const { autonomyRobotStore } = useStores();
  const name = autonomyRobotStore.getSelectedRobot()?.name;

  let deckOffset = DEFAULT_NO_DATA;
  let frameOffset = DEFAULT_NO_DATA;

  const estopIsOn = getValueOrDefault(autonomyRobotStore.getSelectedRobot()?.robot_state?.safety_state?.estop_is_on, 'ON', 'OFF');

  const baseboardState = getValueOrDefault(
    autonomyRobotStore.getSelectedRobot()?.robot_state?.safety_state?.baseboard_is_active,
    'ACTIVE',
    'FLAMED'
  );

  const safetyStopState = getValueOrDefault(autonomyRobotStore.getSelectedRobot()?.robot_state?.safety_state?.safety_stop, 'ON', 'OFF');

  const sensingEdgeState = getValueOrDefault(
    autonomyRobotStore.getSelectedRobot()?.robot_state?.safety_state?.sensing_edge_is_on,
    'ON',
    'OFF'
  );
  const swEstopState = getValueOrDefault(
    autonomyRobotStore.getSelectedRobot()?.robot_state?.safety_state?.sw_estop_is_on,
    'ON',
    'OFF'
  );

  const bladesState = getValueOrDefault(
    autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.solar_state.blades_running !== null &&
      autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.tool_type?.includes('mower') &&
      autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.solar_state.blades_running,
    'ON',
    'OFF'
  );

  if (
    autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.solar_state.deck_offset !== null &&
    autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.tool_type?.includes('mower')
  ) {
    deckOffset = autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.solar_state.deck_offset.toFixed(2);
  }

  if (
    autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.solar_state.frame_offset !== null &&
    autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.tool_type?.includes('mower')
  ) {
    frameOffset = autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.solar_state.frame_offset.toFixed(2);
  }

  const toolType = autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.tool_type;

  const safetyDetailsInfoList = [
    {
      label: 'E-stop',
      value: estopIsOn,
      tooltipTitle: `The emergency stop must be disengaged in order to drive ${name}.`,
      Operational: estopIsOn === 'OFF',
      iconType: ICON_TYPES.WARNING
    },
    {
      label: 'Baseboard',
      value: baseboardState,
      tooltipTitle: `${name} must be power-cycled.`,
      Operational: baseboardState === 'ACTIVE',
      iconType: ICON_TYPES.PROBLEM
    },
    {
      label: 'SW-Estop',
      value: swEstopState,
      tooltipTitle: `The sensing edges must be disengaged in order to drive ${name}.`,
      Operational: swEstopState === 'OFF',
      iconType: ICON_TYPES.WARNING
    },
    {
      label: 'Safety Stop',
      value: safetyStopState,
      tooltipTitle: `The safety stop must be disengaged in order to drive ${name}.`,
      Operational: safetyStopState === 'OFF',
      iconType: ICON_TYPES.WARNING
    },
    {
      label: 'Sensing Edges',
      value: sensingEdgeState,
      tooltipTitle: `The sensing edges must be disengaged in order to drive ${name}.`,
      Operational: sensingEdgeState === 'OFF',
      iconType: ICON_TYPES.WARNING
    },
    {
      label: 'Blades',
      value: bladesState,
      show: toolType?.includes('mower')
    },
    {
      label: 'Deck Offset (cm, +ve to left)',
      value: deckOffset,
      show: toolType?.includes('mower')
    },
    {
      label: 'Carriage Offset (cm, +ve to left)',
      value: frameOffset,
      show: toolType?.includes('mower')
    }
  ];
  return (
    <Fade in>
      <Paper elevation={10} className={classes.paper}>
        <Grid container direction="column" justifyContent="center" alignContent="center">
          <Box m={1}>
            {safetyDetailsInfoList.map(
              (item) =>
                item.show !== false && (
                  <Typography align="center" variant="subtitle1" key={item.label}>
                    {item.label}: <b>{item.value}</b>
                    {item.tooltipTitle ? (
                      <Tooltip title={item.tooltipTitle}>
                        <span
                          style={{
                            position: 'relative',
                            top: '4px',
                            right: '-5px',
                            color: (item.value && item.iconType === ICON_TYPES.WARNING && '#eed202') || (item.value ? 'red' : '#414042')
                          }}
                        >
                          {item.Operational ? null : <Icon component={WarningOutlinedIcon} />}
                        </span>
                      </Tooltip>
                    ) : null}
                  </Typography>
                )
            )}
          </Box>
        </Grid>
      </Paper>
    </Fade>
  );
}
