import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { AuditImage } from './audit-image.component';

const useStyles = makeStyles((theme) => ({
  cameraImages: {
    maxHeight: '50%',
    overflowY: 'auto',
  },
  cameraLabel: {
    textTransform: 'capitalize',
  },
}));

export const AuditImageList = ({ images = [], fullWidth = false }) => {
  const classes = useStyles();
  return (
    <Grid item container xs={12} className={classes.cameraImages} spacing={2}>
      {images.map((image) => (
        <Grid key={image.camera} item xs={fullWidth ? 12 : 6}>
          <Typography className={classes.cameraLabel} component='p'>
            {image.camera?.replace(/_/g, ' ')}
          </Typography>
          <AuditImage filename={image.filename} camera={image.camera} />
        </Grid>
      ))}
    </Grid>
  );
};
