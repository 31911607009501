import { guardedClient } from '../../utils/axios-instance';

export const fetchRobots = async (country, region, property, useCase) => {
  const response = await guardedClient.get('/robots', { params: { country, region, property, useCase } });
  return response.data;
};

export const fetchRobotAddressMetadata = async (robotId, isSolar) => {
  const response = await guardedClient.get(`/robots/${robotId}/current-operation`, {
    params: { solar: isSolar ? 1 : 0 }
  });
  return response.data;
};

export const deleteRobot = async (id) => {
  const response = await guardedClient.delete(`/robots/${id}`);
  return response.data;
};

/**
 * Updates the assignee of a robot by making a PATCH request to the server.
 * If the assignee is not 'None' or null, an email notification is sent to the assignee.
 *
 * @param {Object} robot - The robot object containing the updated assignee and other properties.
 * @returns {Promise<Object>} - A promise that resolves to the updated robot object from the server response.
 */
export const updateRobotAssignee = async (robot) => {
  try {
    const response = await guardedClient.patch(`/robots/${robot.id}`, {
      assignee: robot.assignee
    });
    if (robot.assignee !== 'None' && robot.assignee !== null) {
      await guardedClient.post('/email/send', {
        email: robot.assignee,
        robotName: robot.name
      });
    }
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

/**
 * Returns a list of tool types currently in service.
 *
 * @param {Object} robot - None
 * @returns {Promise<Object>} - A promise that resolves tool type object from the server response.
 */
export const getRobotToolTypes = async () => {
  try {
    const response = await guardedClient.get('/robots/tool-types');
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

/**
 * Service function that returns sub-block lat long data for rendering solar rows on goole map component
 * @param {Object} params API parameters. This includes regionId, selectedPropertyId, selectedBlockId, selectedSubBlockId, selectedPathType
 * @returns Array containing map data
 */
export const getSolarMapData = async (params) => {
  const response = await guardedClient.get('/robots/solar-map/', { params });
  return response.data.data;
};

/**
 * Gets all sub-block data for a given block
 */
export const getBlockSolarMapData = async (params) => {
  const response = guardedClient.get('/robots/block-solar-map', { params });
  return response;
};
