import { robotStatusCustomOrder } from './constants';

export const sortTable = (data, sortParam, sortType) => {
  const copyData = [...data];
  let sortableData = copyData;
  const isDate = sortParam.includes('date');
  const isNumber = sortParam.includes('numeric');

  if (!isDate) {
    if (isNumber) {
      const sortParamWithoutPrefix = sortParam.split(':')?.[1];
      sortType === 'asc'
        ? (sortableData = copyData.sort((a, b) => a[sortParamWithoutPrefix] - b[sortParamWithoutPrefix]))
        : (sortableData = copyData.sort((a, b) => b[sortParamWithoutPrefix] - a[sortParamWithoutPrefix]));
    } else {
      sortType === 'asc'
        ? (sortableData = copyData.sort((a, b) => robotStatusCustomOrder[a[sortParam]] - robotStatusCustomOrder[b[sortParam]]))
        : (sortableData = copyData.sort((a, b) => robotStatusCustomOrder[b[sortParam]] - robotStatusCustomOrder[a[sortParam]]));
    }
  } else {
    const sortParamWithoutPrefix = sortParam.split(':')?.[1];
    sortType === 'asc'
      ? (sortableData = copyData.sort((a, b) => new Date(a[sortParamWithoutPrefix]) - new Date(b[sortParamWithoutPrefix])))
      : (sortableData = copyData.sort((a, b) => new Date(b[sortParamWithoutPrefix]) - new Date(a[sortParamWithoutPrefix])));
  }
  return sortableData;
};
