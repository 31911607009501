import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { useStores } from '../store/root/root.store';
import RobotsTable from '../components/tables/RobotsTable';
import SolarRowsTable from '../components/tables/solar-rows.table';
import ManageLocations from '../components/ManageLocations';
import { SOLAR_RG_PREFIX } from '../utils/localstorage.controller';
import { ROUTE_SOLAR_MULTI_ROBOTS, ROUTE_SOLAR_REMOTE } from './routes';
import RobotsMapView from '../components/maps/robots-map-view';
import ConfirmActionDialog from '../components/dialogs/confirm-action.dialog';

const useStyles = makeStyles((theme) => ({
  titleLable: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 290,
    marginBottom: '15px'
  },
  map: {
    height: '440px'
  },
  markerPosition: {
    top: '20px',
    left: 0,
    position: 'relative'
  }
}));

export const SolarRemoteGuardianPage = observer(() => {
  const {
    regionsStore,
    chaperonePropertyStore,
    subrowStore,
    subBlocksStore,
    autonomyRobotStore,
    countriesStore,
    applicationStore,
    controlStore
  } = useStores();
  const classes = useStyles();
  const { push } = useHistory();
  const country = countriesStore.getSelectedCountry()?.id;
  const region = regionsStore.getSelectedRegion()?.id;
  const property = chaperonePropertyStore.getSelectedProperty()?.id;
  const subBlock = subBlocksStore.getSelectedSubBlock()?.id;
  const selectedPathType = subrowStore.currentPathType;
  const useCase = localStorage.getItem('useCase');
  const [isMultipleRobotsSelect, setIsMultipleRobotsSelect] = useState(false);
  const [showRemoveCurrentActiveClientDialog, setShowRemoveCurrentActiveClientDialog] = useState(false);
  const [isFailedToRemoveCurrentActiveClients, setIsFailedToRemoveCurrentActiveClients] = useState(false);
  const [removeCurrentActiveClientBody, setRemoveCurrentActiveClientBody] = useState('');
  const selectedRobots = autonomyRobotStore.selectedRobots?.length > 0;
  const subrowsCount = subrowStore.subrows.length;
  const [robotsWithActiveClient, setRobotsWithActiveClient] = useState([]);

  useEffect(() => {
    const bgBox = document.querySelector('#bg-box');
    bgBox.classList.remove('bg-non-solar', 'bg-snow');
    bgBox.classList.add('bg-solar');
  }, []);

  const sortRobots = () => {
    const sortByAvailability = (_robots) => [
      ..._robots.filter((r) => r.status === 'IN_USE'),
      ..._robots.filter((r) => r.status === 'WPS_SUSPENDED'),
      ..._robots.filter((r) => r.status === 'EXEC_SUSPENDED'),
      ..._robots.filter((r) => r.status === 'AVAILABLE'),
      ..._robots.filter((r) => r.status === 'OFFLINE')
    ];
    const filteredRobots = autonomyRobotStore.robots
      .filter((r) => (useCase && useCase !== 'NONE' ? r.use_case === useCase : true))
      .filter((r) => (property && property !== 'NONE' ? r.current_property_id === property : true))
      .filter((r) => (region && region !== 'NONE' ? r.current_region_id === region : true));
    const notFilteredRobots = autonomyRobotStore.robots.filter((r) => !filteredRobots.find((fR) => fR.id === r.id));
    autonomyRobotStore.setSortedRobots([...sortByAvailability(filteredRobots), ...sortByAvailability(notFilteredRobots)]);
  };

  const getRobots = async () => {
    const selectedCountry = country && country !== 'NONE' ? country : undefined;
    const selectedRegion = region && region !== 'NONE' ? region : undefined;
    const selectedProperty = property && property !== 'NONE' ? property : undefined;
    const selectedUseCase = useCase && useCase !== 'NONE' ? useCase : undefined;
    await autonomyRobotStore.getRobots(selectedCountry, selectedRegion, selectedProperty, selectedUseCase);
    sortRobots();
  };

  const handleEnterMultiRobotsView = async () => {
    let removeActiveBodyClientString = '';
    const promises = autonomyRobotStore.selectedRobots.map(async (serialnumber, i) => {
      const robot = autonomyRobotStore.getBySerialNumber(serialnumber);
      await new Promise((resolve, reject) => {
        controlStore.getCurrentActiveClient(
          robot,
          (activeClient) => {
            // onSuccess()
            if (activeClient?.uuid !== '') {
              console.debug(`user: ${activeClient?.user} is already connected to ${robot.name}`);
              // Build the message removeActiveBodyClientString about active clients
              if (removeActiveBodyClientString) {
                removeActiveBodyClientString += autonomyRobotStore.selectedRobots.length - 1 === i ? ' and ' : ', ';
              }
              removeActiveBodyClientString += `${activeClient.user} is already connected to ${robot.name}`;

              // Store the robots with a active client
              setRobotsWithActiveClient((robotsWithActiveClient) => [...robotsWithActiveClient, robot]);
              resolve();
            } else {
              console.debug(`no user connected to ${robot.name}`);
              resolve();
            }
          },
          () => {
            // onFailure()
            applicationStore.pushError(
              'Error',
              `An error was encountered while getting ${robot.name}'s current remote guardian, please report this to the autonomy team if the issue persists`
            );
            console.debug('The get_current_active_client service was unsuccessful');
            resolve();
          }
        );
      });
    });

    await Promise.all(promises);

    // Check if there are active clients and show a dialog or proceed
    if (removeActiveBodyClientString) {
      removeActiveBodyClientString += ', would you like to disconnect them?';
      setRemoveCurrentActiveClientBody(removeActiveBodyClientString);
      setShowRemoveCurrentActiveClientDialog(true);
    } else {
      push(ROUTE_SOLAR_MULTI_ROBOTS);
    }
  };

  const handleDisconnectRemoteGuardians = async () => {
    const promises = robotsWithActiveClient.map(async (robot) => {
      await new Promise((resolve, reject) => {
        controlStore.removeCurrentActiveClient(
          robot,
          () => {
            // onSuccess()
            console.debug('succeeded in disconnecting the active client from ', robot.name);
            resolve();
          },
          () => {
            // onFailure()
            console.debug('failed to disconnect the active client from ', robot.name);
            setIsFailedToRemoveCurrentActiveClients(true);
            resolve();
          }
        );
      });
    });

    await Promise.all(promises);

    if (isFailedToRemoveCurrentActiveClients) {
      applicationStore.pushError(
        'Error',
        'There is still a remote guardian connected to one of the robots, please report this to the autonomy team if the issue persists'
      );
      setIsFailedToRemoveCurrentActiveClients(false);
    } else {
      push(ROUTE_SOLAR_MULTI_ROBOTS);
    }
  };

  useEffect(() => {
    autonomyRobotStore.resetSelectedRobots(); // Reset selected robots
    getRobots();
  }, [country, region, property]);

  const getSubrows = async () => {
    if (region && property && subBlock && selectedPathType) {
      await subrowStore.getSubrows(region, property, subBlock, selectedPathType);
    }
  };
  useEffect(() => {
    getSubrows();
  }, [subBlock, selectedPathType]);

  useEffect(() => {
    if (selectedPathType) {
      setIsMultipleRobotsSelect(true);
    } else {
      setIsMultipleRobotsSelect(false);
    }
  }, [selectedPathType]);

  return (
    <Grid container justifyContent="flex-start" alignItems="flex-start">
      <ConfirmActionDialog
        handleAction={handleDisconnectRemoteGuardians}
        handleClose={() => setShowRemoveCurrentActiveClientDialog(false)}
        title="Remove Remote Guardian(s)?"
        body={removeCurrentActiveClientBody}
        open={showRemoveCurrentActiveClientDialog}
        action="Proceed"
      />
      <Grid item xs={12} sm={12} md={3}>
        <ManageLocations pagePrefix={SOLAR_RG_PREFIX} solarMode />
      </Grid>
      <Grid item xs={12} sm={12} md={9}>
        <Typography variant="h3" className={classes.titleLable}>
          Manage Robots
        </Typography>
        <RobotsTable selectable showPlay realTimeUpdate multipleSelect={isMultipleRobotsSelect} location={ROUTE_SOLAR_REMOTE} />
      </Grid>
      <Grid item xs={12} sm={12} md={5}>
        {subBlock ? (
          <Box style={{ width: '100%' }}>
            <Button
              style={{ width: '40%', marginTop: '10px' }}
              onClick={() => handleEnterMultiRobotsView()}
              disabled={!selectedRobots}
              variant="contained"
              disableElevation
              color="secondary"
            >
              Enter Multi robots view
            </Button>
            {/* Subrows table */}
            {subrowsCount && <SolarRowsTable tableSize={10} />}
          </Box>
        ) : null}
      </Grid>
      <Grid item xs={12} sm={12} md={7}>
        <Box style={{ marginTop: '50px' }}>
          <RobotsMapView />
        </Box>
      </Grid>
    </Grid>
  );
});
