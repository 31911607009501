import { Button, Grid, makeStyles, Fade } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTE_GENERATE_SOLAR_ROWS, ROUTE_REPEAT_SOLAR_ROWS } from './routes';
import { useStores } from '../store/root/root.store';
import RealtimeClient from '../realtimeClient';
import ManageLocations from '../components/ManageLocations';
import { SOLAR_CH_PREFIX } from '../utils/localstorage.controller';
import SolarRowsTable from '../components/tables/solar-rows.table';
import ConfirmActionDialog from '../components/dialogs/confirm-action.dialog';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240
  },
  form: {
    marginTop: theme.spacing(4)
  },
  toggle: {
    backgroundColor: 'white',
    Width: 40,
    height: 50,
    marginTop: theme.spacing(1)
  },
  label: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 150
  },
  titleLable: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 290,
    marginBottom: '15px'
  },
  button: {
    marginTop: theme.spacing(1),
    height: 80,
    width: 200,
    backgroundColor: '##0000FF'
  },
  select: {
    backgroundColor: theme.palette.inverted.main,
    padding: theme.spacing(1)
  },
  teachButton: {
    marginTop: theme.spacing(1),
    marginDown: theme.spacing(1),
    marginRight: theme.spacing(2),
    height: 80,
    width: 200,
    border: '1px solid #ddd',
    '&:disabled': {
      backgroundColor: 'grey'
    }
  },
  selectButton: {
    height: '100%',
    width: '100%',
    backgroundColor: '##0000FF'
  },
  tableActions: {
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  },
  helperText: {
    color: 'red'
  }
}));

export const SolarChaperonePage = observer(() => {
  const {
    regionsStore,
    chaperonePropertyStore,
    subrowStore,
    subBlocksStore,
    blocksStore,
    authenticationStore,
    autonomyRobotStore,
    controlStore,
    applicationStore
  } = useStores();
  const classes = useStyles();
  const { push } = useHistory();
  const region = regionsStore.getSelectedRegion()?.id;
  const property = chaperonePropertyStore.getSelectedProperty()?.id;
  const block = blocksStore.getSelectedBlock()?.id;
  const subBlock = subBlocksStore.getSelectedSubBlock()?.id;
  const selectedRobot = autonomyRobotStore.getSelectedRobot()?.id;
  const selectedPathType = subrowStore.currentPathType;
  const subrowsCount = subrowStore.subrows.length;
  const isManager = !!(authenticationStore.role === 'manager' || authenticationStore.role === 'admin');
  const [showRemoveCurrentActiveClientDialog, setShowRemoveCurrentActiveClientDialog] = useState(false);
  const [removeCurrentActiveClientBody, setRemoveCurrentActiveClientBody] = useState('');

  const canEnterRepeatPages = () => region && property && block && subBlock && selectedRobot && subrowsCount > 0;

  const canEnterTeachPages = () => region && property && block;

  const handleTeachSolarRow = () => {
    push(ROUTE_GENERATE_SOLAR_ROWS);
  };

  const handleRepeatSolarRows = () => {
    const robot = autonomyRobotStore.getById(selectedRobot);
    controlStore.getCurrentActiveClient(
      robot,
      (activeClient) => {
        // onSuccess()
        if (activeClient?.uuid !== '') {
          console.debug(`user: ${activeClient?.user} is already connected to ${robot.name}`);
          setRemoveCurrentActiveClientBody(
            `${activeClient.user} is already the remote guardian of ${robot.name}, would you like to disconnect them?`
          );
          setShowRemoveCurrentActiveClientDialog(true);
        } else {
          push({
            pathname: ROUTE_REPEAT_SOLAR_ROWS,
            state: {
              region,
              property,
              block,
              subBlock,
              selectedPathType,
              robot: selectedRobot
            }
          });
        }
      },
      () => {
        // onFailure()
        console.debug('failed to disconnect the active client from ', robot.name);
        applicationStore.pushError(
          'Error',
          'There is still a remote guardian connected to this robot, please report this to the autonomy team if the issue persists'
        );
      }
    );
  };

  const handleRemoveCurrentActiveClient = () => {
    const robot = autonomyRobotStore.getById(selectedRobot);
    controlStore.removeCurrentActiveClient(
      robot,
      () => {
        // has no active clients
        push({
          pathname: ROUTE_REPEAT_SOLAR_ROWS,
          state: {
            region,
            property,
            block,
            selectedPathType,
            robot: selectedRobot
          }
        });
      },
      () => {
        // has active clients
        applicationStore.pushError(
          'Error',
          'There is still a remote guardian connected to this robot, please report this to the autonomy team if the issue persists'
        );
      }
    );
  };

  const getSubrows = async () => {
    const selectedSubBlock = subBlock && subBlock !== 'NONE' ? subBlock : undefined;
    if (selectedSubBlock && selectedPathType) {
      await subrowStore.getSubrows(region, property, selectedSubBlock, selectedPathType);
    }
  };

  useEffect(() => {
    const bgBox = document.querySelector('#bg-box');
    if (localStorage.getItem('useCase') === 'NON_SOLAR_LAWN_MOWING') {
      bgBox.classList.remove('bg-solar', 'bg-snow');
      bgBox.classList.add('bg-non-solar');
    } else if (localStorage.getItem('useCase') === 'SNOW_PLOWING') {
      bgBox.classList.remove('bg-solar', 'bg-non-solar');
      bgBox.classList.add('bg-snow');
    }
  }, []);

  useEffect(() => {
    const client = RealtimeClient();
    client.connect().then(() => {
      client.onMessageReceived((robotSerialNumber, status, user, currentSubsection, _, timestamp) => {
        autonomyRobotStore.updateRobotStatus(robotSerialNumber, status, user, currentSubsection, _, timestamp);
      });
    });
    return () => {
      if (client) {
        client.closeConnection();
      }
    };
  }, []);

  useEffect(() => {
    if (subBlock && selectedPathType && selectedPathType !== '0') {
      console.log('Running get subrows');
      getSubrows();
    } else {
      subrowStore.clearSubrows();
    }
  }, [subBlock, selectedPathType]);

  return (
    <Fade in>
      <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
        <ManageLocations solarMode pagePrefix={SOLAR_CH_PREFIX} />
        {isManager && (
          <Grid>
            <Button
              className={classes.teachButton}
              onClick={handleTeachSolarRow}
              variant="contained"
              disableElevation
              color="secondary"
              disabled={!canEnterTeachPages()}
            >
              Add Solar Block
            </Button>
            <Button
              className={classes.teachButton}
              onClick={handleRepeatSolarRows}
              variant="contained"
              disableElevation
              color="secondary"
              disabled={!canEnterRepeatPages()}
            >
              Repeat Solar Rows
            </Button>
          </Grid>
        )}
        {subrowsCount && <SolarRowsTable />}
        <ConfirmActionDialog
          handleAction={handleRemoveCurrentActiveClient}
          handleClose={() => setShowRemoveCurrentActiveClientDialog(false)}
          title={`Remove ${autonomyRobotStore.getById(selectedRobot)?.serial_number}'s Remote Guardian?`}
          body={removeCurrentActiveClientBody}
          open={showRemoveCurrentActiveClientDialog}
          action="Proceed"
        />
      </Grid>
    </Fade>
  );
});
