import {
  Grid,
  makeStyles,
  Paper,
  Typography,
  Button,
  Divider,
  styled,
  createTheme,
  ThemeProvider,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import GoogleMapReact from 'google-map-react';
import { useObserver } from 'mobx-react-lite';
import React, { useRef, useState } from 'react';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { getDistance } from 'geolib';
import ReactDOMServer from 'react-dom/server';
import AuditRobotStatePopup from './audit-robot-state-popup';
import { useEnvironment } from '../../environment';
import { useStores } from '../../store/root/root.store';
import { ControlSwitch } from '../control/control-switch.component';
import 'reactjs-popup/dist/index.css';
import '../../App.css';
import { formatDate, celsiusToFahrenheit, capitalize, DEFAULT_NO_DATA, getVersionNumber } from '../../utils/ui.utils';
import { wpsStateEnum, plowStateEnum, plowHeightEnum, COORDINATE_DISTANCE_APART_THRESHOLD } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  sideBySide: {
    float: 'left',
    width: '50%',
    padding: '5px'
  },

  justifyRight: {
    float: 'right'
  },

  justifyCenter: {
    float: 'right',
    position: 'relative',
    left: '-50%'
  },

  dialogContainer: {
    width: '100%',
    height: '100%'
  },

  fullImage: {
    objectFit: 'contain',
    width: '100%',
    maxHeight: '800px',
    border: `1px solid ${theme.palette.grey[500]}`
  },

  pointerCursor: {
    cursor: 'pointer'
  },

  detailPanel: {
    padding: theme.spacing(2),
    height: '100%'
  },

  noRecords: {
    color: theme.palette.inverted.main
  },
  innerPanel: {
    height: '100%'
  },
  map: {
    // Have to define a height for the map
    height: '700px'
  },
  cameraImages: {
    maxHeight: '50%',
    overflowY: 'auto'
  },
  cameraLabel: {
    textTransform: 'capitalize'
  },
  table: {
    border: 'none'
  },
  noPrint: {
    '@media print': {
      display: 'none'
    }
  },
  controlSwitch: {
    width: '100%',
    backgroundColor: theme.palette.grey[500],
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginTop: 15,
    borderRadius: theme.spacing(1)
  },
  toggleGroup: {
    backgroundColor: theme.palette.grey[200],
    width: '100%',
    marginTop: 15
  },
  toggleButton: {
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: 17,
    color: 'black'
  },
  divider: {
    marginBottom: 28
  }
}));

const StyledToggleButton = styled(ToggleButton)({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: '#ea2840'
  }
});

const theme = createTheme({
  typography: {
    fontSize: 20
  }
});

let polylineArray = [];
const markerArray = [];
let startingMarkerArray = [];
const segmentArray = [];
let controlValues = [];
const robotColours = [];
let poly = null;
let snailDistance = 0;
let mowingDistance = 0;
let snailArea = 0;
let snailAreaAcres = 0;
let pathDistance = 0;
let pathArea = 0;
let pathAreaAcres = 0;
let drawMode = false;
let currentView = 'lines';
let robotStateStamped;
let currentRobot;
let currentTimestamp;
let mapVariable;
let mapsVariable;
let isSolar = false;
let infoWindow;

const images = [];
const cameras = [];

export const AuditReportsSnailTrails = () => {
  const classes = useStyles();
  const { auditReportsStore, autonomyRobotStore } = useStores();
  const selectedRobots = auditReportsStore.filter.robotList;
  const { googleMaps } = useEnvironment();

  const rootRef = useRef();
  const detailRef = useRef();

  const [view, setView] = useState('lines');
  const [open, setOpen] = useState(false);
  const [viewFull, setViewFull] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [control, setControl] = useState([]);

  window.openPopup = () => {
    setOpen(true);
  };

  const coordinates = auditReportsStore.records.map((el) => {
    // TODO, resolve bug with retrieving image source and uncomment the code below
    // if (el?.mediaSources.length === 2) {
    //   return {
    //     lat: el?.robot_state_stamped?.robot_state?.location_state?.latitude,
    //     lng: el?.robot_state_stamped?.robot_state?.location_state?.longitude,
    //     robot: el?.robot,
    //     timestamp: el?.timestamp,
    //     robot_state_stamped: el?.robot_state_stamped,
    //     images: [el?.mediaSources[0]?.filename, el?.mediaSources[1]?.filename],
    //     cameras: [el?.mediaSources[0]?.camera, el?.mediaSources[1]?.camera]
    //   };
    // }

    // if (el?.mediaSources.length === 1) {
    //   return {
    //     lat: el?.robot_state_stamped?.robot_state?.location_state?.latitude,
    //     lng: el?.robot_state_stamped?.robot_state?.location_state?.longitude,
    //     robot: el?.robot,
    //     timestamp: el?.timestamp,
    //     robot_state_stamped: el?.robot_state_stamped,
    //     images: [el?.mediaSources[0]?.filename],
    //     cameras: [el?.mediaSources[0]?.camera]
    //   };
    // }

    return {
      lat: el?.robot_state_stamped?.robot_state?.location_state?.latitude,
      lng: el?.robot_state_stamped?.robot_state?.location_state?.longitude,
      serial_number: el?.serial_number,
      created_at: el?.created_at,
      robot_state_stamped: el?.robot_state_stamped,
      images: [],
      cameras: []
    };
  });

  const sortRobots = (a, b) => {
    const robotA = a?.serial_number?.toLowerCase();
    const robotB = b?.serial_number?.toLowerCase();

    if (robotA < robotB) {
      return -1;
    }

    if (robotA > robotB) {
      return 1;
    }

    return 0;
  };

  const coords = coordinates
    .filter(
      (el) =>
        el.lat !== '0' &&
        el.lng !== '0' &&
        el.lat !== undefined &&
        el.lng !== undefined &&
        el.robot_state_stamped?.robot_state?.location_state?.gps_fix_status === 'Fixed RTK' &&
        selectedRobots.includes(el.serial_number)
    )
    .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
  const pathCoordinates = coords.sort((a, b) => sortRobots(a, b));

  const toggleMeasurements = () => {
    drawMode = !drawMode;
    poly?.setEditable(drawMode);
  };

  function handleImageClick() {
    setViewFull(true);
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else if (document.mozFullScreenElement) {
      document.mozCancelFullScreen();
    } else if (document.webkitFullscreenElement) {
      document.webkitExitFullscreen();
    } else if (document.msFullscreenElement) {
      document.msExitFullscreen();
    }
  }

  const resetStartingMarkers = () => {
    startingMarkerArray.forEach((marker) => {
      marker.setVisible(false);
    });
    startingMarkerArray = [];
  };

  const createPolylines = () => {
    pathCoordinates.forEach((coord, index) => {
      const latLng = new mapsVariable.LatLng(coord.lat, coord.lng);

      if (index === 0) {
        polylineArray.push(
          new mapsVariable.Polyline({
            strokeOpacity: 1,
            strokeColor: robotColours[selectedRobots.indexOf(coord.serial_number)],
            map: mapVariable
          })
        );
      } else {
        const prevCoord = pathCoordinates[index - 1];
        const distance = getDistance(
          { latitude: coord.lat, longitude: coord.lng },
          { latitude: prevCoord.lat, longitude: prevCoord.lng },
          0.01
        );

        if (distance > COORDINATE_DISTANCE_APART_THRESHOLD || coord.serial_number !== prevCoord.serial_number) {
          polylineArray.push(
            new mapsVariable.Polyline({
              strokeOpacity: 1,
              strokeColor: robotColours[selectedRobots.indexOf(coord.serial_number)],
              map: mapVariable
            })
          );
        }
      }

      polylineArray[polylineArray.length - 1].getPath().push(latLng);
    });
  };

  const updateMarkersWhenZoomedOut = () => {
    markerArray.forEach((marker) => {
      marker.setVisible(false);
    });
    let index = 0;

    if (currentView === 'markers' || currentView === 'both') {
      startingMarkerArray = [];

      polylineArray.forEach((polyline) => {
        const { length } = polyline.getPath();
        const start = index;

        if (controlValues.length === 0) {
          markerArray[index].setVisible(true);
          index += length;
        } else {
          for (let polylineIndex = 0; polylineIndex < length; polylineIndex += 1) {
            const current = index + polylineIndex;

            // When zoomed out, make the first marker of each polyline visible
            if (markerArray[current].getMap() !== null) {
              markerArray[current].setVisible(true);
              startingMarkerArray.push(markerArray[current]);
              break;
            }
          }

          index = length + start;
        }
      });
    }
  };

  const renderWithinBounds = () => {
    const zoom = mapVariable.getZoom();

    if (zoom > 15) {
      const bounds = mapVariable.getBounds();

      if (currentView === 'markers' || currentView === 'both') {
        markerArray.forEach((marker) => {
          if (bounds.contains(marker.getPosition())) {
            marker.setVisible(true);
          } else {
            marker.setVisible(false);
          }
        });
      } else {
        polylineArray.forEach((polyline) => {
          polyline.setVisible(true);
        });

        segmentArray.forEach((segment) => {
          segment.setVisible(true);
        });

        markerArray.forEach((marker) => {
          marker.setVisible(false);
        });
      }
    } else {
      updateMarkersWhenZoomedOut();
    }
  };
  /** Same formula used in SQL function to calculate distance between two gps points and update locations table
   * Used in OPS KPI page
   */
  function haversineDistance(lat1, lon1, lat2, lon2) {
    function toRad(x) {
      return (x * Math.PI) / 180;
    }

    const R = 6371e3; // Earth radius in meters
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  }

  const calculateDistance = () => {
    snailDistance = 0;
    mowingDistance = 0;

    polylineArray.forEach((polyline) => {
      let pointIndex = 0;

      polyline
        .getPath()
        .getArray()
        .forEach(() => {
          if (pointIndex > 0) {
            const current = pathCoordinates[pointIndex];
            const previous = pathCoordinates[pointIndex - 1];
            const timeDifference = (new Date(current.created_at) - new Date(previous.created_at)) / 1000;
            const bladesRunning = previous?.robot_state_stamped?.robot_state?.control_state?.solar_state?.blades_running;

            if (timeDifference <= 60) {
              const distance = haversineDistance(previous.lat, previous.lng, current.lat, current.lng);
              snailDistance += distance;

              if (bladesRunning) {
                mowingDistance += distance;
              }
            }
          }
          pointIndex += 1;
        });
    });
    snailArea = mowingDistance * 1.7907;
    snailAreaAcres = snailArea / 4047.0;
    pathDistance = mapsVariable.geometry?.spherical.computeLength(poly.getPath());

    const length = poly.getPath().getLength();

    if (poly.getPath().getAt(0) === poly.getPath().getAt(length - 1)) {
      pathArea = mapsVariable.geometry?.spherical.computeArea(poly.getPath());
    } else {
      pathArea = 0;
    }
    pathAreaAcres = pathArea / 4047.0;

    document.getElementById('snailDistance').innerHTML = snailDistance?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

    if (isSolar) {
      document.getElementById('mowedDistance').innerHTML = mowingDistance?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      document.getElementById('snailArea').innerHTML = snailArea?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      document.getElementById('snailAreaAcres').innerHTML = snailAreaAcres?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    }

    document.getElementById('pathDistance').innerHTML = pathDistance?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    document.getElementById('pathArea').innerHTML = pathArea?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    document.getElementById('pathAreaAcres').innerHTML = pathAreaAcres?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  const addLatLngToPoly = (latLng) => {
    const path = poly.getPath();

    path.push(latLng);
  };

  const motorInfo = (index) =>
    `<tr><th><b><u>Motor ${index + 1} (` +
    `${robotStateStamped?.robot_state?.control_state?.motors[index]?.id || DEFAULT_NO_DATA}` +
    ')</u></b></th></tr>' +
    '<tr><td>RPM</td><td><b>' +
    `${
      robotStateStamped?.robot_state?.control_state?.motors[index]?.rpm !== undefined
        ? robotStateStamped?.robot_state?.control_state?.motors[index]?.rpm
        : DEFAULT_NO_DATA
    }` +
    '</b></td></tr><tr><td>Current</td><td><b>' +
    `${
      robotStateStamped?.robot_state?.control_state?.motors[index]?.current !== undefined
        ? robotStateStamped?.robot_state?.control_state?.motors[index]?.current
        : `${DEFAULT_NO_DATA} `
    }` +
    'A' +
    '</b></td></tr><tr><td>Temperature</td><td><b>' +
    `${robotStateStamped?.robot_state?.control_state?.motors[index]?.motor_temp || DEFAULT_NO_DATA}` +
    '°C</b> | <b>' +
    `${celsiusToFahrenheit(robotStateStamped?.robot_state?.control_state?.motors[index]?.motor_temp) || DEFAULT_NO_DATA}` +
    '°F</b></td></tr>';

  const attachmentInfo = () => {
    if (robotStateStamped?.robot_state?.control_state?.tool_type?.includes('plow')) {
      return (
        '<tr><td>Plow Height</td><td><b>' +
        `${plowHeightEnum[robotStateStamped?.robot_state?.control_state?.snow_state?.plow_height] || DEFAULT_NO_DATA}` +
        '</b></td></tr><tr><td>Plow State</td><td><b>' +
        `${plowStateEnum[robotStateStamped?.robot_state?.control_state?.snow_state?.plow_height] || DEFAULT_NO_DATA}` +
        '</b></td></tr><tr><td>Plow Left Extension</td><td><b>' +
        `${robotStateStamped?.robot_state?.control_state?.snow_state?.plow_left_extension || DEFAULT_NO_DATA}` +
        '</b></td></tr><tr><td>Plow Right Extension</td><td><b>' +
        `${robotStateStamped?.robot_state?.control_state?.snow_state?.plow_right_extension || DEFAULT_NO_DATA}` +
        '</b></td></tr><tr><td>Salter Speed</td><td><b>' +
        `${
          robotStateStamped?.robot_state?.control_state?.snow_state?.salter_percentage !== undefined
            ? robotStateStamped?.robot_state?.control_state?.snow_state?.salter_percentage
            : `${DEFAULT_NO_DATA} `
        }` +
        '%</b></td></tr>'
      );
    }
    if (robotStateStamped?.robot_state?.control_state?.tool_type?.includes('mower')) {
      return (
        '<tr><td>Deck Offset</td><td><b>' +
        `${
          robotStateStamped?.robot_state?.control_state?.solar_state?.deck_offset !== undefined
            ? robotStateStamped?.robot_state?.control_state?.solar_state?.deck_offset
            : `${DEFAULT_NO_DATA} `
        }` +
        'cm' +
        '</b></td></tr><tr><td>Carriage Offset</td><td><b>' +
        `${
          robotStateStamped?.robot_state?.control_state?.solar_state?.frame_offset !== undefined
            ? robotStateStamped?.robot_state?.control_state?.solar_state?.frame_offset
            : `${DEFAULT_NO_DATA} `
        }` +
        'cm' +
        '</b></td></tr><tr><td>Blades Running</td><td><b>' +
        `${
          robotStateStamped?.robot_state?.control_state?.solar_state?.blades_running?.toString() !== undefined
            ? robotStateStamped?.robot_state?.control_state?.solar_state?.blades_running?.toString()
            : DEFAULT_NO_DATA
        }` +
        '</b></td></tr>'
      );
    }
    return '';
  };

  const renderPolylines = (map, maps) => {
    mapVariable = map;
    mapsVariable = maps;
    polylineArray = [];

    infoWindow = new maps.InfoWindow();

    selectedRobots.sort((robotA, robotB) => sortRobots(robotA, robotB));

    createPolylines();

    poly = new maps.Polyline({
      strokeColor: '#EA2840',
      strokeOpacity: 1,
      strokeWeight: 3,
      editable: true,
      map
    });

    maps.event.addListener(map, 'click', (event) => {
      infoWindow.close();
      if (drawMode) {
        addLatLngToPoly(event.latLng);
        calculateDistance();
      }
    });

    maps.event.addListener(poly, 'click', (event) => {
      if (drawMode) {
        if (event.vertex === undefined) {
          return;
        }

        if (event.latLng === poly.getPath().getAt(0)) {
          addLatLngToPoly(event.latLng);
          calculateDistance();
        }
      }
    });

    maps.event.addListener(poly, 'contextmenu', (e) => {
      if (drawMode) {
        if (e.vertex === undefined) {
          return;
        }

        poly.getPath().removeAt(e.vertex);
        calculateDistance();
      }
    });

    maps.event.addListener(poly.getPath(), 'set_at', () => {
      calculateDistance();
    });

    maps.event.addListener(poly.getPath(), 'insert_at', () => {
      calculateDistance();
    });

    calculateDistance();

    const bounds = new maps.LatLngBounds();
    pathCoordinates.forEach((p) => {
      const version = getVersionNumber(p.serial_number);
      const showLiquidSensors = version !== 'sim' && parseInt(version) >= 75;

      bounds.extend(new maps.LatLng(p.lat, p.lng));
      const marker = new maps.Marker({
        position: p,
        map,
        icon: {
          path: maps.SymbolPath.CIRCLE,
          fillColor: selectedRobots.length === 1 ? '#EA2840' : robotColours[selectedRobots.indexOf(p.serial_number)],
          fillOpacity: 1,
          scale: 4,
          strokeColor: 'black',
          strokeWeight: 1,
          strokeOpacity: 1
        }
      });

      markerArray.push(marker);

      function stateButton() {
        return (
          `<br><button type='button' class=${classes.justifyRight} style='border: none; background-color: #ea2840; border-radius: 10px; height: 40px; width: 200px; color: white; margin-top: 5px; cursor: pointer;' onclick='openPopup()'>` +
          '<b>Show Full Robot State</b>' +
          '</button>'
        );
      }

      function liquidSensor(type, value) {
        if (showLiquidSensors && value !== undefined && value !== null) {
          if ((type === 'base' && value) || (type === 'tank' && !value)) {
            return 'NOT LEAKING';
          }
          return 'LEAKING';
        }
        return DEFAULT_NO_DATA;
      }

      maps.event.addListener(
        marker,
        'click',
        (() => async () => {
          robotStateStamped = p.robot_state_stamped;
          currentRobot = p.serial_number;
          currentTimestamp = p.created_at;

          let content =
            `${
              '<div class="two-grid-container"><div>' +
              '<table><tr><th><b><u>Robot State</u></b></th></tr><tr>' +
              '<td>Robot</td><td><b>' +
              `${currentRobot || DEFAULT_NO_DATA}` +
              '</b></td></tr><tr><td>Timestamp</td><td><b>'
            }${formatDate(currentTimestamp)}</b></td></tr><tr><td>Waypoints State</td><td><b>` +
            `${wpsStateEnum[robotStateStamped?.robot_state?.navigation_state?.wps_state] || DEFAULT_NO_DATA}` +
            '</b></td></tr><tr><td>GPS Fix Status</td><td><b>' +
            `${robotStateStamped?.robot_state?.location_state?.gps_fix_status || DEFAULT_NO_DATA}` +
            '</b></td></tr><tr><td>Linear Speed</td><td><b>' +
            `${
              robotStateStamped?.robot_state?.location_state?.linear_speed.toFixed(2) !== undefined
                ? robotStateStamped?.robot_state?.location_state?.linear_speed.toFixed(2)
                : `${DEFAULT_NO_DATA} `
            }` +
            'm/s</b></td></tr><tr><td>Angular Speed</td><td><b>' +
            `${
              robotStateStamped?.robot_state?.location_state?.angular_speed.toFixed(2) !== undefined
                ? robotStateStamped?.robot_state?.location_state?.angular_speed.toFixed(2)
                : `${DEFAULT_NO_DATA} `
            }` +
            'rad/s</b></td></tr><tr><td>Control Mode</td><td><b>' +
            `${robotStateStamped?.robot_state?.control_state?.control_mode || DEFAULT_NO_DATA}` +
            '</b></td></tr><tr><td>Battery Percentage</td><td><b>' +
            `${
              robotStateStamped?.robot_state?.control_state?.battery_state?.percentage !== undefined
                ? robotStateStamped?.robot_state?.control_state?.battery_state?.percentage
                : `${DEFAULT_NO_DATA} `
            }` +
            '%' +
            '</b></td></tr><tr><td>Oil Temperature</td><td><b>' +
            `${
              robotStateStamped?.robot_state?.control_state?.oil_temperature !== undefined
                ? `${robotStateStamped?.robot_state?.control_state?.oil_temperature}°C | ${celsiusToFahrenheit(
                    robotStateStamped?.robot_state?.control_state?.oil_temperature
                  )}°F`
                : `${DEFAULT_NO_DATA} °C | ${DEFAULT_NO_DATA} °F`
            }` +
            `</b></td></tr><tr><td>Base Liquid Sensor</td><td><b>${liquidSensor(
              'base',
              robotStateStamped?.robot_state?.control_state?.base_liquid_sensor
            )}</b></td></tr><tr><td>Tank Liquid Sensor</td><td><b>${liquidSensor(
              'tank',
              robotStateStamped?.robot_state?.control_state?.tank_liquid_sensor
            )}</b></td></tr><tr><td>Fan</td><td><b>${
              robotStateStamped?.robot_state?.control_state?.fan_is_on ? 'On' : 'Off'
            }</b></td></tr><tr><td>E-Stop</td><td><b>${robotStateStamped?.robot_state?.safety_state?.estop_is_on ? 'On' : 'Off'}</b>` +
            '</td></tr><td>' +
            `</td>${attachmentInfo()}</table></div><div><table>${motorInfo(0)}${motorInfo(1)}${motorInfo(2)}</table>${stateButton()}` +
            '</div></div>';

          infoWindow.setContent(
            `${content}<div class=${classes.justifyCenter}>${ReactDOMServer.renderToString(<CircularProgress color="secondary" />)}</div>`
          );
          infoWindow.open(map, marker);
          // TODO resolve bug with no camera data and uncomment this
          // cameras[0] = capitalize(p.cameras[0]?.replace(/_/g, ' '));
          // cameras[1] = capitalize(p.cameras[1]?.replace(/_/g, ' '));

          // await auditReportsStore
          //   .getReportMedia(p.images[0])
          //   .then((value) => {
          //     images[0] = value.data;
          //     content +=
          //       `<div class=${classes.sideBySide}>` +
          //       `<b><u>${cameras[0]}</u></b>` +
          //       `<img id="imageIDOne" class=${classes.pointerCursor} width="100%" maxHeight="200px" objectFit="contain" src="data:image/jpeg;base64,${value.data}" alt=""></img>` +
          //       '</div>';
          //   })
          //   .catch(() => {});
          // await auditReportsStore
          //   .getReportMedia(p.images[1])
          //   .then((value) => {
          //     images[1] = value.data;
          //     content +=
          //       `<div class=${classes.sideBySide}>` +
          //       `<b><u>${cameras[1]}</u></b>` +
          //       `<img id="imageIDTwo" class=${classes.pointerCursor} cursor="pointer" width="100%" maxHeight="200px" objectFit="contain" src="data:image/jpeg;base64,${value.data}" alt=""></img>` +
          //       '</div>';
          //   })
          //   .catch(() => {});

          // infoWindow.setContent(content);
          // document?.getElementById('imageIDOne')?.addEventListener(
          //   'click',
          //   () => {
          //     setCurrentImage(0);
          //     handleImageClick();
          //   },
          //   false
          // );
          // document?.getElementById('imageIDTwo')?.addEventListener(
          //   'click',
          //   () => {
          //     setCurrentImage(1);
          //     handleImageClick();
          //   },
          //   false
          // );
        })(marker)
      );
    });

    map.fitBounds(bounds);
  };

  function clearClicked() {
    poly?.getPath().clear();
    pathDistance = 0;
    pathArea = 0;
    pathAreaAcres = 0;
    document.getElementById('snailDistance').innerHTML = snailDistance.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    document.getElementById('snailAreaAcres').innerHTML = snailAreaAcres.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    document.getElementById('pathDistance').innerHTML = pathDistance.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    document.getElementById('pathArea').innerHTML = pathArea.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    document.getElementById('pathAreaAcres').innerHTML = pathAreaAcres?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }

  const handleChange = (event, nextView) => {
    if (nextView !== null) {
      setView(nextView);
      currentView = nextView;

      polylineArray.forEach((polyline) => {
        polyline.setVisible(nextView === 'lines' || nextView === 'both');
      });

      segmentArray.forEach((segment) => {
        segment.setVisible(nextView === 'lines' || nextView === 'both');
      });

      markerArray.forEach((marker) => {
        marker.setVisible(nextView === 'markers' || nextView === 'both');
      });

      renderWithinBounds();
    }
  };

  const handleControl = (event, newControl) => {
    setControl(newControl);
    controlValues = newControl;

    segmentArray.forEach((polyline) => {
      polyline.setMap(null);
    });

    markerArray.forEach((marker) => {
      marker.setMap(null);
    });

    resetStartingMarkers();

    let pointIndex = 0;
    let index = 0;

    polylineArray.forEach((polyline) => {
      let firstMarkerSet = false;

      if (newControl.length === 0) {
        polyline.setOptions({ strokeOpacity: 1 });
        markerArray.forEach((marker) => {
          marker.setMap(mapVariable);
        });

        resetStartingMarkers();

        // When zoomed out, make the first marker of each polyline visible
        const { length } = polyline.getPath();
        markerArray[index].setVisible(true);
        startingMarkerArray.push(markerArray[index]);

        index += length;
      } else {
        polyline.setOptions({ strokeOpacity: 0 });

        let polylineIndex = 0;

        polyline
          .getPath()
          .getArray()
          .forEach(() => {
            if (pointIndex < pathCoordinates.length - 1 && polylineIndex < polyline.getPath().getLength() - 1) {
              const controlState = pathCoordinates[pointIndex]?.robot_state_stamped?.robot_state?.control_state;
              const safetyState = pathCoordinates[pointIndex]?.robot_state_stamped?.robot_state?.safety_state;
              const controlMode = controlState?.control_mode;
              const leftMotorRPM = controlState?.motors[0]?.rpm;
              const rightMotorRPM = controlState?.motors[1]?.rpm;
              const estop = safetyState?.estop_is_on;
              const idle = leftMotorRPM === 0 && rightMotorRPM === 0 && !estop;
              const transparencyRequired = !(newControl.length === 1 || (newControl.length === 2 && newControl.includes('idle')));

              let transparency = 0;

              if (newControl.includes('manual') && controlMode === 'manual' && !idle) {
                if (transparencyRequired) {
                  transparency = 0.33;
                } else {
                  transparency = 1;
                }
              } else if (newControl.includes('teleops') && controlMode === 'teleops' && !idle) {
                if (transparencyRequired) {
                  transparency = 0.67;
                } else {
                  transparency = 1;
                }
              } else if (newControl.includes('autonomous') && controlMode === 'autonomous' && !idle) {
                transparency = 1;
              }

              const coord = pathCoordinates[pointIndex];

              segmentArray.push(
                new mapsVariable.Polyline({
                  strokeOpacity: transparency,
                  strokeColor: robotColours[selectedRobots.indexOf(coord.serial_number)],
                  map: mapVariable
                })
              );

              const nextCoord = pathCoordinates[pointIndex + 1];
              const latLng = new mapsVariable.LatLng(coord.lat, coord.lng);
              const nextLatLng = new mapsVariable.LatLng(nextCoord.lat, nextCoord.lng);

              if (transparency === 0 && !(newControl.includes('idle') && idle)) {
                markerArray[pointIndex].setMap(null);
              } else {
                markerArray[pointIndex].setMap(mapVariable);

                // When zoomed out, make the first marker of each polyline visible
                if ((view === 'markers' || view === 'both') && !firstMarkerSet) {
                  markerArray[pointIndex].setVisible(true);
                  startingMarkerArray.push(markerArray[pointIndex]);
                  firstMarkerSet = true;
                }
              }
              segmentArray[segmentArray.length - 1].getPath().push(latLng);
              segmentArray[segmentArray.length - 1].getPath().push(nextLatLng);
              segmentArray[segmentArray.length - 1].setVisible(polyline.getVisible());
            }

            pointIndex += 1;
            polylineIndex += 1;
          });
      }
    });

    renderWithinBounds();
  };

  return useObserver(() => {
    const record = auditReportsStore.selectedRecord;
    const center = pathCoordinates ? pathCoordinates[0] : googleMaps.defaultCenter;

    if (robotColours.length === 0) {
      if (selectedRobots.length === 1) {
        robotColours.push('#000000');
      } else {
        selectedRobots.forEach(() => {
          const randomColour = Math.floor(Math.random() * 16777215).toString(16);
          const colourHex = `#${randomColour}`;
          robotColours.push(colourHex);
        });
      }
    }

    selectedRobots.forEach((robot) => {
      const useCase = autonomyRobotStore.getBySerialNumber(robot)?.use_case;

      if (useCase === 'SOLAR_LAWN_MOWING') {
        isSolar = true;
      }
    });

    if (record && rootRef.current) {
      // Timeout just forces the scroll to the iteration after the render, so the scroll places properly
      setTimeout(() => rootRef.current.scrollIntoView({ behavior: 'smooth' }));
    }

    return (
      <div ref={rootRef}>
        {pathCoordinates.length === 0 ? (
          <Typography variant="h6" component="p" className={classes.noRecords}>
            No GPS Locations to display
          </Typography>
        ) : (
          <Paper color="inverted" ref={detailRef} className={classes.detailPanel} elevation={0}>
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              className={classes.innerPanel}
            >
              <Grid item xs={12} lg={12} className={`${classes.map} ${classes.noPrint}`}>
                <div id="container">
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, libraries: 'geometry' }}
                    center={center}
                    defaultZoom={18.56}
                    options={{
                      streetViewControl: true,
                      overviewMapControl: true,
                      disableDefaultUI: false,
                      zoomControl: true,
                      mapTypeId: 'hybrid',
                      draggable: true
                    }}
                    onGoogleApiLoaded={({ map, maps }) => renderPolylines(map, maps)}
                    onDragEnd={() => renderWithinBounds()}
                    onZoomAnimationEnd={() => renderWithinBounds()}
                    yesIWantToUseGoogleMapApiInternals
                  />
                  <div id="sidebar">
                    <ThemeProvider theme={theme}>
                      <Typography style={{ fontWeight: 'bold' }}>Total Driven Distance (m)</Typography>
                      <Divider />
                      <Typography id="snailDistance" style={{ fontSize: 25 }}>
                        0.00
                      </Typography>
                      {isSolar && (
                        <div>
                          <Divider />
                          <Typography style={{ fontWeight: 'bold' }}>Total Mowed Distance (m)</Typography>
                          <Divider />
                          <Typography id="mowedDistance" style={{ fontSize: 25 }}>
                            0.00
                          </Typography>
                          <Divider />
                          <Typography style={{ fontWeight: 'bold' }}>Total Mowed Area (m²)</Typography>
                          <Divider />
                          <Typography id="snailArea" style={{ fontSize: 25 }}>
                            0.00
                          </Typography>
                          <Divider />
                          <Typography style={{ fontWeight: 'bold' }}>Total Mowed Area (Acres)</Typography>
                          <Divider />
                          <Typography id="snailAreaAcres" style={{ fontSize: 25 }}>
                            0.00
                          </Typography>
                        </div>
                      )}

                      <Divider className={classes.divider} />

                      <Typography style={{ fontWeight: 'bold' }}>Drawn Path Distance (m)</Typography>
                      <Divider />
                      <Typography id="pathDistance" style={{ fontSize: 25 }}>
                        0.00
                      </Typography>
                      <Divider />
                      <Typography style={{ fontWeight: 'bold' }}>Drawn Path Area (m²)</Typography>
                      <Divider />
                      <Typography id="pathArea" style={{ fontSize: 25 }}>
                        0.00
                      </Typography>
                      <Divider />
                      <Typography style={{ fontWeight: 'bold' }}>Drawn Path Area (Acres)</Typography>
                      <Divider />
                      <Typography id="pathAreaAcres" style={{ fontSize: 25 }}>
                        0.00
                      </Typography>
                      <Divider />
                    </ThemeProvider>
                    <Grid item className={classes.controlSwitch}>
                      <ControlSwitch label="Draw Mode" skipControlStore defaultValue={false} onClick={toggleMeasurements} />
                    </Grid>
                    <Button
                      variant="contained"
                      size="large"
                      type="submit"
                      onClick={clearClicked}
                      color="secondary"
                      fullWidth
                      style={{ marginTop: 15 }}
                    >
                      Clear
                    </Button>
                    {viewFull ? (
                      <Dialog open={viewFull} fullWidth maxWidth="xl">
                        <DialogTitle>{`Camera: ${cameras[currentImage]}`}</DialogTitle>
                        <DialogContent>
                          <div className={classes.dialogContainer}>
                            <img className={classes.fullImage} src={`data:image/jpeg;base64,${images[currentImage]}`} alt="" />
                          </div>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={() => {
                              setViewFull(false);
                              setCurrentImage(null);
                            }}
                            color="primary"
                          >
                            Close
                          </Button>
                        </DialogActions>
                      </Dialog>
                    ) : null}
                    <AuditRobotStatePopup
                      audit_record={[currentRobot, currentTimestamp, robotStateStamped]}
                      isOpen={open}
                      onClose={() => setOpen(false)}
                    />
                    {selectedRobots.length > 1 && (
                      <div>
                        {selectedRobots.map((robot, index) => (
                          <div key={robot} style={{ width: '100%', marginTop: '10px' }}>
                            <Grid container direction="row" justifyContent="center" alignItems="center">
                              <Typography style={{ fontWeight: 'bold', fontSize: 18, width: '35%' }}>{robot}</Typography>
                              <div style={{ backgroundColor: `${robotColours[index]}`, width: '50%', minHeight: '20px' }}> </div>
                            </Grid>
                          </div>
                        ))}
                      </div>
                    )}
                    <div>
                      <div style={{ width: '50%', float: 'left', marginTop: 70 }}>
                        <ThemeProvider theme={theme}>
                          <Typography style={{ textAlign: 'center', fontWeight: 'bold', verticalAlign: 'middle' }}>
                            Visualization Mode
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div style={{ width: '50%', float: 'right' }}>
                        <ToggleButtonGroup
                          className={classes.toggleGroup}
                          value={view}
                          exclusive
                          orientation="vertical"
                          onChange={handleChange}
                        >
                          <StyledToggleButton value="lines" aria-label="bold" className={classes.toggleButton}>
                            LINES
                          </StyledToggleButton>
                          <StyledToggleButton value="markers" aria-label="italic" className={classes.toggleButton}>
                            MARKERS
                          </StyledToggleButton>
                          <StyledToggleButton value="both" aria-label="italic" className={classes.toggleButton}>
                            LINES & MARKERS
                          </StyledToggleButton>
                        </ToggleButtonGroup>
                      </div>
                    </div>
                    <div>
                      <div style={{ width: '50%', float: 'left', marginTop: 125 }}>
                        <ThemeProvider theme={theme}>
                          <Typography style={{ textAlign: 'center', fontWeight: 'bold', verticalAlign: 'middle' }}>Control Mode</Typography>
                        </ThemeProvider>
                      </div>
                      <div style={{ width: '50%', float: 'right' }}>
                        <ToggleButtonGroup className={classes.toggleGroup} value={control} orientation="vertical" onChange={handleControl}>
                          <StyledToggleButton value="idle" aria-label="bold" className={classes.toggleButton}>
                            IDLE
                          </StyledToggleButton>
                          <StyledToggleButton value="manual" aria-label="italic" className={classes.toggleButton}>
                            MANUAL
                          </StyledToggleButton>
                          <StyledToggleButton value="teleops" aria-label="italic" className={classes.toggleButton}>
                            TELEOPS
                          </StyledToggleButton>
                          <StyledToggleButton value="autonomous" aria-label="italic" className={classes.toggleButton}>
                            AUTONOMOUS
                          </StyledToggleButton>
                        </ToggleButtonGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Paper>
        )}
      </div>
    );
  });
};
