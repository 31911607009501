import { Grid, makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import { useStores } from '../../store/root/root.store';
import { useEnvironment } from '../../environment';
import robotIcon from '../../assets/images/robot_icon_35x35.png';
import { guardedClient } from '../../utils/axios-instance';
import { DEFAULT_PATH_TYPE } from '../../utils/constants';
import { extractSubRowMeta } from '../../utils/ui.utils';
import { getSubRowStatus } from '../../services/api/subrows.service';

const useStyles = makeStyles(() => ({
  map: {
    height: '440px',
    width: '50%'
  },
  markerPosition: {
    top: '23px',
    left: 0,
    position: 'relative'
  }
}));

export const RobotsMapView = observer(() => {
  const { regionsStore, blocksStore, chaperonePropertyStore, autonomyRobotStore, subBlocksStore } = useStores();
  const classes = useStyles();
  const { googleMaps } = useEnvironment();
  const region = regionsStore.getSelectedRegion()?.id;
  const property = chaperonePropertyStore.getSelectedProperty()?.id;
  const block = blocksStore.getSelectedBlock()?.id;
  const subBlock = subBlocksStore.getSelectedSubBlock()?.id;
  const center = googleMaps.defaultCenter;
  const robotLocations = useRef([]);
  const polylineArray = useRef({});
  const markerArray = useRef([]);
  const mapVariable = useRef(undefined);
  const mapsVariable = useRef(undefined);
  const icon = useRef(null);
  const firstMarkerCentered = useRef(false);
  const previousSelectedRobot = useRef('');
  const currentSelectedRobot = useRef('');
  const isMountedRef = useRef(null);
  const coordinatesCache = useRef();

  /** Clears polyline array
   *  Required since data persists across re-renders
   */
  const clearPolylines = (blockId) => {
    if (blockId && polylineArray.current[blockId]) {
      // Clear only the polylines for the specified block
      polylineArray.current[blockId].forEach((polyline) => polyline.setMap(null));
      polylineArray.current[blockId] = [];
    } else if (!blockId) {
      // Clear all polylines for all blocks if no blockId is specified
      Object.keys(polylineArray.current).forEach((key) => {
        polylineArray.current[key].forEach((polyline) => polyline.setMap(null));
        polylineArray.current[key] = [];
      });
    }
  };

  /** Handles updating polyline style (highlights sub-block if user selects it) */
  const updatePolylineStyles = () => {
    if (polylineArray.current) {
      Object.values(polylineArray.current)
        .flat()
        .forEach((polyline) => {
          const isSubBlockSelected = polyline.subblockId.toString() === subBlock?.toString();
          let color;
          if (isSubBlockSelected) {
            if (polyline.isSubRowCut) {
              color = '#ffb66a';
            } else {
              color = '#ADFF2F';
            }
          } else {
            if (polyline.isSubRowCut) {
              color = '#f8a753';
            } else {
              color = '#55ff0c';
            }
          }
          polyline.setOptions({
            strokeColor: color,
            strokeOpacity: isSubBlockSelected ? 0.9 : 0.5,
            strokeWeight: isSubBlockSelected ? 4 : 3
          });
          polyline.setMap(mapVariable.current);
        });
    }
  };

  useEffect(() => {
    const renderAllSubBlocks = async () => {
      if (!block) {
        clearPolylines(block);
        return;
      }
      try {
        if (!coordinatesCache.current || coordinatesCache.current.block !== block) {
          isMountedRef.current = true;
          await subBlocksStore.getSubBlocks(block);
          const blockSubBlocksArray = subBlocksStore.getSubBlockIds() || [];
          if (region && property && blockSubBlocksArray.length > 0) {
            const res = await guardedClient.get('/robots/block-solar-map', {
              params: {
                regionId: region,
                propertyId: property,
                blockId: block,
                subBlocksArray: blockSubBlocksArray,
                pathType: DEFAULT_PATH_TYPE
              }
            });
            coordinatesCache.current = { data: res.data.data, block };
          }
        }
        if (coordinatesCache.current?.data) {
          const newPolylines = await Promise.all(
            coordinatesCache.current.data.map(async (coord) =>
              Promise.all(
                Object.keys(coord).map(async (subrowName) => {
                  const { orderIndex, direction, subblockId } = extractSubRowMeta(subrowName);
                  const response = await getSubRowStatus(orderIndex, direction, subblockId);
                  const subrow = coord[subrowName];
                  return new mapsVariable.current.Polyline({
                    path: subrow.map((point) => ({ lat: Number(point.lat), lng: Number(point.long) })),
                    map: mapVariable.current,
                    subblockId,
                    isSubRowCut: response.status
                  });
                })
              )
            )
          );
          if (!polylineArray.current[block]) {
            polylineArray.current[block] = [];
          } else {
            polylineArray.current[block].forEach((polyline) => polyline.setMap(null));
            polylineArray.current[block] = [];
          }
          polylineArray.current[block] = [...polylineArray.current[block], ...newPolylines.flat()];
          updatePolylineStyles(); // Update polyline style on first creation
        }
      } catch (e) {
        console.error(e);
        clearPolylines(block);
      }
    };
    renderAllSubBlocks();

    return () => {
      isMountedRef.current = false;
    };
  }, [region, property, block]);

  /**  Updates polyline style when sub-block is selected */
  useEffect(() => {
    updatePolylineStyles();
  }, [subBlock]);

  const renderWithinBounds = () => {
    if (!mapVariable.current) return;
    const bounds = mapVariable.current.getBounds();
    markerArray.current.forEach((marker) => {
      if (bounds.contains(marker.point.getPosition())) {
        marker.point.setVisible(true);
      } else {
        marker.point.setVisible(false);
      }
    });
  };

  const handleMarkerCenteringAndOpacity = (point, markerIndex, selectedId, selectedRobots) => {
    autonomyRobotStore.setSelectedRobot(selectedId);
    const currentRobot = autonomyRobotStore.getSelectedRobot();
    currentSelectedRobot.current = currentRobot.serial_number;

    if (currentRobot.serial_number === point.serialNumber || selectedRobots.includes(point.serialNumber)) {
      if (currentSelectedRobot.current !== previousSelectedRobot.current) {
        mapVariable.current.setZoom(18);
        const selectedMarker = markerArray.current.find((marker) => marker.serialNumber === currentRobot.serial_number);
        mapVariable.current.panTo(selectedMarker?.point?.position);
        previousSelectedRobot.current = currentRobot.serial_number;
        renderWithinBounds();
      }
    }
  };

  const renderMarkers = (robots, selectedId, selectedRobots) => {
    robotLocations.current.forEach((point, index) => {
      let markerIndex = markerArray.current.findIndex((marker) => marker.serialNumber === point.serialNumber);
      const arrayIndex = robots.findIndex((robot) => robot.serial_number === point.serialNumber);

      // does icon exist
      if (markerIndex !== -1) {
        // is the robot online
        if (arrayIndex !== -1) {
          markerArray.current[markerIndex].point.setPosition(point);
        } else {
          markerArray.current[markerIndex].point.setMap(null);
          markerArray.current.splice(markerIndex, 1);
          robotLocations.current.splice(index, 1);
        }
      } else if (arrayIndex !== -1) {
        const marker = new mapsVariable.current.Marker({
          position: point,
          label: {
            text: `${point.name} ${point.online ? '🟢' : '⚫'}`,
            color: '#EA2840',
            fontSize: '18px',
            fontWeight: 'bold',
            className: classes.markerPosition
          },
          map: mapVariable.current
        });
        marker.setIcon(icon.current);
        markerArray.current.push({ serialNumber: point.serialNumber, point: marker });
        markerIndex = markerArray.current.length - 1;

        if (!firstMarkerCentered.current && point.lat !== 0 && point.lng !== 0) {
          mapVariable.current.setZoom(17);
          mapVariable.current.panTo(marker.position);
          firstMarkerCentered.current = true;
        }
      }

      handleMarkerCenteringAndOpacity(point, markerIndex, selectedId, selectedRobots);
    });
  };

  const checkLatAndLng = (latitude, longitude) => typeof latitude === 'number' && typeof longitude === 'number';

  const updateRobotLocations = () => {
    const { robots } = autonomyRobotStore;

    const selectedId = autonomyRobotStore.getSelectedRobot().id;
    const { selectedRobots } = autonomyRobotStore;

    robots.forEach(async (robot) => {
      autonomyRobotStore.setSelectedRobot(robot.id);
      const robotInfo = await autonomyRobotStore.getSelectedRobot();
      let latitude;
      let longitude;

      if (robot?.status !== 'OFFLINE' && robot?.robot_state?.location_state?.gps_fix_status === 'Fixed RTK') {
        const location = robotInfo?.robot_state?.location_state;
        latitude = location?.latitude;
        longitude = location?.longitude;
      } else {
        ({ latitude, longitude } = robotInfo);
      }

      const index = robotLocations.current.findIndex((robotLocation) => robotLocation.serialNumber === robotInfo.serial_number);

      if (checkLatAndLng(latitude, longitude)) {
        if (index !== -1) {
          robotLocations.current[index].lat = latitude;
          robotLocations.current[index].lng = longitude;
          robotLocations.current[index].online = robot?.status && robot?.status !== 'OFFLINE';
        } else {
          robotLocations.current.push({
            serialNumber: robotInfo.serial_number,
            name: robotInfo.name,
            lat: latitude,
            lng: longitude,
            online: robot?.status !== undefined && robot?.status !== 'OFFLINE'
          });
        }
      }
    });

    if (mapVariable.current !== undefined && mapsVariable.current !== undefined) {
      renderMarkers(robots, selectedId, selectedRobots);
    }
  };

  useEffect(() => {
    updateRobotLocations();

    const interval = setInterval(() => {
      updateRobotLocations();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const renderPolylines = (map, maps) => {
    mapVariable.current = map;
    mapsVariable.current = maps;
    icon.current = {
      url: robotIcon // url
    };
  };

  return (
    <div style={{ height: '500px' }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
          libraries: 'geometry'
        }}
        center={center}
        defaultZoom={18.56}
        options={{
          streetViewControl: true,
          overviewMapControl: true,
          disableDefaultUI: false,
          zoomControl: true,
          mapTypeId: 'hybrid',
          draggable: true
        }}
        onGoogleApiLoaded={({ map, maps }) => renderPolylines(map, maps)}
        onDragEnd={() => renderWithinBounds()}
        onZoomAnimationEnd={() => renderWithinBounds()}
        yesIWantToUseGoogleMapApiInternals
      />
    </div>
  );
});

export default RobotsMapView;
