import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

export default function ConnectionErrorDialog({ open, backdropProps, style, containerRef, handleClose, errorMessage }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      style={style}
      BackdropProps={backdropProps}
      container={containerRef === undefined || !containerRef ? null : containerRef.current}
      aria-describedby="connection-error-description"
    >
      <DialogContent>
        <DialogContentText id="connection-error-description">{errorMessage}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
