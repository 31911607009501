import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
  IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%'
  },
  containerScrollable: {
    height: '100%',
    maxHeight: 445
  },
  fullScreen: {
    height: '100%',
    maxHeight: 725
  },
  table: {
    overflowY: 'hidden',
    minWidth: '500px'
  },
  smallTable: {
    minWidth: '320px'
  },
  tableHeader: {
    backgroundColor: theme.palette.grey[600],
    color: theme.palette.primary.contrastText
  },
  tableBody: {},
  tableCell: {
    whiteSpace: 'nowrap'
  },
  textInput: {
    backgroundColor: theme.palette.inverted.main,
    borderRadius: 5
  },
  selected: {
    backgroundColor: theme.palette.secondary.light
  },
  sortButton: {
    color: theme.palette.primary.contrastText,
    borderRadius: '0'
  },
  arrow: {
    paddingLeft: theme.spacing(1)
  }
}));

export const StripedTable = ({
  headers = [],
  children,
  align,
  scrollable,
  isRepeatPage,
  isFullScreen = false,
  selectedSortParam,
  selectedSortType,
  setSelectedSortParam,
  setSelectedSortType
}) => {
  const classes = useStyles();

  const flipSortType = () => {
    if (selectedSortType === 'asc') setSelectedSortType('des');
    else setSelectedSortType('asc');
  };

  const handleSortParam = (id) => {
    if (selectedSortParam === id) flipSortType();
    else {
      setSelectedSortType('asc');
      setSelectedSortParam(id);
    }
  };

  const isObject = (variable) => {
    if (variable.length) return !!variable[0] && variable[0].constructor === Object;
    return false;
  };

  return (
    <TableContainer
      component={Paper}
      className={scrollable ? (isFullScreen ? classes.fullScreen : classes.containerScrollable) : classes.container}
    >
      <Table stickyHeader className={`${classes.table} ${isRepeatPage ? classes.smallTable : ''}`}>
        <TableHead>
          <TableRow>
            {headers.map((header, i) => (
              <TableCell className={classes.tableHeader} key={i} align={align}>
                {isObject(headers) ? (
                  header.isSortable ? (
                    <Tooltip title={<Typography>Sort by {header.name}</Typography>}>
                      <IconButton disableRipple className={classes.sortButton} size="small" onClick={() => handleSortParam(header.id)}>
                        <Typography component="p" variant="subtitle1">
                          {header.name}
                        </Typography>
                        {header.id === selectedSortParam && selectedSortType === 'asc' && (
                          <div className={classes.arrow}>
                            <KeyboardArrowDownRoundedIcon size="small" />
                          </div>
                        )}
                        {header.id === selectedSortParam && selectedSortType === 'des' && (
                          <div className={classes.arrow}>
                            <KeyboardArrowUpRoundedIcon size="small" />
                          </div>
                        )}
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Typography component="p" variant="subtitle1">
                      {header.name}
                    </Typography>
                  )
                ) : (
                  <Typography component="p" variant="subtitle1">
                    {header}
                  </Typography>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};
