import { Box, Button, Grid, LinearProgress, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';
import { getDistance } from 'geolib';
import { ROUTE_SOLAR_AUDIT_OFFICER_DETAILS, ROUTE_SOLAR_REMOTE } from './routes';
import { ControlImageCapture } from '../components/control/control-image-capture.component';
import { BatteryIndicator } from '../components/control/control-battery-indicator.component';
import { ToolIndicator } from '../components/control/control-tool-indicator.component';
import { MapSwitch } from '../components/control/switch-map-visualization';
import { ControlSlider } from '../components/control/control-slider.component';
import { ControlSwitch } from '../components/control/control-switch.component';
import { MotorStatus } from '../components/control/motor-status.component';
import { useStores } from '../store/root/root.store';
import { guardedClient } from '../utils/axios-instance';
import { formatSubrows } from '../utils/format-solar-row';
import { areClose } from '../utils/compare-util';
import RepeatControls from '../components/control/repeat-controls.component';
import LoadingDialog from '../components/dialogs/loading-dialog.dialog';
import { EStopIndicator } from '../components/control/e-stop-indicator';
import { ChaperoneRobotConnectionService } from '../services/chaperone/robot-connection.service';
import { RosChaperoneService } from '../services/chaperone/ros.service';
import { AutonomyVideo } from '../components/control/autonomy-video.component';
import ActionsDialog from '../components/dialogs/actions.dialog';
import ConnectionErrorDialog from '../components/dialogs/connection-error.dialog';
import { Joystick } from '../input';
import { NOT_STARTED, IN_PROGRESS, COMPLETED, ERROR_TIME_THRESHOLD, DECK_AND_FRAME_OFFSET_SLIDER_MARKS } from '../utils/constants';
import SolarGridMap from '../components/gridMaps/SolarGridMap';
import SolarMap from '../components/maps/solar-map';
import { isDevMode } from '../utils/ui.utils';
import { useSwitchTab } from '../utils/switch-tab.hook';
import BatteryAlert from '../components/core/battery-level-warning-component';
import ConfirmActionDialog from '../components/dialogs/confirm-action.dialog';

/* eslint-disable no-unused-expressions */

const analogDeadZone = 0.1;
const maxSpeed = 2.429512; // maximum speed in metres/second
const maxRotationRate = 2.25; // maximum angular speed in radians/second
const maxSpeedLimit = 3; // max level of the speed limit control
let gamepadIndex = 0;
let previousButtons = Array(16).fill(0);
let previousAxes = Array(8).fill(0.0);

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 1600,
    minHeight: 800
  },
  controlAreaLeftPanel: {
    paddingRight: theme.spacing(1)
  },
  controlAreaMainPanel: {
    position: 'relative',
    marginTop: '35px'
  },
  controlAreaRightPanel: {
    padding: theme.spacing(1)
  },
  controlAreaControlPanel: {
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: '35px'
  },
  joystick: {
    overflow: 'visible',
    position: 'relative'
  },
  PS4: {
    overflow: 'visible',
    position: 'relative'
  },
  mapTitle: {
    background: 'rgba(32, 32, 32, 1.0)',
    color: theme.palette.inverted.main,
    height: '3.5%',
    padding: theme.spacing(1)
  },
  imgPanelShort: {
    height: '25%'
  },
  imgPanelTall: {
    height: '50%'
  },
  progress: {
    color: theme.palette.inverted.main
  },
  robotControl: {
    width: '100%',
    backgroundColor: theme.palette.grey[500],
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: 3,
    borderRadius: theme.spacing(1)
  },
  robotControlImg: {
    width: '100%',
    backgroundColor: theme.palette.grey[500],
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(1)
  },
  toggleMapSty: {
    backgroundColor: theme.palette.grey[500],
    width: '100%',
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(1)
  },
  robotControlAlt: {
    width: '100%',
    lineHeight: '0px',
    paddingBottom: theme.spacing(0.5),
    borderRadius: theme.spacing(1)
  },
  map: {
    position: 'absolute',
    right: '0',
    bottom: 0,
    zIndex: '3',
    border: '3px solid gray'
  }
}));

export const SolarRemoteGuardianRepeatPage = observer(() => {
  const classes = useStyles();
  const { applicationStore, controlStore, autonomyRobotStore, subsectionStore, subrowStore } = useStores();
  const robotConnection = useRef(null);
  const robotConnectionService = useRef(null);
  const frontVideoStream = useRef(null);
  const [connecting, setConnecting] = useState(false);
  const [controlMode, setControlMode] = useState('manual');
  const [start, setStart] = useState(true);
  const [resume, setResume] = useState(false);
  const [pause, setPause] = useState(false);
  const [resetRobot, setResetRobot] = useState(false);
  const [userRobot, setUserRobot] = useState(false);
  const [solarSubRowsData, setSolarSubRowsData] = useState([]);
  const [cords, setCords] = useState([]);
  const [solarSubRows, setSolarSubRows] = useState([]);
  const [selectedSolarSubRows, setSelectedSolarSubRows] = useState([]);
  const [isSuspendedRepeatingDialogOpen, setIsSuspendedRepeatingDialogOpen] = useState(false);
  const [currentPositionIndex, setCurrentPositionIndex] = useState(0);
  const [currentSplittedRowName, setCurrentSplittedRowName] = useState('');
  const [counter, setCounter] = useState(0);
  const { state, pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [loadingCountdownMessage, setLoadingCountdownMessage] = useState('');
  const [loadingCountdownTime, setLoadingCountdownTime] = useState(0);
  const [connectionError, setConnectionError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [subsection, setSubsection] = useState('');
  const [, setSaltRate] = useState(0);
  const [lightsState, setLightsState] = useState(0);
  const [beeperState, setBeeperState] = useState(0);
  const [lookAheadDistanceError, setLookAheadDistanceError] = useState(false);
  const [mapType, setMapType] = useState('googleMap');
  const [teleopsDrivingEnabled, setTeleopsDrivingEnabled] = useState(false);
  const { push } = useHistory();
  const [positionError, setPositionError] = useState(false);
  const [openAutonomousDialog, setOpenAutonomousDialog] = useState(false);
  const [showBatteryAlert, setShowBatteryAlert] = useState(false);
  const [userConfirmedBatteryWarning, setUserConfirmedBatteryWarning] = useState(false);
  const [frameOffset, setFrameOffset] = useState(0);
  const [deckOffset, setDeckOffset] = useState(0);
  const [isNextPathInterRow, setIsNextPathInterRow] = useState(false);
  const [isCurrentPathComplete, setIsCurrentPathComplete] = useState(false);
  const [showInterRowConfirmationDialog, setshowInterRowConfirmationDialog] = useState(true);
  const toolType = 'Lawn-Mower Active';
  const currentSubrow = useRef(null);
  const isSolarAuditOfficer = pathname === ROUTE_SOLAR_AUDIT_OFFICER_DETAILS;
  const currentCount = useRef(null);
  currentCount.current = counter;
  const keepSelectedRobot = useRef(false);
  const containerRef = useRef(null);
  const isSimMode = autonomyRobotStore.getSelectedRobot()?.serial_number?.includes('sim');
  const isRightOffset = controlStore.isRightOffsetDeck;
  // !isSimMode && useSwitchTab(ROUTE_SOLAR_REMOTE);

  const { batteryLevel } = controlStore;
  const isToolActive = controlStore.isLawnMowing;
  const autonomyRepeatingIsDone = controlStore.isRepeatingDone;
  const currentWpsState = controlStore.wpsState;
  const { previousWpsState } = controlStore;
  const username = localStorage.getItem('username');

  const isMountedRef = useRef(null);

  const d = new Date();
  const currTime = d.getTime();
  let lastFixedRTKTime = null;

  const handleConnectionError = () => {
    if (isDevMode) {
      console.log('Developing mode...');
      return;
    }
    setConnectionError(true);
    setErrorMessage(
      `An error occurred with robot ${
        autonomyRobotStore.getSelectedRobot().name
      }, check the robot's internet connection and if the issue occurs again, try to reboot the robot.`
    );
  };

  if (robotConnection?.current?.time && currTime - robotConnection?.current?.time > ERROR_TIME_THRESHOLD) handleConnectionError();

  const switchToManualControl = () => {
    if (controlStore.getControlMode() !== 'manual') robotConnection?.current?.ros.setControlMode('manual');
  };

  const switchToAutonomousControl = () => {
    if (controlStore.getControlMode() !== 'autonomous') robotConnection?.current?.ros.setControlMode('autonomous');
  };

  const switchToTeleopsControl = () => {
    if (controlStore.getControlMode() !== 'teleops') robotConnection?.current?.ros.setControlMode('teleops');
  };

  const isValidPosition = (subrowReferencePoint) => {
    if (isDevMode) {
      return true;
    }
    const distance = getDistance(
      { latitude: Number(subrowReferencePoint.lat), longitude: Number(subrowReferencePoint.lng) },
      { latitude: controlStore.lat, longitude: controlStore.lng },
      0.01
    );
    if (Math.fround(distance) > Math.fround(1)) {
      setPositionError(true);
      setConnectionError(true);
      setErrorMessage("Can't start repeating this subrow, the robot is not close to the starting point.");
      return false;
    }
    let diffAngle = controlStore.current_heading_rad - subrowReferencePoint.angle;
    if (diffAngle > 3.14) {
      diffAngle -= 6.28;
    } else if (diffAngle < -3.14) {
      diffAngle += 6.28;
    }
    if (Math.abs(diffAngle) > 0.75) {
      setPositionError(true);
      setConnectionError(true);
      setErrorMessage("Can't start repeating this subrow, the robot's heading is not close to the initial recorded orientation.");
      return false;
    }
    return true;
  };

  const sendRepeatCmdToRobot = async (action) => {
    setUserRobot(true);
    const command = 'REPEAT';
    console.log(`############################### ${currentSubrow.current}`);
    robotConnection?.current?.ros?.cmdRobotService(
      command,
      [`${action},${currentSubrow.current},${localStorage.getItem('username')},${subrowStore.currentPathType}`],
      (result) => {
        if (!loadingDialogTimerInterval.current) {
          setLoading(false);
          setLoadingMessage('');
        }
        if (result.error_message === '') return;
        console.log(`There was the following error with the repeat action: ${result.error_message}`);
        applicationStore.pushError(
          'Error',
          'The robot encountered an error with this repeat action, please report this to the autonomy team if the issue persists'
        );
      }
    );
  };

  const showLoadingDialog = (message) => {
    setLoading(true);
    setLoadingMessage(message);
  };

  const startLoadingMsg = () => {
    if (isDevMode) {
      console.log('Developing mode...');
      return;
    }
    showLoadingDialog('We are provisioning the robot now, it will begin the path in a few seconds.');
  };

  const startNextSolarRow = () => {
    if (selectedSolarSubRows.length > 0) {
      currentSubrow.current = selectedSolarSubRows[0];
      const subrowReferencePoint = {
        lat: solarSubRowsData[currentSubrow.current][0].lat,
        lng: solarSubRowsData[currentSubrow.current][0].long,
        angle: solarSubRowsData[currentSubrow.current][0].angle
      };
      console.log('nxt::currentSubrow.current:: ', currentSubrow.current);
      console.log('nxt::currentSubrow.current data:: ', subrowReferencePoint);
      if (isValidPosition(subrowReferencePoint)) {
        switchToAutonomousControl();
        sendRepeatCmdToRobot('SUBROWS_START');
        startLoadingMsg();
      }
    }
  };

  const openNewSubrow = async () => {
    setOpenDialog(false);
    startNextSolarRow();
  };

  const closeDialogue = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    const bgBox = document.querySelector('#bg-box');
    if (localStorage.getItem('useCase') === 'NON_SOLAR_LAWN_MOWING') {
      bgBox.classList.remove('bg-solar', 'bg-snow');
      bgBox.classList.add('bg-non-solar');
    } else if (localStorage.getItem('useCase') === 'SNOW_PLOWING') {
      bgBox.classList.remove('bg-solar', 'bg-non-solar');
      bgBox.classList.add('bg-snow');
    }
  }, []);

  const handleSolarSubRows = (results) => {
    const solarSubRowsList = results.map((row) => {
      const splittedRow = row.name.split('/').at(-1);
      const splittedRowName = splittedRow.substring(0, splittedRow.indexOf('__'));
      return {
        ...row,
        name: splittedRowName,
        value: splittedRow
      };
    });
    setSolarSubRows(solarSubRowsList);
  };

  useEffect(() => {
    if (state?.subBlock !== 'NONE') {
      guardedClient.get('/subrows', { params: { subblockId: state.subBlock, pathType: state?.pathType } }).then((res) => {
        handleSolarSubRows(formatSubrows(res.data.results, state.region, state.property, state.block, state.subBlock));
      });
    }
  }, [state]);

  useEffect(() => {
    guardedClient
      .get('/robots/solar-map/', {
        params: {
          regionId: state?.region,
          propertyId: state?.property,
          blockId: state?.block,
          subblockId: state?.subBlock,
          pathType: state?.pathType
        }
      })
      .then((res) => {
        setSolarSubRowsData(res.data.data);
        const coordinates = Object.entries(res.data.data).map(([key, points]) =>
          points.map((sample) => ({
            key,
            lat: Number(sample.lat),
            lng: Number(sample.long),
            angle: Number(sample.angle)
          }))
        );
        setCords(coordinates);
      })
      .catch((err) => console.log(err));

    return () => {
      controlStore.resetRobotPosition();
      controlStore.resetStore();
    };
  }, []);

  useEffect(() => {
    setSaltRate(controlStore.salter_level);
  }, [controlStore.salter_level]);

  useEffect(() => {
    setLightsState(controlStore.lightsState);
  }, [controlStore.lightsState]);

  useEffect(() => {
    setBeeperState(controlStore.beeperState);
  }, [controlStore.beeperState]);

  useEffect(() => {
    if (controlStore.previousControlMode === 'teleops') {
      setTeleopsDrivingEnabled(false);
    }
  }, [controlStore.previousControlMode]);

  const handlesetTeleopsDrivingEnabled = (value) => {
    setTeleopsDrivingEnabled(value);
  };

  const skipCurrentSubsection = () => {
    if (!start) {
      setStart(true);
      if (pause) {
        setPause(false);
      } else {
        setPause(true);
      }
      sendRepeatCmdToRobot('SKIP');
      controlStore.resetRobotPosition();
      setResetRobot(true);
      switchToManualControl();
    }
  };

  useEffect(
    () =>
      autorun(() => {
        console.log('[controlMode]', controlStore.getControlMode());
        if (controlStore.controlMode !== controlStore.previousControlMode) {
          if (!start && Number(currentWpsState) !== 6) {
            if (controlStore.controlMode === 'autonomous' && controlStore.previousControlMode !== 'autonomous' && !pause) {
              setPause(true);
              sendRepeatCmdToRobot('RESUME');
            } else if (
              controlStore.controlMode !== 'autonomous' &&
              controlStore.previousControlMode !== controlStore.controlMode &&
              pause
            ) {
              setPause(false);
              sendRepeatCmdToRobot('PAUSE');
            }
          }
          setControlMode(controlStore.controlMode);
        }
      }),
    [start, pause]
  );

  useEffect(() => {
    if (resetRobot) {
      setResetRobot(false);
    }
  }, [resetRobot]);

  useEffect(() => {
    if (state.region && state.property && state.block && state.subsection) {
      subsectionStore.getSubsections(state.region, state.property, state.block);
      setSubsection(state.subsection);
    }
  }, []);

  useEffect(() => {
    if (autonomyRobotStore.getSelectedRobot().status === 'EXEC_SUSPENDED') {
      setUserRobot(true);
      setIsSuspendedRepeatingDialogOpen(true);
    }
    keepSelectedRobot.current = true;
  }, [autonomyRobotStore]);

  const handleReleaseRobot = async () => {
    const cmd = 'CANCEL';
    if (currentWpsState > 0) {
      sendRepeatCmdToRobot(cmd);
      setIsSuspendedRepeatingDialogOpen(false);
      const operatingSubrowId = autonomyRobotStore.getSelectedRobot()?.operating_subrow_id;
      setSolarSubRows(solarSubRows.map((subrow) => (subrow.id === operatingSubrowId ? { ...subrow, status: NOT_STARTED } : subrow)));
    }
  };
  const fetchSubrowMeta = async (subrowId) => {
    const response = await guardedClient.get(`/subrows/${subrowId}`);
    return response.data.results;
  };

  const handleSuspendedRepeatingDialogGoBack = async () => {
    const subrowInfo = await fetchSubrowMeta(autonomyRobotStore.getSelectedRobot()?.operating_subrow_id);
    const formattedSubrow = formatSubrows([subrowInfo], state.region, state.property, state.block, state.subBlock)[0];
    const splittedRow = formattedSubrow.name.split('/').at(-1);
    setCurrentSplittedRowName(splittedRow);
    const splittedRowName = splittedRow.substring(0, splittedRow.indexOf('__'));
    formattedSubrow.name = splittedRowName;
    formattedSubrow.value = splittedRow;
    setSelectedSolarSubRows([formattedSubrow.value]);
    currentSubrow.current = selectedSolarSubRows[0];
    setIsSuspendedRepeatingDialogOpen(false);
    setResume(true);
    setStart(false);
    // Call the last wps_index service
    robotConnection?.current?.ros?.getLastWpsIndexService((wpsInexStr) => {
      console.log({ wpsInexStr });
      setCurrentPositionIndex(parseInt(wpsInexStr));
    });
  };

  const cancelRepeatingTask = async (done = false) => {
    if (!done) {
      handleReleaseRobot();
      controlStore.resetStore();
    }
    switchToManualControl();
    if (!start) {
      setStart(true);
      if (pause) {
        setPause(false);
      } else {
        setPause(true);
      }
      await sendRepeatCmdToRobot('FINISH');
      controlStore.resetDoneRepeating();
    }
  };

  const changeRepeatingState = () => {
    if (start && selectedSolarSubRows.length > 0) {
      if (isDevMode) {
        console.log('Developing mode...');
      } else if (controlStore.lat && controlStore.lng) {
        if (!controlStore.stableLocalization) {
          setPositionError(true);
          setConnectionError(true);
          setErrorMessage('INS Error, INS solution has not initialized. Try driving the robot forwards and back 10m and checking again');
          return;
        }
        console.log('GPS data is good to allow the robot to goooo!!');
      } else {
        setConnectionError(true);
        setErrorMessage('GPS  Error, check the GPS and if the issue occurs again, try to reboot the robot!');
        return;
      }
      currentSubrow.current = selectedSolarSubRows[0];
      console.log('selectedSolarSubRows:: ', selectedSolarSubRows);
      const subrowReferencePoint = {
        lat: solarSubRowsData[currentSubrow.current][0].lat,
        lng: solarSubRowsData[currentSubrow.current][0].long,
        angle: solarSubRowsData[currentSubrow.current][0].angle
      };

      console.log('next::currentSubrow.current:: ', currentSubrow.current);
      console.log('next::currentSubrow.current data:: ', subrowReferencePoint);
      if (!isValidPosition(subrowReferencePoint)) {
        return;
      }
    }
    if (resume) {
      if (!controlStore.stableLocalization) {
        setPositionError(true);
        setConnectionError(true);
        setErrorMessage('INS Error, INS solution has not initialized. Try driving the robot forwards and back 10m and checking again');
        return;
      }
      console.log('GPS data is good to allow the robot to goooo!!');
      setPause(!pause);
      setResume(!resume);
      switchToAutonomousControl();
      sendRepeatCmdToRobot('RESUME');
    } else if (pause) {
      setPause(!pause);
      setResume(!resume);
      switchToManualControl();
      sendRepeatCmdToRobot('PAUSE');
    } else if (start && selectedSolarSubRows.length > 0) {
      setStart(!start);
      setPause(!pause);
      switchToAutonomousControl();
      setResetRobot(true);

      guardedClient
        .get(`/robots/${autonomyRobotStore.selectedRobotId}/status`)
        .then((res) => {
          if (res.data.results.status === 'AVAILABLE' || userRobot) {
            sendRepeatCmdToRobot('SUBROWS_START');
            startLoadingMsg();
          } else {
            setConnectionError(true);
            cancelRepeatingTask();
            setErrorMessage('Robot is already in-use, check it again later or select another robot!');
          }
        })
        .catch((err) => {
          console.log(err);
          setConnectionError(true);
          cancelRepeatingTask();
          setErrorMessage('Robot is already in-use, check it again later or select another robot!');
        });
    }
  };

  const confirmWarningDialog = () => {
    switchToAutonomousControl();
    setOpenAutonomousDialog(false);
  };
  const closeAutonomousDialogue = () => setOpenAutonomousDialog(false);

  useEffect(() => {
    function handleSpace(e) {
      if (!start && e.key === ' ') {
        e.preventDefault();
        e.stopPropagation();
        if (controlMode !== 'teleops') {
          switchToTeleopsControl();
        } else if (controlMode !== 'autonomous') {
          setOpenAutonomousDialog(true);
        }
      }
    }
    document.addEventListener('keyup', handleSpace);
    return () => {
      document.removeEventListener('keyup', handleSpace);
    };
  }, [controlMode, pause]);

  const changeDriveState = useCallback(
    (nextControlMode) => {
      if (currentSubrow.current || (nextControlMode !== null && !(start && nextControlMode === 'autonomous'))) {
        robotConnection?.current?.ros.setControlMode(nextControlMode);
      }
    },
    [controlMode]
  );

  const handleSolarRowsStatus = (updatedStatus) => {
    const updatedSubrows = solarSubRows.map((subrow) => {
      if (subrow.value.includes(selectedSolarSubRows[0])) {
        return { ...subrow, status: updatedStatus };
      }
      return subrow;
    });
    setSolarSubRows(updatedSubrows);
    const updatedSubrow = updatedSubrows.find((subrow) => subrow.value.includes(selectedSolarSubRows[0]));
    if (updatedSubrow) {
      guardedClient.patch(`/subrows/${updatedSubrow.id}`, {
        status: updatedStatus
      });
    }
  };

  useEffect(() => {
    if (robotConnectionService.current !== null && previousWpsState !== currentWpsState) {
      // check for idle robot state data
      if ((Date.now() - controlStore.lastRobotStateReceived) / 1000 > 20 && controlStore.getControlMode() === 'autonomous') {
        // in seconds
        setPositionError(true);
        setConnectionError(true);
        if (pause) {
          setErrorMessage(
            `Robot State Error: Unable to continue executing the path. ${
              autonomyRobotStore.getSelectedRobot().serial_number
            }'s current state is not being communicated.`
          );
          setResume(!resume);
          changeDriveState('teleops');
          sendRepeatCmdToRobot('PAUSE');
        }
        // Check for change in other Waypoints state
        if (!start && autonomyRepeatingIsDone) {
          console.log('WAYPOINTS REPEATING IS DONE');
          handleSolarRowsStatus(COMPLETED);
          setSelectedSolarSubRows(selectedSolarSubRows.slice(1));
          selectedSolarSubRows.shift();
          cancelRepeatingTask(true);
          if (selectedSolarSubRows.length > 0) {
            setOpenDialog(true);
          }
        } else if (Number(currentWpsState) === 0) {
          setStart(true);
          setPause(false);
          setResume(false);
        } else if (Number(currentWpsState) === 4) {
          setStart(false);
          setPause(true);
          handleSolarRowsStatus(IN_PROGRESS);
        } else if (!start && Number(currentWpsState) === 40) {
          setResume(true);
          setPause(false);
        }
        controlStore.setPreviousWpsState(currentWpsState);
      }
      // controlStore.setPreviousWpsState(currentWpsState);
    }
  }, [robotConnectionService, start, currentWpsState]);

  useEffect(() => {
    console.log('In remote gaudian page useEffect');
    // Confirm the robot has a WebSocket connection to the gateway
    (async () => {
      if (autonomyRobotStore.selectedRobotId) {
        // Running async code in the effect, we need to track component is mounted
        isMountedRef.current = true;
        try {
          // On first load, initiate the robot connection
          if (isMountedRef.current && robotConnection.current === null) {
            console.debug('Connecting to robot ', autonomyRobotStore.selectedRobotId);
            setConnecting(true);
            robotConnectionService.current = new ChaperoneRobotConnectionService(
              () => {
                // onConnected
                robotConnectionService?.current?.ros.subscribeToRobotStateStamped((robotState) =>
                  controlStore.updateRobotState(robotState)
                );
                robotConnectionService?.current?.ros.subscribeToDoneRepeatingMission((msg) =>
                  controlStore.setDoneRepeating({ data: true })
                );
                // To trigger the janus ML if it wasn't active
                robotConnection.current = robotConnectionService.current;
                switchToManualControl();
                controlStore.setSpeedLimit(0);
                setConnecting(false);
                robotConnectionService?.current?.ros.subscribeToRobotNotification((message) => {
                  controlStore.setNotificationMessage(message.data);
                  if (isRightOffset !== null) {
                    if (isRightOffset) {
                      setDeckOffset(parseInt(controlStore.deckOffset));
                      setFrameOffset(parseInt(controlStore.frameOffset));
                    } else {
                      setDeckOffset(parseInt(controlStore.deckOffset * -1));
                      setFrameOffset(parseInt(controlStore.frameOffset * -1));
                    }
                  }
                });
                robotConnectionService?.current?.ros.subscribeToIsPathDoneTopic((message) => {
                  if (message !== null) {
                    setIsCurrentPathComplete(message.data);
                  }
                });
                robotConnectionService?.current?.ros.subscribeToNextPathIsInterRow((message) => {
                  if (message !== null) {
                    setIsNextPathInterRow(message.data);
                  }
                });
                robotConnectionService?.current?.ros.isWebSocketDisconnected((isWebSocketDisconnected) => {
                  if (isWebSocketDisconnected) {
                    applicationStore.pushError(
                      'Error',
                      `You have been disconnected from ${autonomyRobotStore.getSelectedRobot().name} by a different remote guardian`
                    );
                  }
                });
              },
              () => {
                // onDisconnect
                console.log('Lost connection to robot');
                if (robotConnectionService.current !== null) {
                  if (!start) {
                    sendRepeatCmdToRobot('PAUSE');
                  } else {
                    handleReleaseRobot();
                  }
                  switchToManualControl();
                  robotConnectionService?.current?.retryConnection();
                }
                handleConnectionError();
                robotConnection.current = null;
              },
              autonomyRobotStore.getSelectedRobot().serial_number,
              username,
              'solar_remote_guardian_repeat'
            );
          }
        } catch (error) {
          console.error('Failed to connect', error);
        }
      } else {
        applicationStore.pushError('Error', 'No robot is selected');
      }
    })();

    robotConnectionService?.current?.connectToRobot(handleConnectionError);
    // Cleanup function
    return () => {
      isMountedRef.current = false;
      if (robotConnectionService.current !== null) {
        if (!start) {
          handleSolarRowsStatus(NOT_STARTED);
          switchToManualControl();
          sendRepeatCmdToRobot('PAUSE');
        } else {
          handleReleaseRobot();
        }
        robotConnectionService?.current?.destroy();
        robotConnectionService.current = null;
        robotConnection.current = null;
        const selectedRobotIdAtCleanup = autonomyRobotStore.selectedRobotId;
      }
      if (!keepSelectedRobot.current) {
        autonomyRobotStore.clearSelectedRobot();
      }
      autonomyRobotStore.clearSelectedRobot();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (lastFixedRTKTime !== null && (controlStore?.robotGpsFixStatus?.includes('RTK') || false)) {
      lastFixedRTKTime = null;
    }
    if (lastFixedRTKTime === null && (!controlStore?.robotGpsFixStatus?.includes('RTK') || false)) {
      lastFixedRTKTime = d.getTime();
    }
  }, [controlStore.robotGpsFixStatus]);

  if (currTime - lastFixedRTKTime && controlStore?.robotControlMode === 'autonomous' && !isDevMode) {
    setPositionError(true);
    setConnectionError(true);
    setErrorMessage(
      `RTK Error: Can't continue repeating this subrow autonomously, the robot ${
        autonomyRobotStore.getSelectedRobot().serial_number
      } does not have an RTK GPS data !`
    );
    if (pause) {
      setPause(false);
      setResume(!resume);
      switchToTeleopsControl();
      sendRepeatCmdToRobot('PAUSE');
    }
  }

  useEffect(() => {
    if (!controlStore.safetyEnabled || controlStore.highLatency) {
      if (!controlStore.safetyEnabled) {
        setErrorMessage(`The robot's safety system is down. ${controlStore.safetyErrorMessage}`);
      } else {
        setErrorMessage(`The robot is experiencing a high video latency of ${controlStore.latency * 1000}ms.`);
      }

      setPositionError(true);
      setConnectionError(true);
      switchToManualControl();
    }
  }, [controlStore.safetyEnabled, controlStore.highLatency, controlStore.getControlMode()]);

  useEffect(() => {
    let timer;

    function startTimer() {
      timer = setTimeout(() => {
        if (teleopsDrivingEnabled) {
          setTeleopsDrivingEnabled(false);
        }
      }, 180000);
    }

    function resetTimer() {
      clearTimeout(timer);
      startTimer();
    }
    ['mousemove', 'pS4Activity'].forEach((e) => {
      document.addEventListener(e, resetTimer);
    });
    startTimer();
  }, [teleopsDrivingEnabled]);

  useEffect(() => {
    const interval =
      counter > 0 &&
      setInterval(() => {
        if (counter === currentCount.current) {
          if (teleopsDrivingEnabled) {
            setTeleopsDrivingEnabled(false);
          }
          setCounter(0);
        }
      }, 180000);

    return () => clearInterval(interval);
  }, [counter, teleopsDrivingEnabled]);

  /**
   * Handles regular calls with the current joystick twist  by translating position to movement
   * commands (if it indicates the robot should not be moving).
   * @param twist twist speed for auto-turn in the range [-1,1], converted to metres/second
   */
  const handleJoystickTwist = (twist) => {
    const robot = robotConnection?.current?.ros;
    if (!robot) {
      return;
    }

    // Generate a cmdVel message if joystick handle moved. Joystick linear values are in the interval [-1, 1]
    // with negative values for moving forward. Reverse the sign when calculating the cmdVel linear property value.
    // Angular values are also in the [-1,0] interval with negative values for turning left. Leave the angular
    // value sign as-is. Treat values in the range (-0.1, 0.1) as 0 as the joystick does not generate 0-values
    // often when at rest on some OS's.
    if (controlMode === 'teleops' && controlStore.speedLimit > 0) {
      const autoTurnCmd = areClose(twist, 0) ? 0 : -1 * twist;
      if (teleopsDrivingEnabled) robot.publishTeleopsTurn(autoTurnCmd);
    }
  };

  /**
   * Handles regular calls with the current joystick position by translating position to movement
   * commands (if it indicates the robot should not be moving).
   * @param linear linear movement in the range [-1,1], converted to metres/second
   * @param angular angular movement in the range [-1,1], converted to radians/second
   */
  const handleJoystickPosition = (angular, linear) => {
    const robot = robotConnection?.current?.ros;
    if (!robot) {
      return;
    }

    // Generate a cmdVel message if joystick handle moved. Joystick linear values are in the interval [-1, 1]
    // with negative values for moving forward. Reverse the sign when calculating the cmdVel linear property value.
    // Angular values are also in the [-1,0] interval with negative values for turning left. Leave the angular
    // value sign as-is. Treat values in the range (-0.1, 0.1) as 0 as the joystick does not generate 0-values
    // often when at rest on some OS's.
    if (controlStore.speedLimit > 0) {
      const cmdVel = {
        angular: ((areClose(angular, 0) ? 0 : angular) * -1 * maxRotationRate * controlStore.speedLimit) / maxSpeedLimit,
        linear: ((areClose(linear, 0) ? 0 : linear) * -1 * maxSpeed * controlStore.speedLimit) / maxSpeedLimit
      };
      if (teleopsDrivingEnabled) robot.publishVelocity(cmdVel);
    }
  };

  // By JohnBurtt
  // Handles input from Logitech Extreme 3D and PS4 controllers
  const handleJoystickState = (currentState) => {
    const ros = robotConnection?.current?.ros;
    if (!ros) {
      return;
    }
    setCounter(counter + 1);

    let gamepads;

    if (navigator.getGamepads) {
      gamepads = navigator.getGamepads();
    } else if (navigator.webkitGetGamepads) {
      gamepads = navigator.webkitGetGamepads;
    } else {
      gamepads = [];
    }

    const event = new Event('pS4Activity');
    gamepads.forEach((gamepad) => {
      const gp = gamepad;
      // If there are any gamepads connected
      if (gp && controlMode === 'teleops') {
        // PS4
        if (gp.id.includes('Wireless Controller')) {
          gamepadIndex = gp.index;
          const buttons = Array(16).fill(0);
          const axes = Array(8).fill(0.0);
          // PERIPHERALS
          buttons[0] = gp.buttons[2].value;
          buttons[1] = gp.buttons[0].value;
          buttons[2] = gp.buttons[1].value;
          buttons[3] = gp.buttons[3].value;
          buttons[4] = gp.buttons[4].value;
          buttons[5] = gp.buttons[5].value;
          buttons[8] = gp.buttons[8].value;
          buttons[9] = gp.buttons[9].value;
          buttons[10] = gp.buttons[10].value;
          buttons[11] = gp.buttons[11].value;
          buttons[12] = gp.buttons[16].value;
          // DRIVING
          if (teleopsDrivingEnabled) {
            if (gp.buttons[6].value > analogDeadZone) buttons[6] = 1;
            if (gp.buttons[7].value > analogDeadZone) buttons[7] = 1;
            axes[0] = Math.abs(gp.axes[0]) > analogDeadZone ? gp.axes[0] * -1.0 : axes[0];
            axes[1] = Math.abs(gp.axes[1]) > analogDeadZone ? gp.axes[1] * -1.0 : axes[1];
            axes[2] = Math.abs(gp.axes[2]) > analogDeadZone ? gp.axes[2] * -1.0 : axes[2];
            axes[5] = Math.abs(gp.axes[3]) > analogDeadZone ? gp.axes[3] * -1.0 : axes[5];
            if (gp.buttons[15].value) axes[6] = -1.0;
            if (gp.buttons[14].value) axes[6] = 1.0;
            if (gp.buttons[12].value) axes[7] = 1.0;
            if (gp.buttons[13].value) axes[7] = -1.0;
          }
          robotConnection?.current?.ros.publishJoy(buttons, axes, controlStore.robotStateStamp);
          if (!(buttons.every((v, i) => v === previousButtons[i]) && axes.every((v, i) => v === previousAxes[i]))) {
            document.dispatchEvent(event);
          }
          previousButtons = buttons;
          previousAxes = axes;
        } else if (gp.id.includes('Logitech') && !gp.id.includes('Wireless')) {
          gamepadIndex = gp.index;
          /**
           * Handles regular calls with the current joystick. Joystick position is translated to movement commands
           * (including stop).  Thumbstick and related buttons are used to control the plow.
           * Trigger is used to control salter on/off.
           *
           * Handles changes from the Joystick component and issue command to the robot
           * @param currentState current joystick state
           */

          // Handle the hatstick on top of the joystick handle (the thumb stick). It control moving the plow up and down.
          // Joystick values are -1 when pushed forword (move plow down) and 1 when pulled backward (move plow up).
          if ('thumbLinear' in currentState) {
            switch (currentState.thumbLinear) {
              case -1:
                ros.cmdPlow(RosChaperoneService.PLOW_DOWN);
                break;
              case 1:
                ros.cmdPlow(RosChaperoneService.PLOW_UP);
                break;
              default:
            }
          }

          // Handle the other buttons for plow commands. For each, 1 means pressed and 0 is released.
          if (currentState.button5 === 1) {
            ros.cmdPlow(RosChaperoneService.PLOW_LEFT_FORWARD);
          }
          if (currentState.button3 === 1) {
            ros.cmdPlow(RosChaperoneService.PLOW_LEFT_BACKWARD);
          }
          if (currentState.button6 === 1) {
            ros.cmdPlow(RosChaperoneService.PLOW_RIGHT_FORWARD);
          }
          if (currentState.button4 === 1) {
            ros.cmdPlow(RosChaperoneService.PLOW_RIGHT_BACKWARD);
          }

          // The trigger controls the salter on/off (rate is set with a separate control)
          if ('trigger' in currentState) {
            if (currentState.trigger === 1) {
              ros.cmdSalterOnOff(true);
            }
            const isSalting = currentState.trigger === 1 && controlStore.salt_level > 0;
            if (controlStore.isSalting !== isSalting) {
              controlStore.setSalting(isSalting);
            }
          }
          console.log(currentState);
          if (currentState.mainAngular < 0.01 && currentState.mainLinear < 0.01) {
            handleJoystickTwist(currentState.mainTwist);
          }
          handleJoystickPosition(currentState.mainAngular, currentState.mainLinear);
        }
      }
    });
  };

  const handleCloseErrorDialog = () => {
    setConnectionError(false);
    if (positionError) {
      setPositionError(false);
      return;
    }
  };

  const handleCloseLookAheadErrorDialog = () => {
    setLookAheadDistanceError(false);
  };

  const handleRestartController = () => {
    showLoadingDialog('Restarting the robot now, the robot will be ready in less than 1.5 minutes.');
    switchToManualControl();
    robotConnection?.current?.ros?.restartRobotService('robot');
    setLoading(false);
  };

  useEffect(() => {
    setLoadingMessage(`${loadingCountdownMessage}${loadingCountdownTime} seconds`);
  }, [loadingCountdownTime]);

  const loadingDialogTimerInterval = useRef(null);
  const showLoadingDialogTimeout = (message, timeout) => {
    clearInterval(loadingDialogTimerInterval.current);
    setLoadingCountdownTime(timeout);
    setLoadingCountdownMessage(message);
    setLoading(true);
    // Starts an interval that decrements countdown every second
    loadingDialogTimerInterval.current = setInterval(() => {
      setLoading(true);
      setLoadingCountdownTime((prevTime) => prevTime - 1);
    }, 1000);
    // Clear interval after countdown finishes
    setTimeout(() => {
      clearInterval(loadingDialogTimerInterval.current);
      loadingDialogTimerInterval.current = null;
      setLoading(false);
    }, timeout * 1000);
  };

  const handleRestartVideo = () => {
    changeDriveState('manual');
    robotConnection?.current?.ros?.restartRobotService('video');
    showLoadingDialogTimeout("Restarting the robot's video streaming system please wait ", 15);
  };

  const updateNavParams = (params) => {
    robotConnection?.current?.ros.updateNavParams(params);
  };

  /**
   * Closes battery warning dialogue when user confirms/agrees to take the robot to a charging station
   * Dialogue can only be closed by clicking the Confirm button. It cannot be closed by clicking anywhere else in the viewport.
   * @param {} None
   * @returns {} None
   */
  const handleLowBatteryConfirmation = async () => {
    setUserConfirmedBatteryWarning(true);
    await sendRepeatCmdToRobot('RESUME');
    setShowBatteryAlert(false);
  };

  /**
   * Disconnects the robot from Remote guardian.
   * Function will also ensure the robot is set to manual control and is paused.
   * When robot disconnects, user will be directed to solar-remote page.
   * @param {} None
   * @returns {} None
   */
  const handleRobotDisconnect = async () => {
    console.log('Disconnecting from robot due to critical battery levels');
    if (robotConnectionService.current !== null) {
      sendRepeatCmdToRobot('PAUSE');
      handleReleaseRobot();
      switchToManualControl();
      robotConnection.current = null;
      setShowBatteryAlert(false);
    }
  };

  /**
   * This effect triggers when the battery is low or critical. When battery level falls below 10%,
   * the robot is commanded to pause and the interface switches to manual control.
   * A battery alert dialogue is shown to prompt user to charge robot. When user
   * confirms, control of robot is returned and dialogue closes.
   *
   * If the battery level falls below 5%, the robot is also commanded to pause and
   * a battery alert dialogue is shown.
   *
   * The reaction is cleaned up when the component unmounts.
   *
   * @effect
   * @async
   * @return {Promise} empty
   */
  useEffect(() => {
    const handleLowBatteryLevels = async () => {
      if (batteryLevel <= 10 && batteryLevel > 5 && !userConfirmedBatteryWarning && batteryLevel !== null && !isSimMode) {
        await sendRepeatCmdToRobot('PAUSE');
        setShowBatteryAlert(true);
      }
    };
    handleLowBatteryLevels();
  }, [batteryLevel]);

  /** Renders inter-row path confirmation dialogue if isNextPathInterRow & isCurrentPathComplete are true */
  useEffect(() => {
    if (isNextPathInterRow && isCurrentPathComplete) {
      setshowInterRowConfirmationDialog(true);
    } else {
      setshowInterRowConfirmationDialog(false);
    }
  }, [isNextPathInterRow, isCurrentPathComplete]);

  /**
   * Handles sending confirmation or cancellation for inter-row path from RG to robot.
   * When robot disconnects, user will be directed to solar-remote page.
   * @param {Boolean} confirmInterRow user confirmation or cancellation
   * @returns {} None
   */
  const handleConfirmStartInterRowPath = async (confirmInterRow) => {
    if (robotConnection?.current && robotConnectionService?.current?.hasConnected) {
      await robotConnectionService?.current?.ros.startInterRowCmdService({ data: confirmInterRow }, (result) => {
        if (!result.success) {
          applicationStore.pushError(
            'Error Starting Inter-Row Path',
            `${
              autonomyRobotStore.getSelectedRobot().serial_number
            } has encountered an error starting Inter-Row path. Please contact Autonomy.`
          );
          setIsNextPathInterRow(false);
          setIsCurrentPathComplete(false);
        }
      });
      setIsNextPathInterRow(false);
      setIsCurrentPathComplete(false);
    }
  };
  return (
    // During connection, show loader
    connecting ? (
      <>
        <LinearProgress className={classes.progress} />
        <Button
          variant="contained"
          color="secondary"
          size="large"
          style={{ marginTop: '16px' }}
          onClick={() => {
            setConnecting(false);
          }}
        >
          Cancel Connection remote guardian
        </Button>
      </>
    ) : (
      <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" className={classes.root} ref={containerRef}>
        <LoadingDialog show={loading} message={loadingMessage} maxWidth="md" />
        <ConfirmActionDialog
          title="Begin Inter-Row Hopping"
          open={showInterRowConfirmationDialog}
          action="Confirm"
          body={`${
            autonomyRobotStore.getSelectedRobot().serial_number
          } has completed the current row. Start Inter-Row path to the next row?`}
          handleClose={() => {
            handleConfirmStartInterRowPath(false);
          }}
          handleAction={() => {
            handleConfirmStartInterRowPath(true);
          }}
        />
        <RepeatControls
          changeRepeatingState={changeRepeatingState}
          changeDriveState={changeDriveState}
          cancelRepeatingTask={cancelRepeatingTask}
          solarSubRows={solarSubRows}
          selectedSolarSubRows={selectedSolarSubRows}
          setSelectedSolarSubRows={setSelectedSolarSubRows}
          readOnlyMode={isSolarAuditOfficer}
          start={start}
          pause={pause}
          controlMode={controlMode}
          robot={state.robot || state.robotId}
          region={state.region}
          property={state.property}
          block={state.block}
          subBlock={state?.subBlock}
          subsection={subsection}
          skipCurrentSubsection={skipCurrentSubsection}
          handleRestartController={handleRestartController}
          handleRestartVideo={handleRestartVideo}
          updateNavParams={updateNavParams}
        />
        <BatteryAlert
          robotSerial={autonomyRobotStore.getSelectedRobot().serial_number}
          openParam={showBatteryAlert}
          onConfirm={() => handleLowBatteryConfirmation()}
          batteryLevel={batteryLevel}
          onDisconnect={() => handleRobotDisconnect()}
          containerRef={containerRef}
        />
        {/* Main Control area: Main video feed, Joystick controls */}
        <Grid item container xs={11} justifyContent="flex-end" alignItems="flex-start" className={classes.controlAreaMainPanel}>
          {!isDevMode && (
            <AutonomyVideo stream={frontVideoStream.current} serialNumber={autonomyRobotStore.getSelectedRobot().serial_number} />
          )}
          {!isDevMode && (
            <EStopIndicator eStopEngaged={controlStore.estopState || controlStore.swEstopState} videoStream width={650} height={650} />
          )}
          {mapType === 'googleMap' ? (
            <SolarMap
              customStyle={classes.map}
              width="25%"
              height="423px"
              robotLat={controlStore.lat}
              resetRobot={resetRobot}
              googleMapData={cords}
              selectedSubRows={selectedSolarSubRows}
              robotLng={controlStore.lng}
              robotHeading={controlStore.current_heading_rad}
              currentPositionIndex={currentPositionIndex}
              currentSplittedRowName={currentSplittedRowName}
            />
          ) : (
            <SolarGridMap
              customStyle
              solarRows={solarSubRowsData}
              robotEnabled
              selectedSolarRows={selectedSolarSubRows}
              resetRobot={resetRobot}
              robotLng={controlStore.lng}
              robotLat={controlStore.lat}
              robotHeadingRad={controlStore.current_heading_rad}
              currentPositionIndex={currentPositionIndex}
              currentSplittedRowName={currentSplittedRowName}
            />
          )}
        </Grid>
        {/* Far Right Panel: Controls, Motor status */}
        <Grid
          item
          container
          xs={1}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-end"
          className={classes.controlAreaControlPanel}
        >
          <Grid container direction="column" justifyContent="flex-start" className={classes.controlAreaRightPanel}>
            <Grid item className={classes.robotControl}>
              <ControlSwitch
                label="Lamps"
                defaultValue={lightsState}
                checked={lightsState}
                onClick={() => robotConnection?.current?.ros?.cmdLights(!lightsState)}
              />
            </Grid>
            <Grid item className={classes.robotControl}>
              <ControlSwitch
                label="Beeper"
                defaultValue={beeperState}
                checked={beeperState}
                onClick={() => robotConnection?.current?.ros?.cmdBeeper(!beeperState)}
              />
            </Grid>
            <Grid item className={classes.robotControl}>
              <ControlSwitch
                label="SW ESTOP"
                defaultValue={controlStore.swEstopState}
                checked={controlStore.swEstopState}
                onClick={() => robotConnection?.current?.ros?.cmdSwEstop(!controlStore.swEstopState)}
              />
            </Grid>
            <Grid item className={classes.robotControl}>
              <ControlSlider
                label="Wiper Speed"
                defaultValue={0}
                valueLabelDisplay="off"
                marks
                min={0}
                max={2}
                step={1}
                onChange={(value) => robotConnection?.current?.ros?.cmdWiper(value)}
                key="wiperSpeed"
                robotControlMode={controlStore.controlMode}
              />
            </Grid>
            <Grid item className={classes.robotControl}>
              <ControlSlider
                label="Deck Offset"
                defaultValue={deckOffset}
                valueLabelDisplay="off"
                marks={DECK_AND_FRAME_OFFSET_SLIDER_MARKS.deckOffset}
                min={-76}
                max={76}
                step={1}
                key="deckOffset"
                robotControlMode={controlStore.controlMode}
              />
            </Grid>
            <Grid item className={classes.robotControl}>
              <ControlSlider
                label="Carriage Offset"
                defaultValue={frameOffset}
                valueLabelDisplay="off"
                marks={DECK_AND_FRAME_OFFSET_SLIDER_MARKS.frameOffset}
                min={-65}
                max={65}
                step={1}
                key="frameOffset"
                robotControlMode={controlStore.controlMode}
              />
            </Grid>
            <Grid item className={classes.robotControl}>
              <ToolIndicator toolType={toolType} isActive={isToolActive} />
            </Grid>
            <Grid item className={classes.toggleMapSty}>
              <MapSwitch mapType={mapType} handleToggleMap={setMapType} />
            </Grid>

            <Grid item align="center" className={classes.robotControlImg}>
              <ControlImageCapture service={robotConnection.current} />
            </Grid>
            <Grid item className={classes.robotControlAlt}>
              <MotorStatus />
            </Grid>
            <Grid item className={classes.robotControl}>
              <BatteryIndicator />
            </Grid>
            <Grid item style={{ width: '100%' }}>
              <Box className={classes.joystick}>
                <Joystick
                  gamepadIndex={gamepadIndex}
                  cwidth={175}
                  cheight={250}
                  visualized={true}
                  handleJoystickState={handleJoystickState}
                />
              </Box>
            </Grid>
            <Grid item className={classes.robotControl}>
              <ControlSwitch
                label="Enable Teleops Driving"
                defaultValue={teleopsDrivingEnabled}
                checked={teleopsDrivingEnabled}
                onClick={() => handlesetTeleopsDrivingEnabled(!teleopsDrivingEnabled)}
              />
            </Grid>
          </Grid>
        </Grid>
        <ActionsDialog
          dialogTitle="Next sub-row is ready to go. Drive the robot to its starting point, start Next Sub-row?"
          open={openDialog}
          actions={[
            { color: 'primary', name: 'Cancel', variant: 'outlined', handler: closeDialogue },
            { color: 'secondary', name: 'Start', variant: 'contained', handler: openNewSubrow }
          ]}
        />

        <ActionsDialog
          dialogTitle="Robot is not in autonomous mode now, do you want it in autonomous?"
          open={openAutonomousDialog}
          actions={[
            { color: 'primary', name: 'Cancel', variant: 'outlined', handler: closeAutonomousDialogue },
            { color: 'secondary', name: 'Confirm', variant: 'contained', handler: confirmWarningDialog }
          ]}
        />
        <ActionsDialog
          dialogTitle="The robot is currently suspended and there is a pending repeating operation, do you want to release the robot and cancel the current operation?"
          open={isSuspendedRepeatingDialogOpen}
          actions={[
            { color: 'primary', name: 'Release and Cancel', variant: 'outlined', handler: handleReleaseRobot },
            { color: 'secondary', name: 'Continue Repeating', variant: 'contained', handler: handleSuspendedRepeatingDialogGoBack }
          ]}
        />
        <ConnectionErrorDialog open={connectionError} handleClose={handleCloseErrorDialog} errorMessage={errorMessage} />
        <ActionsDialog
          open={lookAheadDistanceError}
          dialogTitle="ERROR: The lookahead distance must be greater than both turning thresholds"
          actions={[{ color: 'secondary', name: 'Ok', variant: 'contained', handler: handleCloseLookAheadErrorDialog }]}
        />
      </Grid>
    )
  );
});
