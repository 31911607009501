import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import { Dialog, DialogTitle, Fab, List, ListItem, ListItemText } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useStores } from '../../store/root/root.store';
import { IN_USE, OFFLINE } from '../../utils/constants';
import { MqttClient } from '../../realtimeClient';
import ConfirmActionDialog from '../dialogs/confirm-action.dialog';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(4)
  }
}));

export const AddRobotToMultiViewComponent = observer(({ selectedProperty }) => {
  const classes = useStyles();
  const { MultiRobotsStore, autonomyRobotStore, applicationStore, controlStore } = useStores();
  const [openRobotDialog, setOpenRobotDialog] = useState(false);
  const [robotToBeAdded, setRobotToBeAdded] = useState(null);
  const [showRemoveCurrentActiveClientDialog, setShowRemoveCurrentActiveClientDialog] = useState(false);
  const [removeCurrentActiveClientBody, setRemoveCurrentActiveClientBody] = useState('');

  const mqttClient = useRef(null);

  useEffect(() => {
    autonomyRobotStore.getRobots();
    (async () => {
      mqttClient.current = new MqttClient(autonomyRobotStore.updateRobotStatus);
      await mqttClient.current.connect();
    })();

    return () => {
      if (mqttClient.current) {
        mqttClient.current.disconnect();
      }
    };
  }, []);

  const addRobot = (robot) => {
    MultiRobotsStore.addRobot({
      id: robot.id,
      serialNumber: robot.serial_number,
      status: robot.status,
      useCase: robot.use_case,
      operatingSubsectionId: robot.operating_subsection_id,
      name: robot.name,
      batteryLevel: robot.battery_level,
      siteName: robot.current_property_name,
      currentRow: robot.operating_subrow_id
    });
  };

  const handleAddRobot = (robot) => {
    setOpenRobotDialog(false);
    controlStore.getCurrentActiveClient(
      robot,
      (activeClient) => {
        // onSuccess()
        if (activeClient?.uuid !== '') {
          console.debug(`user: ${activeClient?.user} is already connected to ${robot.name}`);
          setRobotToBeAdded(robot);
          setRemoveCurrentActiveClientBody(
            `${activeClient.user} is already the remote guardian of ${robot.name}, would you like to disconnect them?`
          );
          setShowRemoveCurrentActiveClientDialog(true);
        } else {
          addRobot(robot);
        }
      },
      () => {
        // onFailure()
        applicationStore.pushError(
          'Error',
          `An error was encountered while getting ${robot.name}'s current remote guardian, please report this to the autonomy team if the issue persists`
        );
        console.debug('The get_current_active_client service was unsuccessful');
      }
    );
  };

  const handleRemoveCurrentActiveClient = () => {
    setShowRemoveCurrentActiveClientDialog(false);
    controlStore.removeCurrentActiveClient(
      robotToBeAdded,
      () => {
        // onSuccess()
        console.debug('succeeded in disconnecting the active client from ', robotToBeAdded.name);
        addRobot(robotToBeAdded);
      },
      () => {
        // onFailure()
        console.debug('failed to disconnect the active client from ', robotToBeAdded.name);
        applicationStore.pushError(
          'Error',
          'There is still a remote guardian connected to this robot, please report this to the autonomy team if the issue persists'
        );
      }
    );
  };

  return (
    <>
      <Fab
        color="secondary"
        aria-label="add"
        className={classes.fab}
        onClick={() => {
          autonomyRobotStore.getRobots();
          setOpenRobotDialog(true);
        }}
      >
        <Add />
      </Fab>
      {openRobotDialog && (
        <Dialog onClose={() => setOpenRobotDialog(false)} aria-labelledby="simple-dialog-title" open={openRobotDialog}>
          <DialogTitle id="simple-dialog-title">Select a robot to add</DialogTitle>
          <List>
            {autonomyRobotStore.robots
              .filter((robot) => {
                if (MultiRobotsStore.robots.has(robot.serial_number)) {
                  return false;
                }
                if (robot.status === OFFLINE || robot.status === IN_USE) {
                  return false;
                }
                // TODO REFACTOR
                return robot.current_property_id === selectedProperty;
              })
              .map((robot) => (
                <ListItem button onClick={() => handleAddRobot(robot)} key={robot.serial_number}>
                  <ListItemText primary={`${robot.name} - ${robot.serial_number}`} />
                </ListItem>
              ))}
          </List>
        </Dialog>
      )}

      <ConfirmActionDialog
        handleAction={handleRemoveCurrentActiveClient}
        handleClose={() => setShowRemoveCurrentActiveClientDialog(false)}
        title={`Boot ${robotToBeAdded?.name}'s Remote Guardian?`}
        body={removeCurrentActiveClientBody}
        open={showRemoveCurrentActiveClientDialog}
        action="Proceed"
        key={robotToBeAdded?.name}
      />
    </>
  );
});
