/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Grid,
  makeStyles,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  Input,
  Chip,
  Container,
  Tooltip,
  IconButton
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleFilled';
import SettingsIcon from '@material-ui/icons/Settings';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useStores } from '../../../store/root/root.store';
import RepeatSettingsDialog from '../../dialogs/multi-robots-repeat-settings.dialog';
import ActionsDialog from '../../dialogs/actions.dialog';
import { COMPLETED, IN_PROGRESS, STATUS_MAP, IN_USE, EXEC_SUSPENDED } from '../../../utils/constants';
import { formatStringForReadbility } from '../../../utils/ui.utils';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    // paddingRight: theme.spacing(2),
    minWidth: 1600
  },
  grid: {
    backgroundColor: theme.palette.grey[500],
    padding: theme.spacing(1),
    marginBottom: 10,
    borderRadius: theme.spacing(1),
    position: 'absolute',
    top: '1%',
    left: '55px',
    maxWidth: '96%',
    wrap: 'no-wrap'
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold
  },
  PlayCircleOutlineIcon: {
    backgroundColor: '',
    color: 'white',
    height: 35,
    width: 35
  },
  connectionIndicatorIcon: {
    height: 40,
    width: 45
  },
  toggleGroup: {
    backgroundColor: theme.palette.grey[200],
    Width: '100%',
    height: 35
  },
  ToggleButton: {
    padding: theme.spacing(1)
  },
  Button: {
    marginLeft: theme.spacing(1),
    height: 35,
    width: 176,
    backgroundColor: '#FF0000'
  },
  Stepper: {
    backgroundColor: theme.palette.grey[500],
    padding: theme.spacing(1)
  },
  Notifications: {
    backgroundColor: theme.palette.grey[100],
    fontSize: '1.5em',
    width: '100%',
    height: '75%',
    borderRadius: 4,
    marginRight: theme.spacing(1),
    overflow: 'auto'
  },
  Notes: {
    backgroundColor: theme.palette.grey[100],
    width: '100%',
    height: '75%',
    borderRadius: 4,
    marginRight: theme.spacing(1)
  },
  formControl: {
    width: '100%',
    marginRight: theme.spacing(1)
  },
  solarSubRows: {
    width: '100%',
    marginRight: theme.spacing(1)
  },
  RestartButtton: {
    padding: '5px 16px'
  },
  ButtonGroup: {
    height: '63px'
  },
  select: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(1),
    height: '63px',
    borderRadius: '4px'
  },
  chips: {
    display: 'flex'
  },
  chip: {
    margin: 2
  },
  multiSelect: {
    backgroundColor: theme.palette.inverted.main,
    padding: theme.spacing(1)
  },
  selected: {
    backgroundColor: 'gray !important',
    color: 'white'
  },
  disabledCursor: {
    cursor: 'not-allowed'
  },
  selectedSolarRow: {
    padding: theme.spacing(2),
    backgroundColor: '#fff',
    borderRadius: '5px'
  },
  completedSolarRow: {
    backgroundColor: '#2eb885',
    color: '#fff'
  },
  inProgressSolarRow: {
    backgroundColor: '#03a9f4',
    color: '#fff'
  }
}));

const MultiRobotsRepeatControls = withRouter(
  observer(
    ({
      readOnlyMode,
      localDataStore,
      changeRepeatingState,
      changeDriveState,
      cancelRepeatingTask,
      isSolarAuditOfficer,
      controlMode,
      solarSubRows,
      robot,
      region,
      wpsState,
      property,
      block,
      subBlock,
      pathType,
      serialNumber,
      linearSpeed,
      lookAheadDistance,
      angularSpeed,
      videoGamma,
      videoContrast,
      isTeleopsStreamingEnabled,
      connectionStatusMessageColor,
      setShowRestartRobotDialog,
      setShowRestartVideoDialog,
      robotEstopState,
      robotSwEstopState,
      robotSensingEdgeState,
      isVideoStreamingRefreshed,
      setIsVideoStreamingRefreshed
    }) => {
      const { regionsStore, chaperonePropertyStore, blocksStore, subBlocksStore, autonomyRobotStore, MultiRobotsStore } = useStores();
      const classes = useStyles();
      const [showRepeatSettingsDialog, setShowRepeatSettingsDialog] = useState(false);
      const [openDialog, setOpenDialog] = useState(false);
      const [selectControlMode, setSelectControlMode] = useState('');
      const regionName = regionsStore.getById(region)?.name;
      const propertyName = chaperonePropertyStore.getById(property)?.name;
      const blockName = blocksStore.getById(block)?.name;
      const subBlockName = subBlocksStore.getById(subBlock)?.name;
      const currentRobot = autonomyRobotStore.getById(robot);
      const selectedSolarSubRows = MultiRobotsStore.robots.get(serialNumber)?.selectedSolarSubRows;
      const notificationMessage = MultiRobotsStore.robots.get(serialNumber)?.notificationMessage || 'No New Notifications';
      const isAutonomyAndTeleopsDisabled = !isTeleopsStreamingEnabled || robotSwEstopState || robotEstopState || robotSensingEdgeState;
      const confirmWarningDialog = () => {
        changeDriveState(selectControlMode);
        setOpenDialog(false);
      };
      const closeDialogue = () => setOpenDialog(false);

      const handleChangeToggleButton = (_, toggleControlMode) => {
        if (toggleControlMode !== null) {
          if (STATUS_MAP[Number(wpsState)] === EXEC_SUSPENDED && toggleControlMode === 'autonomous') {
            setOpenDialog(true);
            setSelectControlMode(toggleControlMode);
          } else {
            changeDriveState(toggleControlMode);
          }
        }
      };

      const handleChangeSolarSubRows = (event) => {
        MultiRobotsStore.setSelectedSolarSubRows(serialNumber, event.target.value);
      };

      const closeSettingDialog = () => {
        setShowRepeatSettingsDialog(false);
      };

      const getStatusClass = (status) => {
        if (status === COMPLETED) return classes.completedSolarRow;
        if (status === IN_PROGRESS) return classes.inProgressSolarRow;
        return '';
      };

      useEffect(() => {
        if (showRepeatSettingsDialog && STATUS_MAP[wpsState] === IN_USE) {
          changeRepeatingState();
        }
      }, [showRepeatSettingsDialog, wpsState]);

      return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" className={classes.grid} xs={12}>
          <ActionsDialog
            dialogTitle="Robot is not in autonomous mode now, do you want it in autonomous?"
            open={openDialog}
            actions={[
              { color: '#ed5063', name: 'Confirm', handler: confirmWarningDialog },
              { color: '#555555', name: 'Cancel', handler: closeDialogue }
            ]}
          />
          {showRepeatSettingsDialog && (
            <RepeatSettingsDialog
              readOnlyMode={readOnlyMode}
              handleClose={closeSettingDialog}
              serialNumber={serialNumber}
              linearSpeed={linearSpeed}
              lookAheadDistance={lookAheadDistance}
              angularSpeed={angularSpeed}
              videoGamma={videoGamma}
              videoContrast={videoContrast}
            />
          )}
          <Grid item container direction="column" justifyContent="flex-start" alignItems="flex-start" xs={2}>
            <Typography>
              Serial Number:{' '}
              <b>{formatStringForReadbility(currentRobot?.serial_number) || formatStringForReadbility(localDataStore?.serial_number)}</b>
            </Typography>
            <Typography>
              Home Region:{' '}
              <b>
                {formatStringForReadbility(currentRobot?.home_region_name) || formatStringForReadbility(localDataStore?.home_region_name)}
              </b>
            </Typography>
            <Typography>
              Home Property:{' '}
              <b>
                {formatStringForReadbility(currentRobot?.home_property_name) ||
                  formatStringForReadbility(localDataStore?.home_property_name)}
              </b>
            </Typography>
            <Typography>
              Region Name: <b>{formatStringForReadbility(localDataStore?.region_name) || formatStringForReadbility(regionName)}</b>
            </Typography>
          </Grid>
          <Grid item container direction="column" justifyContent="flex-start" alignItems="flex-start" xs={2}>
            <Typography>
              Property Name: <b>{formatStringForReadbility(localDataStore?.property_name) || formatStringForReadbility(propertyName)}</b>
            </Typography>
            <Typography>
              Block Name: <b>{formatStringForReadbility(localDataStore?.block_name) || formatStringForReadbility(blockName)}</b>
            </Typography>
            <Typography>
              Sub Block Name: <b>{formatStringForReadbility(subBlockName)}</b>
            </Typography>
            <Typography>
              Path Type: <b>{formatStringForReadbility(pathType) || ''}</b>
            </Typography>
          </Grid>
          <Grid item container direction="row" justifyContent="flex-start" alignItems="flex-start" xs={3} style={{ maxHeight: '90px' }}>
            <Typography>Notifications:</Typography>
            <Container className={classes.Notifications} justifycontent="center">
              {notificationMessage}
            </Container>
          </Grid>
          {solarSubRows && (
            <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs={1} style={{ display: 'flex' }}>
              <FormControl className={classes.solarSubRows}>
                {isSolarAuditOfficer ? <Typography>Selected Solar SubRows:</Typography> : <Typography>Select Solar SubRows:</Typography>}
                {isSolarAuditOfficer ? (
                  <div className={classes.selectedSolarRow}>
                    <Typography>{selectedSolarSubRows}</Typography>
                  </div>
                ) : (
                  <Select
                    multiple
                    disabled={readOnlyMode}
                    value={selectedSolarSubRows}
                    className={classes.multiSelect}
                    style={{}}
                    onChange={handleChangeSolarSubRows}
                    input={<Input />}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip key={value} label={value.substring(0, value.indexOf('__'))} className={classes.chip} />
                        ))}
                      </div>
                    )}
                  >
                    {solarSubRows.map((subrow) => (
                      <MenuItem
                        selected
                        disabled={subrow.status === IN_PROGRESS}
                        classes={{ selected: subrow.status !== IN_PROGRESS ? classes.selected : getStatusClass(IN_PROGRESS) }}
                        className={getStatusClass(subrow.status)}
                        key={subrow.id}
                        value={subrow.value}
                      >
                        {subrow.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </Grid>
          )}

          <Grid item container direction="row" justifyContent="flex-start" alignItems="flex-start" xs={1}>
            <Typography>Restart:</Typography>
            <FormControl className={classes.formControl}>
              <ButtonGroup
                orientation="vertical"
                aria-label="vertical outlined button group"
                variant="contained"
                className={classes.ButtonGroup}
              >
                <Button
                  key="resetController"
                  disabled={readOnlyMode}
                  onClick={() => setShowRestartRobotDialog(true)}
                  className={classes.RestartButtton}
                >
                  Robot
                </Button>
                <Button key="resetVideo" onClick={() => setShowRestartVideoDialog(true)} className={classes.RestartButtton}>
                  Video
                </Button>
              </ButtonGroup>
            </FormControl>
          </Grid>
          <Grid item container direction="row" justifyContent="flex-start" alignItems="flex-start" xs={3}>
            <Grid item container direction="row" justifyContent="flex-end" alignItems="center" style={{ marginRight: '50px' }}>
              <Tooltip title={<Typography fontSize={25}> Refresh Video Streaming </Typography>}>
                <IconButton className={classes.PlayCircleOutlineIcon}>
                  <RefreshIcon
                    className={classes.PlayCircleOutlineIcon}
                    color="action"
                    disabled={readOnlyMode}
                    onClick={() => {
                      setIsVideoStreamingRefreshed(!isVideoStreamingRefreshed);
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title={<Typography fontSize={25}> {connectionStatusMessageColor.message} </Typography>}>
                <FiberManualRecordIcon
                  style={{ color: `${connectionStatusMessageColor.color}` }}
                  className={classes.connectionIndicatorIcon}
                />
              </Tooltip>
              <SettingsIcon
                className={classes.PlayCircleOutlineIcon}
                color="action"
                disabled={readOnlyMode}
                onClick={() => {
                  setShowRepeatSettingsDialog(true);
                }}
              />
              <PlayCircleOutlineIcon
                className={classes.PlayCircleOutlineIcon}
                color="action"
                style={{
                  display: STATUS_MAP[wpsState] !== IN_USE ? 'inline' : 'none',
                  // Use a ternary operator to set the color to gray when disabled
                  color: isAutonomyAndTeleopsDisabled ? 'grey' : 'white'
                }}
                onClick={() => {
                  if (!isAutonomyAndTeleopsDisabled) {
                    changeRepeatingState();
                  }
                }}
              />
              <PauseCircleOutlineIcon
                className={classes.PlayCircleOutlineIcon}
                color="action"
                style={{
                  display: STATUS_MAP[wpsState] === IN_USE ? 'inline' : 'none'
                }}
                onClick={() => changeRepeatingState()}
              />
              <Button disabled={readOnlyMode} onClick={() => cancelRepeatingTask(false)} className={classes.Button} variant="contained">
                Cancel Path
              </Button>
            </Grid>
            <Grid item container direction="row" justifyContent="flex-end" alignItems="center" style={{ marginRight: '50px' }}>
              <ToggleButtonGroup
                className={classes.toggleGroup}
                exclusive
                value={controlMode}
                onChange={handleChangeToggleButton}
                aria-label="text formatting"
              >
                <ToggleButton disabled={readOnlyMode} color="primary" value="manual" aria-label="bold" className={classes.ToggleButton}>
                  Manual
                </ToggleButton>
                <ToggleButton
                  disabled={readOnlyMode || !isTeleopsStreamingEnabled}
                  color="secondary"
                  value="teleops"
                  aria-label="italic"
                  className={classes.ToggleButton}
                >
                  TeleOps
                </ToggleButton>
                <ToggleButton
                  disabled={readOnlyMode}
                  color="secondary"
                  value="autonomous"
                  aria-label="italic"
                  className={classes.ToggleButton}
                >
                  Autonomous
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Grid>
      );
    }
  )
);

export default MultiRobotsRepeatControls;
