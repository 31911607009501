import React from 'react';
import { observer } from 'mobx-react-lite';
import { Checkbox, Grid, makeStyles, TableCell, Typography } from '@material-ui/core';
import { StripedTable } from '../core/striped-table.component';
import { StripedTableRow } from '../core/striped-table-row.component';
import { COMPLETED } from '../../utils/constants';
import { useStores } from '../../store/root/root.store';

const useStyles = makeStyles((theme) => ({
  solarTable: {
    width: '40%'
  },
  titleLable: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 290,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2)
  }
}));

const SolarRowsTable = observer(({ tableSize }) => {
  const { subrowStore } = useStores();
  const headers = ['Name', 'Completed'];
  const classes = useStyles();
  const tableWidth = typeof tableSize !== 'number' ? 5 : tableSize;

  const updateSolarRowStatus = async (id, event) => {
    const status = event.target.checked ? 'COMPLETED' : 'NOT_STARTED';
    await subrowStore.updateSubrow(id, { status });
  };
  return (
    <Grid item xs={12} md={tableWidth}>
      <Typography variant="h4" className={classes.titleLable}>
        Solar Sub-Rows:
      </Typography>
      <StripedTable headers={headers} scrollable>
        {subrowStore.subrows.map((solarRow) => {
          const splittedRow = solarRow.name.split('/').at(-1);
          const splittedRowName = splittedRow.substring(0, splittedRow.indexOf('__'));
          return (
            <StripedTableRow key={solarRow.id} hover>
              <TableCell align="left">{splittedRowName}</TableCell>
              {/* <TableCell align="left">{solarRow.status}</TableCell> */}
              <TableCell align="left">
                <Checkbox checked={solarRow.status === COMPLETED} onChange={(event) => updateSolarRowStatus(solarRow.id, event)} />
              </TableCell>
            </StripedTableRow>
          );
        })}
      </StripedTable>
    </Grid>
  );
});
export default SolarRowsTable;
