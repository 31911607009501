import { types, flow } from 'mobx-state-tree';
import { Auth } from 'aws-amplify';

/**
 * The Authentication Store will act as a bridge between the Amplify SDK and the rest of the application
 * Normally state would be stored in the model for the store, but Amplify handles its own internal storage
 * so we will provide accessors here as neccessary instead
 */
export const AuthenticationStore = types
  .model('AuthenticationStore', {
    error: types.optional(types.string, ''),
    loading: types.boolean,
    authenticated: types.boolean,
    idToken: types.optional(types.string, ''),
    role: types.string,
    newPasswordRequired: types.optional(types.boolean, false)
  })
  .views((self) => ({
    isAuthenticated() {
      return self.authenticated;
    },
    isLoading() {
      return self.loading;
    },
    async getCurrentlyLoggedInUser() {
      return Auth.currentCredentials();
    },
    isRequireNewPassword() {
      return self.newPasswordRequired;
    }
  }))
  .actions((self) => ({
    afterAttach: flow(function* afterCreate() {
      try {
        self.loading = true;
        self.newPasswordRequired = false;
        yield Auth.currentSession();
        self.authenticated = true;
        self.role = localStorage.getItem('role');
      } catch (error) {
        self.authenticated = false;
      } finally {
        self.loading = false;
      }
    }),
    login: flow(function* login(username, password) {
      try {
        self.loading = true;
        const cognitoUser = yield Auth.signIn(username, password);
        console.log('[Login Successful]', Auth.currentCredentials());
        if (cognitoUser?.challengeName === 'NEW_PASSWORD_REQUIRED') {
          self.newPasswordRequired = true;
          window.cognitoUser = cognitoUser;
        } else {
          // We don't need to store auth data in the tree as the Amplify SDK handles its own storage
          // We will store only state related information
          const userRole = cognitoUser?.attributes['custom:Role'] || '';
          localStorage.setItem('role', userRole);
          localStorage.setItem('username', cognitoUser?.attributes.email.split('@')[0]);
          self.role = userRole;
          self.authenticated = true;
        }
        self.loading = false;
      } catch (error) {
        console.error('Failed to login', error);
        self.error = error.message;
        self.authenticated = false;
        self.loading = false;
      }
    }),
    logout: flow(function* logout() {
      try {
        self.loading = true;

        yield Auth.signOut();
        console.debug('Logout Successful');
        self.authenticated = false;
        self.loading = false;
        localStorage.clear();
      } catch (error) {
        console.error('Failed to logout', error);
        self.error = error.message;
        self.loading = false;
        self.role = '';
      }
    }),
    setNewPassword: flow(function* login(password) {
      try {
        self.loading = true;
        console.log('before');
        const data = yield Auth.completeNewPassword(window.cognitoUser, password);
        delete window.cognitoUser;
        const cognitoUser = yield Auth.currentAuthenticatedUser();
        self.newPasswordRequired = false;
        const userRole = cognitoUser?.attributes['custom:Role'] || '';
        localStorage.setItem('role', userRole);
        localStorage.setItem('username', cognitoUser?.attributes.email.split('@')[0]);
        self.role = userRole;
        self.authenticated = true;
        self.loading = false;
      } catch (error) {
        console.error('Failed to change password', error);
        self.error = error.message;
        self.authenticated = false;
        self.loading = false;
      }
    })
  }));
